import React, { useContext, useEffect } from "react";
import history from "../../history";
import MainContext from "../../contexts/MainContext";

function MustBeSubscribed(props) {
  const { children } = props;
  const { session, sessionComplete, listSubscription } = useContext(MainContext);

  useEffect(() => {
    if (!session || !listSubscription) {
      const param = encodeURIComponent(
        window.location.pathname + window.location.search
      );
      history.replace({ pathname: "sign-in", search: `?redirect=${param}` });
    }
  }, [session, sessionComplete, listSubscription]);

  if (!session || !listSubscription) return null;

  return children;
}

export default MustBeSubscribed;
