import React from 'react';
import classNames from 'classnames';
import CopyButton from '../CopyButton';
import { get } from '../../services/utils';
import './UserOffer.scss';

const blue = '#004e95';

const UserOffer = (props) => {
  const {
    offer,
    userOffer,
    className,
  } = props;

  const classes = classNames('UserOffer h-full', className);

  // const color = offer.sender_color || blue;
  // const style = {
  //   backgroundColor: color
  // }

  const createBody = () => {
    return { __html: offer.body };
  }

  const onCopy = () => {
    window.gtag('event', 'copy', {
      action: 'click',
      category: 'coupon',
      content_type: 'offer',
      item_id: offer.id,
    });
  }

  const handleClick = (e) => {
    if (get(e, 'target') && get(e, 'target.closest')) {
      const link = e.target.closest('a');
      if (link && link.href) {
        window.gtag('event', 'select_content', {
          action: 'click',
          category: 'link',
          content_type: 'offer',
          item_id: offer.id,
          label: link.href,
        });
      }
    }
  }

  const coupon = (
    <div className="UserOffer-content-coupon">
      <span className="UserOffer-content-coupon__code">{userOffer.coupon_code}</span>
      <CopyButton className="UserOffer-content-coupon__copy" toCopy={userOffer.coupon_code} label='' copiedLabel='' copiedCallback={onCopy} />
    </div>
  );

  return (
    <div className={classes}>

      <div className="UserOffer-content p-4 sm:p-6">
        {
          offer.sender_logo
            ? <img src={offer.sender_logo} alt={offer.sender_name} className="UserOffer-content__sender-logo mb-3 " />
            : <div className="UserOffer-content__title title nhl-medium text-lg mb-3 ">{offer.sender_name}</div>
        }
        <p className="UserOffer-content__body w-full mb-4 " onClick={handleClick} dangerouslySetInnerHTML={createBody()} />
        {
          userOffer.coupon_code
            ? coupon
            : null
        }
      </div>

    </div>
  );
}

export default UserOffer;