import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import history from '../../history';

const GameDay = (props) => {
  const { activity, showLink = true, className } = props;
  const { title, home_team, away_team, game_id, home_score, away_score, period } = activity;
  function handleClick(e) {
    e.preventDefault();
    if (showLink) history.push({ pathname: `/game/${game_id}` });
  }

  return (
    <div className={classnames('nhl-regular bg-white p-4 sm:p-6', { "cursor-pointer": showLink }, className)} onClick={handleClick}>
      <div className={classnames("flex items-center", {
        "justify-between md:hidden": showLink,
        "justify-center": !showLink
      })}>
        <div className="text-2xl">{title}</div>
        {showLink && (
          <Link to={`/game/${game_id}`} className="flex items-center cursor-pointer text-xl sm:text-2xl uppercase">
            Get your Game on <i className="icon-chevron-right" />
          </Link>
        )}
      </div>

      <div className="flex justify-center items-center mt-3">
        {showLink && <div className="hidden md:flex text-2xl whitespace-nowrap">{title}</div>}

        <div className="w-full flex justify-center">
          <div className="flex items-center">
            <div className="nhl-bold bg-gray-100 p-1 mr-3 sm:mr-6 sm:text-lg">{away_team.city_name_abbr}</div>
            <img className="h-10 w-10 sm:h-20 sm:w-20" src={away_team.logo} />
          </div>
          <div className='flex items-center'>
            {period !== 'pregame' && (
              <div className="px-6 flex items-center">
                <span className="font-bold text-3xl sm:text-6xl px-3">{away_score}</span>
                <span className="text-4xl">:</span>
                <span className="font-bold text-3xl sm:text-6xl px-3">{home_score}</span>
              </div>
            )}
            {period === 'pregame' && (
              <span className="font-semibold text-4xl px-6">@</span>
            )}
          </div>
          <div className="flex items-center">
            <img className="h-10 w-10 sm:h-20 sm:w-20" src={home_team.logo} />
            <div className="nhl-bold bg-gray-100 p-1 ml-3 sm:ml-6 sm:text-lg">{home_team.city_name_abbr}</div>
          </div>
        </div>

        {showLink && (
          <Link to={`/game/${game_id}`} className="hidden md:flex whitespace-nowrap cursor-pointer text-xl sm:text-2xl uppercase items-center">
            Get your Game on <i className="icon-chevron-right" />
          </Link>
        )}
      </div>
    </div>
  )
}

export default GameDay;