import React, { useEffect, useState, Fragment } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Loading from '../../components/Loading';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import NavigationHeader from '../../components/NavigationHeader';
import useRequest from '../../hooks/useRequest';

import './StartingLineup.scss'

function StartingLineup(props) {
  const { id } = props.match.params;
  const location = useLocation();

  const [userLineup] = useRequest({
    type: 'get',
    path: `/starting_lineups/${id}`
  })

  if (userLineup.loading || !userLineup.complete) {
    return <Loading />
  } else if (userLineup.data) {
    const {
      og_title,
      og_description,
      shareable_photo,
      contest_id,
    } = userLineup.data.starting_lineup;

    return (
      <Fragment>
        <Helmet>
          <meta property="og:title" content={og_title} />
          <meta property="og:description" content={og_description} />
          <meta property="og:image" content={shareable_photo} />

          <meta name="twitter:title" content={og_title} />
          <meta name="twitter:description" content={og_description} />
          <meta name="twitter:image" content={shareable_photo} />
        </Helmet>
        <Menu path={props.location.pathname} />
        <NavigationHeader path={props.location.pathname} />
        <div className="MyStartingLineup">
          <header>
            <h1 className="logo" alt="LOGO" />
          </header>
          { userLineup.loading ? <Loading /> :
            <Fragment>
              <h1 className="MyStartingLineup__header">{og_title}</h1>
              <img src={shareable_photo} className="MyStartingLineup__image" />
              <Link to={{ pathname: `/activity/${contest_id}`, search: location.search }} className="btn">Create Your Own Starting Lineup</Link>
            </Fragment> }
        </div>
        <Footer />
        <BluesFooter />
      </Fragment>
    )
  } else if (userLineup.complete) {
    return <Redirect to="/" />
  }
}

export default StartingLineup;