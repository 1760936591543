import './utils/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import GoogleAnalyticsInitializer from './initializers/google-analytics';
import FacebookInitializer from './initializers/facebook';
import fbPixel from './services/facebookPixel';

import 'mapbox-gl/dist/mapbox-gl.css';
import './assets/tunespeak/style.css';
import './index.scss';

import * as serviceWorker from './serviceWorker';

// Load the FB lib
FacebookInitializer();

// Load FB Events
fbPixel.init();

// Load Google Analytics
GoogleAnalyticsInitializer();

ReactDOM.render(
  <App />,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
