import React, { Fragment, useContext, useEffect } from 'react';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Loading from '../../components/Loading';
import ActivityCard from '../../components/ActivityCard';
import ActivityGrid from '../../components/ActivityGrid';
import NavigationHeader from '../../components/NavigationHeader';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import Empty from '../../components/Empty';
import ENV from '../../constants';
import promotionService from '../../services/promotion';
import { get, searchStringToHash } from '../../services/utils';
import useRequest from '../../hooks/useRequest';
import MainContext from '../../contexts/MainContext';
import UserContestContext from '../../contexts/UserContestContext';

const Activities = props => {
  const location = useLocation();
  const params = searchStringToHash(location.search)
  const { session } = useContext(MainContext);
  const { fetchUserContests } = useContext(UserContestContext);

  const [req] = useRequest({
    type: 'get',
    path: `/activities?activity_list_id=${ENV.ACTIVITY_LIST_ID}&ids=${params.ids}`,
  });

  useEffect(() => {
    if (session?.id && params.ids) {
      fetchUserContests({contest_ids: [params.ids]});
    }
  }, [session?.id]);

  useEffect(() => {
    promotionService.setPromotionFromQueryString();
  }, []);

  const content = (() => {
    if (req.loading) {
      return <Loading />
    }
    if (req.complete && get(req, 'data.activities.length')) {
      return <ActivityGrid>{req.data.activities.map(a => <ActivityCard key={a.id} activity={a} />)}</ActivityGrid>
    } else {
      return <Empty />
    }
  })();

  return (
    <Fragment>
      <Menu path={props.location.pathname} />
      <NavigationHeader path={props.location.pathname} />
      <div className="dashboard">
        <header>
          <h1 className="logo" alt="LOGO" />
        </header>
        {content}
      </div>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
}

export default Activities;