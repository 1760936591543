import React, {
  useCallback,
  Fragment,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { DateTime } from "luxon";
import classnames from "classnames";
import TextInput from "../TextInput";
import useConfirm from "../../hooks/useConfirm";
import MainContext from "../../contexts/MainContext";
import BackgroundImage from "../BackgroundImage";
import Dropdown from "react-dropdown";
import { get, searchStringToHash } from "../../services/utils";

const DATE_FORMAT = "MM/dd/yyyy";

function ColorSelector(props) {
  const { onChange, value } = props;
  const colors = [
    "#8BD142",
    "#5BD69D",
    "#61BDD9",
    "#4182D4",
    "#6363CC",
    "#D16CCA",
    "#D14242",
    "#EEB646",
    "#F2D500",
    "#111111",
  ];
  const [selected, setSelected] = useState(value || colors[7]);

  useEffect(() => {
    if (selected) {
      onChange(selected);
    }
  }, [selected, onChange]);

  return (
    <div className="p-2 bg-gray-100 w-full">
      <div className="h-20 w-full" style={{ backgroundColor: selected }}></div>
      <div className="grid gap-2 grid-cols-5 mt-2">
        {colors.map((color) => (
          <div
            className={classnames("m-auto w-8 h-8 cursor-pointer", {
              "border-4 border-solid border-blue-700": selected === color,
            })}
            key={color}
            style={{ backgroundColor: color }}
            onClick={() => setSelected(color)}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default function BluenaticKid(props) {
  const {
    handleSubmit,
    handleClose,
    handleDelete,
    kid,
    showHeader = false,
  } = props;
  const { activityList, listSubscription } = useContext(MainContext);
  const formFields = activityList?.form_fields;
  const [firstName, setFirstName] = useState(kid?.first_name || "");
  const [lastName, setLastName] = useState(kid?.last_name || "");
  const [birthday, setBirthday] = useState(
    (kid?.birthday &&
      DateTime.fromFormat(kid.birthday, "yyyy-MM-dd").toFormat("MM/dd/yyyy")) ||
      ""
  );
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [birthdayError, setBirthdayError] = useState(null);
  const [favoriteColor, setFavoriteColor] = useState(kid?.favorite_color || "");
  const [confirm, confirmModal] = useConfirm({
    title: "Are you sure you wish to remove this kid from your account?",
    description: "This action cannot be undone.",
    yesLabel: "Yes, Remove Kid",
    noLabel: "Cancel",
  });

  // reduce existing data to object iwth field_id's as keys
  const userFormFields = useMemo(() => {
    if (kid) {
      return kid.form_inputs.reduce((acc, i) => {
        return {
          ...acc,
          [i.field_id]: i,
        };
      }, {});
    }
    return {};
  }, [kid]);

  const [formFieldData, setFormFieldData] = useState({});
  const onSelect = (e, fieldId) => {
    setFormFieldData({
      field_id: fieldId,
      value: e.value,
    });
  }

  const fields = useMemo(() => {
    return formFields
      .filter((val) => val.permission !== "create_only")
      .map((f) => {
        if (f.input_type === "select" && f.name === "favorite_current_player") {
          const options = f.options.map((opt) => {
            return {
              value: opt.id,
              label: (
                <div className="flex items-center">
                  {opt.photo && (
                    <img src={opt.photo} className="h-6 w-6 mr-3 inline-block"/>
                  )}
                  <label>{opt.label}</label>
                </div>
              ),
            };
          });
          let defaultValue;
          if (get(userFormFields, `${f.id}.selection.id`)) {
            defaultValue = {
              value: userFormFields[f.id].selection.id,
              label: (
                <div className="flex items-center">
                  {userFormFields[f.id].selection.photo && (
                    <img src={userFormFields[f.id].selection.photo} className="h-6 w-6 mr-3 inline-block"/>
                  )}
                  <label>{userFormFields[f.id].selection.label}</label>
                </div>
              ),
            };
            setFormFieldData((prevState) => {
              return {
                ...prevState,
                [f.id]: userFormFields[f.id].selection.id,
              };
            });
          }
          return (
            <div key={f.id}>
              <div className="text-2xl nhl-regular uppercase pt-2">
                {f.label}
              </div>
              <Dropdown
                className="text-base"
                controlClassName="p-3 nhl text-xl"
                arrowClassName="top-6"
                options={options}
                value={defaultValue}
                onChange={(e) => onSelect(e, f.id)}
              />
            </div>
          );
        }

        return null;
      });
  }, [formFields, userFormFields]);

  const onChange = function (e, fieldName) {
    switch (fieldName) {
      case "firstName":
        return setFirstName(e.target.value);
      case "lastName":
        return setLastName(e.target.value);
      case "birthday":
        return setBirthday(e.target.value);
      default:
        break;
    }
  };

  const preSubmitValidation = function () {
    let errors = 0;
    // First Name
    if (!firstName || firstName.trim().length < 1) {
      setFirstNameError("* First Name Cannot be blank");
      errors += 1;
    }
    // Last Name
    if (!lastName || lastName.trim().length < 1) {
      setLastNameError("* Last Name Cannot be blank");
      errors += 1;
    }

    if (!birthday) {
      setBirthdayError("* Birthday cannot be blank");
      errors += 1;
    }

    // Birthday
    if (birthday) {
      const formattedBirthday = DateTime.fromFormat(birthday, DATE_FORMAT);
      const diffNow = formattedBirthday.diffNow("years");
      if (formattedBirthday.invalid) {
        setBirthdayError("* Invalid Birthday");
        errors += 1;
      }
    }

    return errors > 0;
  };

  const clearErrors = function () {
    setFirstNameError(null);
    setLastNameError(null);
    setBirthdayError(null);
  };

  const onColorChange = function (color) {
    setFavoriteColor(color);
  };

  const onDelete = (e) => {
    e.preventDefault();
    confirm()
      .then(() => {
        handleDelete(kid);
      })
      .catch((e) => {});
  };

  const onSubmit = useCallback(() => {
    clearErrors();
    const errors = preSubmitValidation();
    if (!errors) {
      handleSubmit({
        first_name: firstName?.trim(),
        last_name: lastName?.trim(),
        birthday: birthday,
        favorite_color: favoriteColor,
        id: kid?.id,
        form_inputs: formFieldData?.field_id ? [formFieldData] : []
      });
    }
  }, [firstName, lastName, birthday, favoriteColor, formFieldData]);

  return (
    <div>
      {showHeader && (
        <div className="text-center nhl-regular">
          <div className="text-3xl text-blues-blue uppercase">
            Add a <span className="nhl-medium">Kid</span>
          </div>
          <div className="uppercase text-2xl">
            Join now and enjoy games, contests, and more!
          </div>
        </div>
      )}

      <div
        className={classnames("sm:flex space-y-3 sm:space-x-3 sm:space-y-0", {
          "mt-6": showHeader,
        })}
      >
        <div className="uppercase text-xl space-y-3 w-full">
          <div>
            <div className="text-2xl nhl-regular">Kid's Name</div>
            <div className="flex space-x-3 w-full">
              <TextInput
                placeholder="First Name"
                value={firstName}
                error={firstNameError}
                onChange={(e) => onChange(e, "firstName")}
                className="flex-grow text-base"
              />
              <TextInput
                placeholder="Last Name"
                value={lastName}
                error={lastNameError}
                onChange={(e) => onChange(e, "lastName")}
                className="flex-grow text-base"
              />
            </div>
          </div>

          <div>
            <div className="text-2xl nhl-regular">Kid's Birthday</div>
            <TextInput
              placeholder="Birthday (mm/dd/yyyy)"
              type="date"
              value={birthday}
              error={birthdayError}
              onChange={(e) => onChange(e, "birthday")}
              className="text-base"
            />
          </div>
        </div>

        <div className="text-2xl uppercase w-full nhl-regular sm:w-80">
          <div>Favorite Color</div>
          <ColorSelector value={favoriteColor} onChange={onColorChange} />
        </div>
      </div>

      {fields}

      <div className="mt-6 text-xs text-gray-500">
        By clicking the submit button below to join the Blue Note Kids Club, you
        are agreeing to receive emails from the St. Louis Blues to the email
        address associated with your Bluenatics account.
      </div>

      <div className="flex flex-col sm:flex-row justify-center mt-6 gap-3 nhl-regular">
        {kid && (
          <a
            href="#"
            onClick={(e) => {
              onDelete(e);
            }}
            className="flex-shrink-0 border-2 border-solid border-red-500 sm:border-none py-3 px-3 w-full sm:w-40 text-2xl uppercase order-last sm:order-first"
          >
            <div className="w-full text-center text-red-500 hover:text-red-300 transition">
              Remove Kid
            </div>
          </a>
        )}
        {handleClose && (
          <button
            className="btn btn--secondary w-full uppercase"
            onClick={handleClose}
          >
            Cancel
          </button>
        )}
        <button
          className="btn w-full uppercase order-first sm:order-last"
          onClick={onSubmit}
        >
          {kid ? "Update" : "Submit"}
        </button>
      </div>
      {confirmModal}
    </div>
  );
}
