import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import './TextInput.scss';

function TextInput(props) {
  const {
    placeholder,
    type,
    className,
    value,
    onChange,
    error,
    emailVerified,
    name,
  } = props;

  const klass = `TextInput ${className}`;

  if (type === 'date') {
    return (
      <div className={klass}>
        <span className="TextInput__error">{error}</span>
        <InputMask mask="99/99/9999" placeholder={placeholder} value={value} onChange={onChange} />
      </div>
    );
  }

  return (
    <div className={klass}>
      <span className="TextInput__error">{error}</span>
      <div className="TextInput-input-wrapper">
        <input type={type} name={name} placeholder={placeholder} value={value} onChange={onChange} />
        { emailVerified ? 
          <span className="TextInput-input-wrapper__verified">verified</span>
          :
          null
        }
      </div>
    </div>
  );
};

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
}

TextInput.defaultProps = {
  type: 'text',
  className: '',
  name: '',
}

export default TextInput;