import React, { Fragment } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import useRequest from '../../hooks/useRequest';
import Loading from '../../components/Loading';
import Menu from '../../components/Menu';
import NavigationHeader from '../../components/NavigationHeader';
import ENV from '../../constants';

import './Rules.scss';

function Rules(props) {
  const { id } = props.match.params;
  const [rules] = useRequest({
    type: 'get',
    base: 'https://s3.amazonaws.com',
    path: `/${ENV.RULES_BUCKET}/contest_rules/${id}.txt`,
  });

  return (
    <Fragment>
      <Menu path={props.location.pathname} />
      <NavigationHeader path={props.location.pathname} />
      <div className="Rules">
        <header>
          <h1 className="logo" alt="LOGO" />
        </header>
        { rules.loading 
          ? <Loading /> 
          : <ReactMarkdown className="markdown" children={rules.data} remarkPlugins={[remarkGfm]} />
        }
      </div>
    </Fragment>
  );
}


export default Rules;