const ENV = {
  ACTIVITY_LIST_ID: "5d7ab1713c13a7c15f3bac33",
  BLUES_TEAM_ID: "55708bba69702d2fc2281c00",
  EMBER_APP_URL: "http://localhost:3001",
  EMBED_SDK: "http://localhost:8080/ts-embed.debug.js",
  API_ROOT: "http://localhost:3000",
  FB_PIXEL_IDS: "937231339720758",
  RULES_BUCKET: "developmentspeak-rules",
  APP_DOMAIN: "localhost:3006",
  env: "development",
};

const KEYS = {
  airbrake: {
    projectId: 472002,
    projectKey: "d0484a677a5e52b4460826eea136dfb2",
  },

  apple: {
    developerToken:
      "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlVOSDdZOTZaOEMifQ.eyJpYXQiOjE1NTI1Nzg1OTYsImV4cCI6MTU2ODEzMDU5NiwiaXNzIjoiUk1MODdYR0M4OCJ9.NRc2Y8qjFV1xiufGZqxhvAAu0sd6rRS8SqdKx8klkAHsO-nDAARwwzs4quAclnvi0TbctbSo9euHMzqK6J_zEA",
  },

  // Google APIs
  google: {
    apiKey: "AIzaSyCEs-seZjKeDPpvXfY87shqNqvcDLg8wUQ",
    reCaptchaKey: "6LeF0VUUAAAAAOWhQo5kPUfZHghkn0fQJvxLMIzq",
    clientId:
      "684778119137-53ccde4f92uaflb0bbd8277bqd2kcuek.apps.googleusercontent.com",
  },

  googleAnalytics: {
    webPropertyId: "G-6RNHWRZNKT",
  },

  facebook: {
    appId: "384503444962200",
  },

  credenza: {
    chainId: "80001",
    clientId: "65de0c3193ef867874907a4f",
    sdk: {
      sui_address_url: "https://sui.staging.credenza3.com/accounts/address",
      evm_address_url: "https://evm.staging.credenza3.com/accounts/address",
      env: "staging",
      callback_uris: ["*localhost*"],
    },
  },
};

if (process.env.REACT_APP_ENV === "staging") {
  ENV.ACTIVITY_LIST_ID = "5d7bb90dad8efa473d127e15";
  ENV.EMBER_APP_URL = "https://www.stagingspeak.com";
  ENV.EMBED_SDK = "https://cdn.stagingspeak.com/sdk/v1/ts-embed.js";
  ENV.API_ROOT = "https://stagingspeak-api.alist.fans";
  ENV.FB_PIXEL_IDS = "937231339720758";
  ENV.RULES_BUCKET = "stagingspeak-rules";
  ENV.APP_DOMAIN = "staging.alist.fans";
  ENV.env = "staging";

  KEYS.googleAnalytics.webPropertyId = "G-ZDCCEKQD3R";
  KEYS.facebook.appId = "504832369535061";
  KEYS.apple.developerToken =
    "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjhHM0NGNFI5Nk0ifQ.eyJpYXQiOjE1NTI1ODAwNDMsImV4cCI6MTU2ODEzMjA0MywiaXNzIjoiUk1MODdYR0M4OCJ9.HL1MIzHZezvjg1bVA283-M5f6rICvX5ZN-YVk1cydZA6FQPY7g0T0Xp-Da981Qba9-AEcHMvS9aXSRQVxWSVYQ";

  KEYS.google.apiKey = "AIzaSyAGdyvqg8Au0yjyl-VQ9Getc8wEb74-VD4";
  KEYS.google.reCaptchaKey = "6LdD0VUUAAAAAJgn9eegBIxSErpuF3OGMEle-7gO";
  KEYS.google.clientId =
    "604578916384-7af5fb9hgd3k0vf80qtubva1a3lrhe19.apps.googleusercontent.com";
  KEYS.credenza.chainId = "80001";
  KEYS.credenza.sdk.sui_address_url =
    "https://sui.staging.credenza3.com/accounts/address";
  KEYS.credenza.sdk.evm_address_url =
    "https://evm.staging.credenza3.com/accounts/address";
  KEYS.credenza.sdk.env = "staging";
  KEYS.credenza.sdk.callback_uris = ["https://staging.alist.fans*"];
}

if (process.env.REACT_APP_ENV === "beta") {
  ENV.ACTIVITY_LIST_ID = "5dafc05cf6f9642bc26eabe4";
  ENV.EMBER_APP_URL = "https://beta.tunespeak.com";
  ENV.EMBED_SDK = "https://cdn.tunespeak.com/sdk/v1/ts-embed.js";
  ENV.API_ROOT = "https://beta-api.alist.fans";
  ENV.FB_PIXEL_IDS = "937231339720758";
  ENV.RULES_BUCKET = "tunespeak-rules";
  ENV.APP_DOMAIN = "beta.alist.fans";
  ENV.env = "beta";

  KEYS.googleAnalytics.webPropertyId = "G-6QD34Q78GN";
  KEYS.facebook.appId = "116527605044984";
  KEYS.apple.developerToken =
    "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlQ3NjJCV0ZMNU4ifQ.eyJpYXQiOjE1NTI1ODAxMjEsImV4cCI6MTU2ODEzMjEyMSwiaXNzIjoiUk1MODdYR0M4OCJ9.GQZqKbTXdE7uEiC3Dhc-W7CgBCMArxIBNIi_rQWCnz-10mQnEkvHybo_sg_qRr93QUDZpEzbhFLrvLjC-ke10A";

  KEYS.google.apiKey = "AIzaSyD5aIrE5n2wo8XxqT7p0iMIX0T6YbIrhpA";
  KEYS.google.reCaptchaKey = "6LdE0VUUAAAAAD8XBQwxCj3r2w_lUgfjJDUTKRjG";
  KEYS.google.clientId =
    "773014253645-6ak0qnlu2u9rq8ugac70cqrfgsjvbmmb.apps.googleusercontent.com";
  KEYS.credenza.chainId = "137";
  KEYS.credenza.sdk.sui_address_url =
    "https://sui.credenza3.com/accounts/address";
  KEYS.credenza.sdk.evm_address_url =
    "https://evm.credenza3.com/accounts/address";
  KEYS.credenza.sdk.env = "prod";
  KEYS.credenza.sdk.callback_uris = ["https://beta.alist.fans*"];
}

if (process.env.REACT_APP_ENV === "production") {
  ENV.ACTIVITY_LIST_ID = "5dafc05cf6f9642bc26eabe4";
  ENV.EMBER_APP_URL = "https://www.tunespeak.com";
  ENV.EMBED_SDK = "https://cdn.tunespeak.com/sdk/v1/ts-embed.js";
  ENV.API_ROOT = "https://bluenatics-api.stlouisblues.com";
  ENV.FB_PIXEL_IDS = "937231339720758";
  ENV.RULES_BUCKET = "tunespeak-rules";
  ENV.APP_DOMAIN = "bluenatics.stlouisblues.com";
  ENV.env = "production";

  KEYS.googleAnalytics.webPropertyId = "G-6QD34Q78GN";
  KEYS.facebook.appId = "116527605044984";
  KEYS.apple.developerToken =
    "eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlQ3NjJCV0ZMNU4ifQ.eyJpYXQiOjE1NTI1ODAxMjEsImV4cCI6MTU2ODEzMjEyMSwiaXNzIjoiUk1MODdYR0M4OCJ9.GQZqKbTXdE7uEiC3Dhc-W7CgBCMArxIBNIi_rQWCnz-10mQnEkvHybo_sg_qRr93QUDZpEzbhFLrvLjC-ke10A";

  KEYS.google.apiKey = "AIzaSyD5aIrE5n2wo8XxqT7p0iMIX0T6YbIrhpA";
  KEYS.google.reCaptchaKey = "6LdE0VUUAAAAAD8XBQwxCj3r2w_lUgfjJDUTKRjG";
  KEYS.google.clientId =
    "773014253645-6ak0qnlu2u9rq8ugac70cqrfgsjvbmmb.apps.googleusercontent.com";
  KEYS.credenza.chainId = "137";
  KEYS.credenza.sdk.sui_address_url =
    "https://sui.credenza3.com/accounts/address";
  KEYS.credenza.sdk.evm_address_url =
    "https://evm.credenza3.com/accounts/address";
  KEYS.credenza.sdk.env = "prod";
  KEYS.credenza.sdk.callback_uris = ["https://*bluenatics.stlouisblues.com*"];
}

export default {
  ...ENV,
  KEYS,
};
