import ENV from '../constants';
import isEmbeddedWebView from '../services/embedded';

export default function () {
  if (!isEmbeddedWebView() && !window.FB) {
    (function (i, s, o, g, r, a, m) {
      i.initFacebook = function () {
        a = s.createElement(o);
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      }
    })(window, document, 'script', '//connect.facebook.net/en_US/sdk.js', 'facebook-jssdk');

    window.fbAsyncInit = function () {
      const FB = window.FB;
      FB.init({
        appId: ENV.KEYS.facebook.appId,
        xfbml: true,
        cookie: true,
        version: 'v3.3'
      });
    }
  }
}