import React, { Fragment, useEffect, useState, useRef, useMemo, useContext } from "react";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import classnames from "classnames";
import isMobile from "ismobilejs";
import ReactDOMServer from "react-dom/server";
import ReactMarkdown from "react-markdown";
import { useParams, useHistory } from "react-router-dom";
import { distance as turfDistance, point as turfPoint } from "@turf/turf";
import mapboxgl from "mapbox-gl";
import ENV from "../../constants";
import OutsideClick from "../../hooks/useOutsideClick";
import useSearchParams from "../../hooks/useSearchParams";
import useAsyncState from "../../hooks/useAsyncState";
import useApi from "../../hooks/useApi";
import Menu from "../../components/Menu";
import Modal from "../../components/Modal";
import BarCheckIn from "../../components/BarCheckIn";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import TBBBAboutModal from "../../components/TBBBAboutModal";
import TBBBLogo from "../../assets/images/TBBB_logo.png";
import Bar from "../../assets/images/Bar.svg";
import Offer from "../../assets/images/Offer.svg";
import OfferFilled from "../../assets/images/Offer_Filled.svg";
import Restaurant from "../../assets/images/Restaurant.svg";
import BarLocation from "../../assets/images/Bar_Loc.svg";
import BarLocationActive from "../../assets/images/Bar_Loc_Active.svg";
import RestaurantLocation from "../../assets/images/Restaurant_Loc.svg";
import RestaurantLocationActive from "../../assets/images/Restaurant_Loc_Active.svg";
import Wine from "../../assets/images/Wine.svg";
import WineLocation from "../../assets/images/Wine_Loc.svg";
import WineLocationActive from "../../assets/images/Wine_Loc_Active.svg";
import LiveMusic from "../../assets/images/Live_Music.svg";
import LiveMusicLocation from "../../assets/images/Live_Music_Loc.svg";
import LiveMusicLocationActive from "../../assets/images/Live_Music_Loc_Active.svg";

import LocatingGif from "../../assets/images/locating.gif";
import CompassGif from "../../assets/images/compass.gif";
import { redirectToSignIn } from "../../services/redirect";

import {
  SearchIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import socialPostImage from "../../assets/images/tbbb_social_post.png";
import "./Bars.scss";
import { param } from "../../services/utils";
import MainContext from "../../contexts/MainContext";

mapboxgl.accessToken =
  "pk.eyJ1IjoidHVuZXNwZWFrIiwiYSI6IjlnM05uWU0ifQ.Y27Xnws3AWZCemDm4olxbA";
const Center = [-90.248, 38.628];

function locationIcon(icon) {
  switch (icon) {
    case "wine":
      return Wine;
    case "bar":
      return Bar;
    case "live_music":
      return LiveMusic;
    default:
      return Restaurant;
  }
}

function placeUrl(place) {
  return queryString.stringify(
    {
      q: [
        place.geo_json.geometry.coordinates[1],
        place.geo_json.geometry.coordinates[0],
      ],
    },
    { arrayFormat: "comma", skipEmptyString: true, skipNull: true }
  );
}

function googlePlaceUrl(place) {
  const Base = "https://www.google.com/maps/search/?api=1&";
  const Search = queryString.stringify(
    {
      query: [
        place.geo_json.geometry.coordinates[1],
        place.geo_json.geometry.coordinates[0],
      ],
      query_place_id: place.google_id,
    },
    { arrayFormat: "comma", skipEmptyString: true, skipNull: true }
  );
  return `${Base}${Search}`;
}

function markerIcon(location, isActive = false) {
  if (location.icon === "bar") {
    return isActive ? BarLocationActive : BarLocation;
  } else if (location.icon === "wine") {
    return isActive ? WineLocationActive : WineLocation;
  } else if (location.icon === "live_music") {
    return isActive ? LiveMusicLocationActive : LiveMusicLocation;
  } else {
    return isActive ? RestaurantLocationActive : RestaurantLocation;
  }
}

function calculateDistance(from, to, options = { units: "miles" }) {
  const x = turfPoint(from);
  const y = turfPoint(to);
  return turfDistance(x, y, options);
}

function Bars(props) {
  const { session, listSubscription } = useContext(MainContext);
  const { display } = useParams(props);
  const { location_id, q, check_in_help } = useSearchParams();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [checkInModalOpen, setCheckInModalOpen] = useAsyncState(false);
  const [sidebarOpen, setSidebarOpen] = useState(
    isMobile(navigator.userAgent).any
  );
  const [map, setMap] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const menuRef = useRef();
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [myLocation, setMyLocation] = useState();
  const [geoLocating, setGeoLocating] = useAsyncState(false);
  const [showCheckInHelpModal, setShowCheckInHelpModal] = useState(
    parseInt(check_in_help) === 1
  );
  const [geoLocatingError, setGeoLocatingError] = useAsyncState(false);
  const [needsGeoLocationPermission, setNeedsGeoLocationPermission] =
    useAsyncState(true);

  const {
    value: places,
    setValue: setPlaces,
    complete,
  } = useApi({
    type: "get",
    path: `/places?tags=bleedsblue`,
    serializer: (res) => {
      return res.places
        .map((place) => ({
          ...place,
          distance: calculateDistance(Center, [
            place.geo_json.geometry.coordinates[0],
            place.geo_json.geometry.coordinates[1],
          ]).toFixed(1),
        }))
        .sort((a, b) => a.distance - b.distance);
    },
  });

  const {
    value: userContestAction,
    complete: userContestActionComplete,
    execute: fetchUserContestAction,
  } = useApi({});

  const { value: todaysGame } = useApi({
    type: "get",
    path: `/games/today?team_id=${ENV.BLUES_TEAM_ID}`,
    serializer: (res) => {
      return res?.games?.[0];
    },
  });

  const gameDayCheckIn = useMemo(() => {
    if (todaysGame) {
      const listing = todaysGame?.game_day_listings?.find((listing) => {
        return listing.listable.contest_actions.find(
          (ca) => ca.template === "check-in"
        );
      });
      return listing?.listable;
    }
    return null;
  }, [todaysGame]);

  function createMarker(options) {
    const { location, map, isActive = false } = options;
    const $el = document.createElement("div");
    $el.innerHTML = `<div>${ReactDOMServer.renderToString(
      <div className="relative cursor-pointer">
        <img
          src={markerIcon(location, isActive)}
          className="marker h-12 w-12 "
        />
        <img
          src={markerIcon(location, true)}
          className="marker-active hidden h-12 w-12 z-40"
        />
        {location?.current_specials?.length > 0 && (
          <img
            src={OfferFilled}
            className="h-6 w-6 fill-white absolute top-1.5 -right-4"
          />
        )}
      </div>
    )}</div>`;
    $el.addEventListener("click", () => {
      window._ts_map_selected?.remove();
      setSelectedLocation(location);
      setSidebarOpen(true);
      const marker = createMarker({ location, map, isActive: true });
      window._ts_map_selected = marker;
      history.push({
        search: queryString.stringify(
          { location_id: location.id, q },
          { skipEmptyString: true }
        ),
      });
      marker.addTo(map);
    });
    $el.addEventListener("mouseenter", () => {
      [...$el.getElementsByClassName("marker")].forEach((element) =>
        element.classList.add("hidden")
      );
      [...$el.getElementsByClassName("marker-active")].forEach((element) =>
        element.classList.remove("hidden")
      );
    });

    $el.addEventListener("mouseleave", () => {
      [...$el.getElementsByClassName("marker")].forEach((element) =>
        element.classList.remove("hidden")
      );
      [...$el.getElementsByClassName("marker-active")].forEach((element) =>
        element.classList.add("hidden")
      );
    });
    const marker = new mapboxgl.Marker({
      element: $el,
    }).setLngLat([
      location.geo_json.geometry.coordinates[0],
      location.geo_json.geometry.coordinates[1],
    ]);
    return marker;
  }

  function handleSelect(location) {
    setSelectedLocation(location);
    window._ts_map_selected?.remove();
    const marker = createMarker({ location, map, isActive: true });
    window._ts_map_selected = marker;
    marker.addTo(map);
    map.flyTo({
      center: [
        location.geo_json.geometry.coordinates[0],
        location.geo_json.geometry.coordinates[1],
      ],
      zoom: 13,
    });
    history.push({
      search: queryString.stringify(
        { location_id: location.id, q },
        { skipEmptyString: true }
      ),
    });
  }

  function handleClickOutsideSidebar() {
    setSidebarOpen(false);
  }

  function handleBack() {
    setSelectedLocation(null);
    window._ts_map_selected?.remove();
    history.push({
      search: queryString.stringify({ q }, { skipEmptyString: true }),
    });
  }

  function isDayOfWeek(dayString) {
    const Days = {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday",
    };
    const Now = new Date();
    const Today = Now.getDay();
    return Days[Today] === dayString;
  }

  function handleSearch(e) {
    const value = e.target.value;
    if (value) {
      history.push({
        search: queryString.stringify(
          { q: e.target.value, location_id },
          { skipEmptyString: true }
        ),
      });
    } else {
      history.push({
        search: queryString.stringify(
          { location_id },
          { skipEmptyString: true }
        ),
      });
    }
  }

  function geoLocate() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 13000,
          maximumAge: 0,
        });
      }, 2000);
    });
  }

  function userWithinCheckInRadius(position, radius = 0) {
    const distanceKms = calculateDistance(
      [position.coords.longitude, position.coords.latitude],
      [
        selectedLocation.geo_json.geometry.coordinates[0],
        selectedLocation.geo_json.geometry.coordinates[1],
      ],
      { units: "kilometers" }
    );
    return distanceKms <= radius;
  }

  function redirectToCheckIn(position) {
    const params = {
      cb: "checkIn",
      cbid: gameDayCheckIn.id,
      cbargs: encodeURIComponent(
        JSON.stringify([
          {
            option: "bleeds_blue",
            place_id: selectedLocation.id,
            place: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          },
        ])
      ),
    };
    history.replace({
      pathname: `/game/${todaysGame?.id}`,
      search: `?${param(params)}`,
    });
  }

  function geoLocateAndCheckRadius() {
    setGeoLocating(true);
    geoLocate()
      .then((position) => {
        const checkInRadius = gameDayCheckIn?.contest_actions?.find(
          (ca) => ca.template === "check-in"
        )?.check_in_radius_kms;

        if (userWithinCheckInRadius(position, checkInRadius)) {
          redirectToCheckIn(position);
        } else {
          setGeoLocatingError({ message: "User is too far away" });
        }
      })
      .catch(() => {
        setGeoLocatingError({ message: "Geolocation Error" });
      });
  }

  function handleBarCheckInClick(checkPermissionsBeforeGeoLocate = true) {
    if (!session || !listSubscription) {
      return redirectToSignIn({});
    }
    setGeoLocatingError(null);
    setGeoLocating(false);
    setCheckInModalOpen(true);

    // first time click always check permissions
    if (checkPermissionsBeforeGeoLocate) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permission) => {
          if (permission.state === "granted") {
            geoLocateAndCheckRadius();
          } else {
            setNeedsGeoLocationPermission(true);
          }
        })
        .catch(() => {
          setGeoLocating(false);
          setGeoLocatingError({ message: "Geolocation Error" });
        });
    } else {
      geoLocateAndCheckRadius();
    }
  }

  // calculate distances when geolocated
  useEffect(() => {
    if (myLocation) {
      if (selectedLocation) {
        setSelectedLocation((prev) => ({
          ...prev,
          distance: calculateDistance(myLocation, [
            prev.geo_json.geometry.coordinates[0],
            prev.geo_json.geometry.coordinates[1],
          ]).toFixed(1),
        }));
      }
      setPlaces((prev) => {
        const copy = [...prev];
        return copy
          .map((place) => ({
            ...place,
            distance: calculateDistance(myLocation, [
              place.geo_json.geometry.coordinates[0],
              place.geo_json.geometry.coordinates[1],
            ]).toFixed(1),
          }))
          .sort((a, b) => a.distance - b.distance);
      });
    }
  }, [myLocation]);

  useEffect(() => {
    const query = q?.trim()?.toLowerCase();
    if (complete && map) {
      const updatedLocations = query
        ? places.filter((val) => {
            const name = val.name.toLowerCase();
            const neighborhood = val.area.toLowerCase();
            return name.includes(query) || neighborhood.includes(query);
          })
        : places;
      // remove old markers
      markers.forEach((marker) => marker.remove());
      // build new markers
      updatedLocations.map((location) => {
        const marker = createMarker({ location, map });
        setMarkers((prev) => [...prev, marker]);
        marker.addTo(map);
      });
      setFilteredLocations(updatedLocations);
    }
  }, [places, q, map]);

  useEffect(() => {
    if (complete) {
      let center = Center;
      const location = places?.find((l) => l.id === location_id);
      if (location)
        center = [
          location.geo_json.geometry.coordinates[0],
          location.geo_json.geometry.coordinates[1],
        ];
      const map = new mapboxgl.Map({
        container: "bars-mapbox-container", // container ID
        style: "mapbox://styles/tunespeak/cllmgh7ps02gx01p80s090bz1", // style URL
        center: center, // starting position [lng, lat]
        zoom: 12, // starting zoom
        projection: "globe", // display the map as a 3D globe
      });
      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

      // Add geolocate control to the map.
      const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // // When active the map will receive updates to the device's location as it changes.
        // trackUserLocation: true,
        // // Draw an arrow next to the location dot to indicate which direction the device is heading.
        // showUserHeading: true
      });
      map.addControl(geolocate);
      geolocate.on("geolocate", ({ coords }) => {
        if (coords) {
          setMyLocation([coords.longitude, coords.latitude]);
        }
      });

      map.on("style.load", () => {
        // map.setStyle('mapbox://styles/tunespeak/cl7ako273001v14p1pi9k1jk7')
        // map.setFog({}); // Set the default atmosphere style
        places.forEach((location) => {
          const marker = createMarker({ location, map });
          marker.addTo(map);
          setMarkers((prev) => [...prev, marker]);
        });

        map.addSource("mypoints", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: places.map((datum) => datum.geo_json),
          },
        });

        // Layer with just labels that are only visible from a certain zoom level
        map.addLayer({
          id: "layer-mypoints-label",
          type: "symbol",
          source: "mypoints",
          minzoom: 12, // Set zoom level to whatever suits your needs
          layout: {
            "text-field": "{name}",
            "text-anchor": "center",
            "text-offset": [0, -3.3],
            "text-size": 12,
          },
          paint: {
            "text-color": "#ffffff",
          },
        });

        if (location) {
          const marker = createMarker({ location, map, isActive: true });
          window._ts_map_selected = marker;
          marker.addTo(map);
          setSelectedLocation(location);
        }

        setMap(map);
        window._ts_map = map;
      });
    }
  }, [complete]);

  useEffect(() => {
    if (gameDayCheckIn) {
      fetchUserContestAction(`/user_contests/${gameDayCheckIn.id}`);
    }
  }, [gameDayCheckIn]);

  return (
    <div>
      <Helmet>
        <title>This Bar Bleeds Blue</title>
        <meta
          property="og:description"
          content="Support our Bar Bleeds Blue locations and redeem exclusive specials!"
        />
        <meta property="og:title" content="This Bar Bleeds Blue" />
        <meta
          property="og:image"
          content={`http://${ENV.APP_DOMAIN}${TBBBLogo}`}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta name="twitter:title" content="This Bar Bleeds Blue" />
        <meta
          name="twitter:image"
          content={`http://${ENV.APP_DOMAIN}${TBBBLogo}`}
        />
        <meta
          name="twitter:description"
          content="Support our Bar Bleeds Blue locations and redeem exclusive specials!"
        />
      </Helmet>
      <div ref={menuRef}>
        <Menu
          path={props.location.pathname}
          condensed={true}
          display={display}
        />
      </div>

      {/* {display !== 'app' && (
        <NavigationHeader path={props.location.pathname} />
      )} */}

      <div className="h-full w-full flex">
        <OutsideClick
          handler={handleClickOutsideSidebar}
          className="shadow-lg z-30"
        >
          <div
            className={classnames(
              "flex absolute transition ease-in-out tbbb-sidebar sm:relative",
              {
                "tbbb-sidebar--active": sidebarOpen,
              }
            )}
            style={{
              height: `calc(100vh - ${menuRef?.current?.clientHeight || 0}px)`,
            }}
          >
            <div className="relative bg-white overflow-scroll h-full w-full">
              <div className="p-3">
                <img src={TBBBLogo} />
                {gameDayCheckIn &&
                  userContestActionComplete &&
                  !userContestAction && (
                    <BarCheckIn
                      photo={gameDayCheckIn.photo}
                      prize={
                        gameDayCheckIn?.prize_packages?.[0]?.prizes?.[0]?.title
                      }
                      showBtn={false}
                      handleClick={() => setShowCheckInHelpModal(true)}
                      className="mt-6 mb-2"
                    />
                  )}
                <div className="pt-4 text-xl nhl-medium uppercase">
                  Locations and Specials
                  <i
                    className="icon-help text-lg pl-1 text-blue-900 hover:text-blue-700 transition cursor-pointer"
                    onClick={() => setModalOpen(true)}
                  />
                </div>
                <div className="pt-1">
                  Browse our participating Bar Bleeds Blue locations and redeem
                  exclusive specials!
                </div>
                <a
                  href="https://www.nhl.com/blues/fans/bar-program-registration"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="mt-3 inline-block font-semibold"
                >
                  Owners/Managers: Register Your Location
                </a>
                <div className="bg-gray-100 rounded-full flex items-center px-2 mt-4">
                  <SearchIcon className="h-6 w-6 text-gray-400" />
                  <input
                    type="search"
                    placeholder="Search"
                    value={q || ""}
                    onChange={handleSearch}
                    className="w-full outline-none text-base ml-4 bg-transparent"
                  />
                </div>
              </div>
              <div className="mt-4">
                {filteredLocations.map((location) => (
                  <div
                    key={location.id}
                    className="p-3 odd:bg-gray-50 even:bg-white cursor-pointer flex items-center bars-list-item"
                    onClick={() => handleSelect(location)}
                  >
                    <img
                      src={locationIcon(location.icon)}
                      className="h-6 w-6 mr-3"
                    />
                    <div className="w-full">
                      <div className="uppercase text-lg nhl-medium">
                        {location.name}
                      </div>
                      <div className="text-sm uppercase">
                        {location.area}
                        {myLocation && <> - {location.distance} Miles</>}
                      </div>
                    </div>
                    {location.current_specials.length > 0 && (
                      <img src={Offer} className="h-6 w-6" />
                    )}
                  </div>
                ))}
              </div>
            </div>

            {selectedLocation && (
              <div className="bg-white absolute left-0 top-0 w-full h-full z-40 overflow-y-scroll">
                <div className="bg-black flex justify-center max-w-full w-full h-52 sm:h-60 relative">
                  <BackgroundImage
                    src={selectedLocation.photo}
                    size="cover"
                    delay={100}
                  >
                    <div
                      className="flex items-center justify-center p-1 rounded-full bg-white hover:bg-gray-200 absolute top-5 left-3 h-8 w-8 cursor-pointer shadow-lg"
                      onClick={handleBack}
                    >
                      <ChevronLeftIcon className="h-6 w-6" />
                    </div>
                  </BackgroundImage>
                </div>
                <div className="p-3 space-y-2.5">
                  <div>
                    <div className="uppercase nhl-medium text-2xl sm:text-3xl">
                      {selectedLocation.name}
                    </div>
                    <div>
                      {selectedLocation.area}
                      {selectedLocation.distance && (
                        <> - {selectedLocation.distance} Miles</>
                      )}
                    </div>
                  </div>
                  {gameDayCheckIn &&
                    userContestActionComplete &&
                    !userContestAction && (
                      <BarCheckIn
                        photo={gameDayCheckIn.photo}
                        prize={
                          gameDayCheckIn?.prize_packages?.[0]?.prizes?.[0]
                            ?.title
                        }
                        handleCheckIn={handleBarCheckInClick}
                        className="mb-3"
                      />
                    )}

                  <div className="flex items-center">
                    <div className="rounded-full bg-gray-200 h-8 w-8 flex items-center justify-center mr-2">
                      <i className="icon-address text-lg"></i>
                    </div>
                    <div>
                      <div>
                        {selectedLocation.street}, {selectedLocation.city},{" "}
                        {selectedLocation.state} {selectedLocation.zip}
                      </div>
                      <a
                        href={`https://maps.google.com/?${placeUrl(
                          selectedLocation
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mt-0.5 block"
                      >
                        <a
                          href={`https://maps.apple.com/maps?${placeUrl(
                            selectedLocation
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mt-0.5 block"
                        >
                          Get Directions
                        </a>
                      </a>
                      {/* <a href={googlePlaceUrl(selectedLocation)} target="_blank" rel="noopener noreferrer" className="mt-0.5 block">Get Directions</a> */}
                    </div>
                  </div>
                  {selectedLocation.phone && (
                    <div className="flex items-center">
                      <div className="rounded-full bg-gray-200 h-8 w-8 flex items-center justify-center mr-2">
                        <i className="icon-phone text-lg"></i>
                      </div>
                      <div>{selectedLocation.phone}</div>
                    </div>
                  )}
                  {selectedLocation.website_url && (
                    <div className="flex items-center">
                      <div className="rounded-full bg-gray-200 h-8 w-8 flex items-center justify-center mr-2">
                        <i className="icon-website text-lg"></i>
                      </div>
                      <a
                        href={selectedLocation.website_url}
                        target="_blank"
                        className="link block"
                      >
                        Visit Website
                      </a>
                    </div>
                  )}
                  <div className="flex items-center">
                    <div className="rounded-full bg-gray-200 h-8 w-8 flex items-center justify-center mr-2">
                      <i className="icon-clock text-lg"></i>
                    </div>
                    Hours:
                  </div>
                  <div className="pl-2 space-y-1">
                    <div
                      className={classnames("flex justify-between", {
                        "font-bold text-blue-900": isDayOfWeek("Monday"),
                      })}
                    >
                      <div>Monday</div>
                      <div className="capitalize">
                        {selectedLocation.hours_of_operation.monday[0]}
                      </div>
                    </div>
                    <div
                      className={classnames("flex justify-between", {
                        "font-bold text-blue-900": isDayOfWeek("Tuesday"),
                      })}
                    >
                      <div>Tuesday</div>
                      <div className="capitalize">
                        {selectedLocation.hours_of_operation.tuesday[0]}
                      </div>
                    </div>
                    <div
                      className={classnames("flex justify-between", {
                        "font-bold text-blue-900": isDayOfWeek("Wednesday"),
                      })}
                    >
                      <div>Wednesday</div>
                      <div className="capitalize">
                        {selectedLocation.hours_of_operation.wednesday[0]}
                      </div>
                    </div>
                    <div
                      className={classnames("flex justify-between", {
                        "font-bold text-blue-900": isDayOfWeek("Thursday"),
                      })}
                    >
                      <div>Thursday</div>
                      <div className="capitalize">
                        {selectedLocation.hours_of_operation.thursday[0]}
                      </div>
                    </div>
                    <div
                      className={classnames("flex justify-between", {
                        "font-bold text-blue-900": isDayOfWeek("Friday"),
                      })}
                    >
                      <div>Friday</div>
                      <div className="capitalize">
                        {selectedLocation.hours_of_operation.friday[0]}
                      </div>
                    </div>
                    <div
                      className={classnames("flex justify-between", {
                        "font-bold text-blue-900": isDayOfWeek("Saturday"),
                      })}
                    >
                      <div>Saturday</div>
                      <div className="capitalize">
                        {selectedLocation.hours_of_operation.saturday[0]}
                      </div>
                    </div>
                    <div
                      className={classnames("flex justify-between", {
                        "font-bold text-blue-900": isDayOfWeek("Sunday"),
                      })}
                    >
                      <div>Sunday</div>
                      <div className="capitalize">
                        {selectedLocation.hours_of_operation.sunday[0]}
                      </div>
                    </div>
                  </div>
                </div>
                {selectedLocation.current_specials.length > 0 && (
                  <div className="p-3">
                    <div className="text-xl nhl-medium uppercase">
                      Current Specials
                    </div>
                    {selectedLocation.current_specials.map((offer) => (
                      <div className="pt-3" key={offer.id}>
                        <div className="flex items-center text-xl nhl-regular">
                          <img src={Offer} className="h-6 w-6 mr-3" />
                          {offer.name}
                        </div>
                        {offer.description && (
                          <ReactMarkdown className="pt-2 pl-9 deal-markdown">
                            {offer.description}
                          </ReactMarkdown>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            <div
              className="absolute -right-12 mt-4 rounded-r-lg bg-white px-2 py-3 h-14 cursor-pointer sm:hidden"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              {sidebarOpen ? (
                <ChevronLeftIcon className="h-8 w-8 text-gray-300" />
              ) : (
                <ChevronRightIcon className="h-8 w-8 text-gray-300" />
              )}
            </div>
          </div>
        </OutsideClick>

        <div
          id="bars-mapbox-container"
          className="w-full bg-white relative"
          style={{
            height: `calc(100vh - ${menuRef?.current?.clientHeight || 0}px)`,
          }}
        ></div>

        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          showCloseButton
        >
          <div className="p-6 text-black">
            <img src={TBBBLogo} />
            <div className="px-1 pt-6">
              Thanks for being part of this Bar Bleeds Blue presented by Bud
              Light
            </div>
            <div className="px-1 pt-4 flex items-center">
              <img src={Offer} className="h-6 w-6 mr-3" />
              Redeem discounts and other specials!
            </div>
            <div className="px-1 pt-4 flex items-center">
              <img src={Bar} className="h-6 w-6 mr-3" />
              Browse local St. Louis Blues bars!
            </div>
          </div>
        </Modal>

        <Modal
          open={checkInModalOpen}
          onClose={() => setCheckInModalOpen(false)}
          className="min-w-60 p-4"
        >
          {geoLocatingError && (
            <div className="flex flex-col items-center justify-center w-full">
              <img src={CompassGif} className="w-28" />
              <div className="text-center text-xl font-bold uppercase mt-3">
                <span>
                  {geoLocatingError?.message === "User is too far away" &&
                    "Outside Check-In Range"}

                  {geoLocatingError?.message !== "User is too far away" &&
                    "Unable to Verify Location"}
                </span>
              </div>
              <div className="mt-3">
                {geoLocatingError?.message === "User is too far away" && (
                  <>
                    You are currently too far from{" "}
                    <b>{selectedLocation?.name}</b> to check-in.
                  </>
                )}

                {geoLocatingError?.message !== "User is too far away" && (
                  <>
                    We were unable to verify that you're located at{" "}
                    <b>{selectedLocation?.name}.</b> Please ensure you have not
                    denied location permissions.
                  </>
                )}
              </div>
              <div className="flex justify-between w-full mt-6">
                <button
                  className="btn btn--stretch btn--secondary uppercase"
                  onClick={() => setCheckInModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn--stretch ml-6 uppercase"
                  onClick={handleBarCheckInClick}
                >
                  Retry
                </button>
              </div>
            </div>
          )}

          {!geoLocatingError && geoLocating && (
            <div className="flex flex-col items-center justify-center w-full">
              <div className="text-xl font-bold uppercase">
                Finding Location...
              </div>
              <img src={LocatingGif} className="w-28" />
            </div>
          )}

          {!geoLocating && !geoLocatingError && needsGeoLocationPermission && (
            <div>
              <div className="text-center text-xl font-bold uppercase">
                <span>
                  <i className="icon-address mr-2"></i>Allow Access to Location
                </span>
              </div>
              <div className="mt-3">
                We need access to your location to make sure you're at{" "}
                <b>{selectedLocation?.name}.</b>
              </div>
              <div className="flex justify-between mt-6">
                <button
                  className="btn btn--stretch btn--secondary uppercase"
                  onClick={() => setCheckInModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn--stretch ml-6 uppercase"
                  onClick={() => handleBarCheckInClick(false)}
                >
                  Allow
                </button>
              </div>
            </div>
          )}
        </Modal>

        <TBBBAboutModal
          open={showCheckInHelpModal}
          onClose={() => setShowCheckInHelpModal(false)}
        />
      </div>
    </div>
  );
}

export default Bars;
