import Basil from 'basil.js';
const store = new Basil();

function parseBasilOptions(options={}) {
  return ['storages', 'namespace'].reduce((ret, key) => {
    let val = options[key];
    if (typeof(val) !== 'undefined') { ret[key] = options[key]; }
    return ret;
  }, {});
}

export default {
  set(key, data, options={}) {
    store.set(key, data, parseBasilOptions(options));
  },
  get(key, options={}) {
    return store.get(key, parseBasilOptions(options));
  },
  clear() {
    store.reset();
  },
  getAll() {
    return store.keys();
  },
  remove(key, options={}) {
    store.remove(key, parseBasilOptions(options));
  },
  setRawCookie(key, value, options = {}) {
    Basil.cookie.set(key, value, options);
  },
  getRawCookie(key) {
    return Basil.cookie.get(key);
  }
};
