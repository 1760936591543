import { useState } from 'react';

const useForm = (initialValue = {}) => {
  const [state, setState] = useState(initialValue);

  const handleChange = (e) => {
    return setState({
      ...state,
      [e.target.name]: e.target.value
    });
  }

  return [state, handleChange];
}

export default useForm;