import React, { useRef, useEffect } from 'react';

export default function useInterval(callback, delay, stop = false) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    if (stop) clearInterval(id);
  
    return () => clearInterval(id);
  }, [delay, stop]);
}