import { useState } from "react";
import { loginPassport, logoutPassport } from "../../utils/passport";

function Passport({ email, onDisconnect, passport }) {
  const [loadingPassport, setLoadingPassport] = useState(false);
  const [passportUser, setPassportUser] = useState(null);
  const [loggingInPassport, setLoggingInPassport] = useState(false);
  const [loggingOutPassport, setLoggingOutPassport] = useState(false);

  async function passportLogin() {
    setLoggingInPassport(true);
    try {
      await loginPassport(email);
      setLoggingInPassport(false);
    } catch (e) {
      setLoggingInPassport(false);
    }
  }

  async function passportLogout() {
    setLoggingOutPassport(true);
    try {
      await logoutPassport(email);
      setPassportUser(null);
      if (typeof onDisconnect === "function") {
        onDisconnect();
      }
      setLoggingOutPassport(false);
    } catch (e) {
      setLoggingOutPassport(false);
    }
  }

  function handleLogin(e) {
    e.preventDefault();
    passportLogin();
  }

  function handleLogout(e) {
    e.preventDefault();
    passportLogout();
  }

  if (loadingPassport) {
    return (
      <div className="bg-gray-200 p-3 md:p-8 rounded-lg mt-4 md:flex flex-col justify-center w-full h-52">
        <h2 className="text-lg font-semibold flex items-center justify-center space-x-2">
          <svg
            className="animate-spin h-5 w-5 text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <p className="text-blue-500">Initializing...</p>
        </h2>
      </div>
    );
  } else if (loggingInPassport) {
    return (
      <div className="bg-gray-200 p-3 md:p-8 rounded-lg mt-4 md:flex flex-col justify-center w-full h-52">
        <h2 className="text-lg font-semibold flex items-center justify-center space-x-2">
          <svg
            className="animate-spin h-5 w-5 text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <p className="text-blue-500">Connecting...</p>
        </h2>
        <p className="mt-1 ml-2 text-sm text-center">
          Please don't leave the page.
        </p>
      </div>
    );
  } else if (loggingOutPassport) {
    return (
      <div className="bg-gray-200 p-3 md:p-8 rounded-lg mt-4 md:flex flex-col justify-center w-full h-52">
        <h2 className="text-lg font-semibold flex items-center justify-center space-x-2">
          <svg
            className="animate-spin h-5 w-5 text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <p className="text-blue-500">Disconnecting...</p>
        </h2>
        <p className="mt-1 ml-2 text-sm text-center">
          Please don't leave the page.
        </p>
      </div>
    );
  } else {
    if (passportUser || passport) {
      return (
        <div className="bg-gray-200 p-4 md:p-6 rounded mt-4 md:flex justify-between items-center w-full">
          <div className="space-y-2">
            <h2 className="text-xl font-semibold uppercase">
              Your Bluenatics Passport is Connected!
            </h2>
            <div className="flex flex-col sm:flex-row">
              <span className="mr-1">STATUS:</span>
              <span className="text-green-500">CONNECTED</span>
            </div>
            <div className="flex flex-col sm:flex-row">
              <span className="mr-1">ADDRESS:</span>
              <span className="text-xs">{passport?.sui_address || passportUser?.sui_address}</span>
            </div>
          </div>

          <div>
            <button
              className="mt-3 md:ml-6 md:mt-0 btn btn--secondary w-48 uppercase"
              onClick={handleLogout}
            >
              Disconnect
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="bg-gray-200 p-3 md:p-8 rounded mt-4 md:flex justify-between items-center w-full">
        <div>
          <h2 className="text-xl font-semibold uppercase text-blues-light-blue">
            Connect or Create your{" "}
            <span className="nhl-medium">Bluenatics</span> Passport Account
          </h2>
          <div>
            <span className="mr-1">STATUS:</span>
            <span className="text-gray-500">NOT CONNECTED</span>
          </div>
        </div>

        <div>
          <button
            className="mt-3 md:ml-6 btn w-48 uppercase"
            onClick={handleLogin}
          >
            Connect
          </button>
        </div>
      </div>
    );
  }
}

export default Passport;
