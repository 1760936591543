import isMobile from "ismobilejs";
import { DateTime } from "luxon";
import storage from "./storage";

// validate an email string
export function validateEmail(input) {
  if (!input) {
    return false;
  }
  var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(input.toString());
}

// Equivalent to jquery's $.param method
export function param(obj = {}) {
  return Object.keys(obj)
    .map(function (k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(obj[k]);
    })
    .join("&");
}

// Tell if Object is Empty or not
export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function exists(val) {
  return val !== null && typeof val !== "undefined";
}

// Safe object property getter
export function get(obj = {}, path = "") {
  /** EXAMPLE
    const x = {
      a: {
        id: '1234'
      }
    };
    get(x, 'a.id');  -> '1234
    reduce first checks x['a'] exists and since it does it returns { id: 1234 }
    next loop checks the obj['id'] exists and it does so it returns '1234'
  */
  const pathArray = path.split(".");
  return pathArray.reduce((prevObj, currentKey) => {
    return prevObj && exists(prevObj[currentKey]) ? prevObj[currentKey] : null;
  }, obj);
}

// Embedded iOS webview check
export function isEmbeddediOSWebView() {
  const userAgent = navigator.userAgent.toLowerCase();
  const safari = /safari/.test(userAgent);
  return isMobile(navigator.userAgent).apple.device && !safari;
}

// Embedded Android webview check
export function isEmbeddedAndroidWebView() {
  return (
    isMobile(navigator.userAgent).android.device &&
    storage.getRawCookie("em_android") === "1"
  );
}

// Embedded webview check
export function isEmbeddedWebView() {
  return isEmbeddediOSWebView() || isEmbeddedAndroidWebView();
}

// converts a search string to a hash
export function searchStringToHash(search = "") {
  if (search[0] === "?") {
    search = search.slice(1);
  }
  if (search === "") {
    return {};
  }
  return search
    .split("&")
    .map((v) => v.split("="))
    .reduce(
      (pre, [key, value]) => ({ ...pre, [key]: decodeURIComponent(value) }),
      {}
    );
}

const breakpoints = {
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export function isSmall(width) {
  return width < breakpoints.sm;
}

export function commafyNumber(number) {
  return (number || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function dateInPast(date) {
  return DateTime.fromISO(date).diffNow(["seconds"]) < 0;
}
