import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Link } from 'react-router-dom';
import Stepper from '../../components/Stepper';
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import BasicHeader from '../../components/BasicHeader';
import history from '../../history';
import TS from '../../api';
import { isEmpty, searchStringToHash, param } from '../../services/utils';
import useInterval from '../../hooks/useInterval';
import './VerifyEmail.scss';
import MainContext from '../../contexts/MainContext';

function VerifyEmail(props) {
  const location = useLocation();
  const search = location.search;
  const params = searchStringToHash(location.search);
  params.edit = true;
  const { session } = useContext(MainContext);
  const userProfile = session?.profile;

  const [emailSent, setEmailSent] = useState(false);
  const [delay, setDelay] = useState(5000);

  // Poll to see if email has been verified
  useInterval(() => {
    if (params.display !== 'oauth') {
      TS.get(`/check_email_verification`).then(res => {
        if (res && res.verified) {
          window.location.reload();
        } else {
          setDelay(prev => prev += prev / 3);
        }
      });
    }
  // stop if interval greater than 3 minutes or oauth flow or not in app
  }, delay, (delay > 1000 * 60 * 3 || params.display === 'oauth' || params.display !== 'app'));

  const resendEmail = function (e) {
    e.preventDefault();
    if (!emailSent) {
      setEmailSent(true);
      TS.post(`/resend_email_verification`);
      setTimeout(() => {
        setEmailSent(false);
      }, 3000);
    }
  }

  // if Email Verified redirect to verify-phone
  useEffect(() => {
    if (!isEmpty(userProfile) && !userProfile.pending_email) {
      if (userProfile.phone_verified) {
        history.replace({ pathname: 'form-fields', search });
      } else {
        params.send_code = true;
        history.replace({ pathname: 'verify-phone', search: param(params) });
      }
    }
  }, [userProfile]);

  // if not logged in go to sign-in
  useEffect(() => {
    if (!session && !userProfile) {
      history.replace({ pathname: 'sign-in', search });
    }
  }, [session, userProfile]);

  if (!session && !userProfile) {
    return null;
  }

  const formFragment = (
    <Fragment>
      <p>
        A verification email was sent to <b>{userProfile.pending_email}</b>. Use the link inside to verify your email address. If you did not receive an email, click here to try again:<br /> 
        <a href="#" onClick={resendEmail} className="VerifyEmail__resend">
          { emailSent ? 'Email Sent' : 'Resend Email'}
        </a>
      </p>
      <Stepper steps={4} active={1} />
      <div className="SignUpFlow__links">
        <Link className="SignUpFlow__back" to={{ pathname: '/sign-up', search: param(params) }}><i className="icon-arrow-left"></i> Edit</Link>
      </div>
    </Fragment>
  );

  return params.display === 'oauth' ? (
    <div className="SignUpFlow VerifyEmail OAuthFlow">
      <h2>VERIFY YOUR EMAIL</h2>
      {formFragment}
    </div>
  ) : (
    <Fragment>
      <BasicHeader />
      <div className="SignUpFlow VerifyEmail">
        <h2>VERIFY YOUR EMAIL</h2>
        {formFragment}
      </div>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
};

export default VerifyEmail;