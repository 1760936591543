import React, { Fragment, useContext } from 'react';
import Menu from '../../components/Menu';
import NavigationHeader from '../../components/NavigationHeader';
import ListOffer from '../../components/ListOffer';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import useRequest from '../../hooks/useRequest';
import history from '../../history';
import { get } from '../../services/utils';
import './Offer.scss';
import MainContext from '../../contexts/MainContext';

const Offer = (props) => {
  const {
    location,
  } = props;
  const { session } = useContext(MainContext);
  const { id } = props.match.params
  const [offer] = useRequest({
    type: 'get',
    path: `/list_offers/${id}`,
  });

  // if logged in show full offer
  if (get(session, 'id')) {
    history.replace({ pathname: '/offers', search: `?offer_id=${id}`});
  }

  return (
    <Fragment>
      <Menu path={location.pathname} />
      <NavigationHeader />
      <div className="Page Offer">
        { offer.success && <ListOffer offer={offer.data.list_offer} />}
        { offer.loading && <Loading />}
      </div>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
}

export default Offer;