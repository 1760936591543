import React, { useEffect, Fragment, useContext } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Link } from 'react-router-dom';
import BluesFooter from '../../components/BluesFooter';
import Footer from '../../components/Footer';
import BasicHeader from '../../components/BasicHeader';
import history from '../../history';
import { isEmpty, searchStringToHash, param } from '../../services/utils';
import { passportConnectedOrIgnored } from '../../utils/passport';
import ENV from '../../constants';
import './SignIn.scss';
import logo from '../../assets/images/sign_in_to_bluenatics.png';
import MainContext from '../../contexts/MainContext';

function SignIn() {
  const { session, listSubscription } = useContext(MainContext);
  const location = useLocation();
  const search = location.search;
  const params = searchStringToHash(location.search);

  useEffect(() => {
    const userProfile = session?.profile;
    if (listSubscription) {
      if (!passportConnectedOrIgnored(listSubscription)) {
        history.replace({ pathname: "passport", search });
      } else {
        if (params.oauth_redirect) {
          window.top.location = params.oauth_redirect;
          return null;
        }
        history.replace(params.redirect || "/");
      }
    } else if (!isEmpty(userProfile)) {
      if (!userProfile.pending_phone || !userProfile.pending_email) {
        history.replace({ pathname: 'sign-up', search });
      } else if (userProfile.pending_email) {
        history.replace({ pathname: 'verify-email', search });
      } else if (!userProfile.phone_verified) {
        params.send_code = true;
        history.replace({ pathname: 'verify-phone', search: param(params) });
      } else {
        history.replace({ pathname: 'form-fields', search });
      }
    }
  }, [session?.profile, listSubscription]);

  const formFragment = (
    <Fragment>
      <Link className="btn" to={{ pathname: '/sign-up', search }}>Sign Up</Link>
      <div className="SignIn-or-divider">
        <div className="SignIn-or-divider__line" />
        <span className="SignIn-or-divider__or">OR</span>
        <div className="SignIn-or-divider__line" />
      </div>
      <Link className="btn" to={{ pathname: '/login', search }}>Log In</Link>
      <p className="SignUpFlow__login-change">
        We have recently modified our log in options for Bluenatics. If you are unable to login and have previously registered on Bluenatics, please use the <Link to={{ pathname: '/forgot-password', search }}>Forgot Password?</Link> option to quickly reset your password.
      </p>
      <p className="SignUpFlow__legal">
        By continuing or signing up you explicitly agree to Tunespeak's <a href={`${ENV.EMBER_APP_URL}/tos`} target="_blank" rel="noreferrer noopener">TERMS & CONDITIONS</a> and you acknowledge that you have read
        Tunespeak's <a href={`${ENV.EMBER_APP_URL}/privacy`} target="_blank" rel="noreferrer noopener">PRIVACY POLICY</a>. You also agree that
        we may send your information to the St. Louis Blues and/or their affiliates and you consent to receive personalized updates
        and marketing messages from them based on your information, interests, activities, website visits and device data.
        No purchase necessary. Void where prohibited.
      </p>
    </Fragment>
  );

  return params.display === 'oauth' ? (
    <div className="SignUpFlow SignIn OAuthFlow">
      <img src={logo} title="Bluenatics" className="mb-6" />
      {formFragment}
      <p>Powered by <div>TUNESPEAK</div></p>
    </div>
  ) : (
    <Fragment>
      <BasicHeader />
      <div className="SignUpFlow SignIn">
        <h2>Are you a Bluenatic?</h2>
        <p>Sign Up or Log In to Continue</p>
        {formFragment}
      </div>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
};

export default SignIn;