import storage from './storage';
import constants from '../constants';

const options = { 
  namespace: 'pr',
  storages: ['session', 'cookie']
 };

function searchStringToHash(search) {
  return search.split("&")
  .map(v => v.split("="))
  .reduce( (pre, [key, value]) => ({ ...pre, [key]: value }), {} );
};

export default {
  setPromotion(promotionId, scopeId = constants.ACTIVITY_LIST_ID) {
    return storage.set(scopeId, promotionId, options);
  },
  getPromotion(scopeId = constants.ACTIVITY_LIST_ID) {
    return storage.get(scopeId, options);
  },
  setPromotionFromQueryString(scopeId = constants.ACTIVITY_LIST_ID) {
    const searchHash = searchStringToHash(window.location.search.slice(1));
    if (searchHash.p) {
      return this.setPromotion(searchHash.p, scopeId);
    }
    return;
  }
}