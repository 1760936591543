import React from 'react';
import { Link } from 'react-router-dom';
import './BasicHeader.scss';
import BluesSvg from "../../assets/images/Bluenatics_Logo_2024.svg";

function BasicHeader(props) {
  return (
    <div className="BasicHeader">
      <Link to="/">
        <img src={BluesSvg} alt="" className="BasicHeader__logo"/>
      </Link>
    </div>
  );
};

export default BasicHeader;