import React from "react";
import classnames from "classnames";

export default function (props) {
  const {
    prize,
    photo,
    handleClick,
    handleCheckIn,
    showBtn = true,
    className,
  } = props;

  function onClick(e) {
    e?.preventDefault();
    if (typeof handleClick === "function") {
      handleClick();
    }
  }

  return (
    <div
      className={classnames("rounded bg-gray-200 p-4", className, {
        "cursor-pointer": typeof handleClick === "function",
      })}
      onClick={onClick}
    >
      <div className="text-xl uppercase nhl-medium">
        <i className="icon-check-in mr-2"></i>Gameday Giveaway
      </div>
      <div className="flex flex-col sm:flex-row mt-2 text-base leading-5">
        {photo && (
          <img src={photo} className="w-full sm:h-16 sm:w-auto sm:mr-2" />
        )}
        <div className="mt-3 sm:mt-0">
          {/* 25x Credits Hardcoded for now for location check-in */}
          Check-In at {showBtn ? "this" : "an"} official Bar Bleeds Blue
          location and earn 50x the entries to win{" "}
          <b className="text-bold">{prize}</b>
        </div>
      </div>
      {showBtn && (
        <button
          className="btn btn--stretch uppercase mt-6"
          onClick={handleCheckIn}
        >
          Check-In To Win
        </button>
      )}
    </div>
  );
}
