import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react'
import classnames from 'classnames';
import { XCircleIcon } from '@heroicons/react/outline'

function Modal(props) {
  const {
    open,
    onClose,
    sizeClassName = 'w-full max-w-md',
    className,
    showCloseButton,
  } = props;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      document.addEventListener("keydown", escFunction, false);
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener("keydown", escFunction, false);
    }
  }, [open])

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      onClose();
    }
  }, []);

  return (
    <Transition
      show={open}
      className="fixed inset-0 overflow-y-auto"
      enter="transition-opacity duration-300 ease-in"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-200 ease-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      style={{ zIndex: 1200 }}
    >
      <div className="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0">
        {/* Background overlay, show/hide based on modal state. */}
        <div className="fixed inset-0" onClick={onClose}>
          <div className="absolute inset-0 bg-black opacity-75"></div>
        </div>


        {/*This element is to trick the browser into centering the modal contents.*/}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

        {showCloseButton && (
          <div className={classnames('inline-block align-bottom transform transition-all sm:my-8 sm:align-middle', sizeClassName)} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div className="w-full flex justify-end mb-3">
              <XCircleIcon className="h-8 w-8 text-white cursor-pointer hover:text-blue-400" onClick={onClose} />
            </div>
            <div className={classnames("bg-white rounded-sm text-left shadow-xl", className)}>
              {props.children}
            </div>
          </div>
        )}
        

        {/* Modal panel, show/hide based on modal state. */}
        {!showCloseButton && (
          <div className={classnames('inline-block align-bottom bg-white rounded-sm text-left shadow-xl transform transition-all sm:my-8 sm:align-middle', sizeClassName, className)} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            {props.children}
          </div>
        )}
      </div>
    </Transition>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default Modal;
