import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Dropdown from 'react-dropdown';
import ENV from '../../constants';
import Stepper from '../../components/Stepper';
import BackgroundImage from '../../components/BackgroundImage';
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import BasicHeader from '../../components/BasicHeader';
import Checkbox from '../../components/Checkbox';
import history from '../../history';
import storage from '../../services/storage';
import { passportConnectedOrIgnored } from '../../utils/passport';
import { get, isEmpty, searchStringToHash, param, exists } from '../../services/utils';
import 'react-dropdown/style.css';
import './FormFields.scss';
import MainContext from '../../contexts/MainContext';

function FormFields(props) {
  const location = useLocation();
  const search = location.search;
  const params = searchStringToHash(search);

  const { session, listSubscription, listSubscriptionCreate, listSubscriptionLoading, activityList } = useContext(MainContext);
  const formFields = activityList?.form_fields;
  const userProfile = session?.profile;

  const [data, setData] = useState({});

  const onChange = (e, fieldId) => {
    setData(prevState => {
      return {
        ...prevState,
        [fieldId]: e.value,
      }
    });
  }

  const checkboxClicked = (checked, fieldId) => {
    setData(prevState => {
      return {
        ...prevState,
        [fieldId]: checked,
      }
    });
  }

  const multiCheckboxClicked = (checked, fieldId, optionId) => {
    setData(prevState => {
      return {
        ...prevState,
        [fieldId]: {
          ...prevState[fieldId],
          [optionId]: checked
        }
      }
    });
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (listSubscriptionLoading) {
      return;
    }
    listSubscriptionCreate({
      list_subscription: {
        list_id: ENV.ACTIVITY_LIST_ID,
        form_inputs: formFields.map(f => {
          if (f.input_type === 'select' || f.input_type === 'checkbox') {
            return {
              field_id: f.id,
              value: exists(data[f.id]) ? data[f.id] : f.default_value,
            }
          } else if (f.input_type === 'multiple_select') {
            return {
              field_id: f.field_id,
              values: data[f.id] ? Object.keys(data[f.id]).reduce((acc, currentValue) => {
                if (data[f.id][currentValue]) {
                  acc.push(currentValue);
                }
                return acc;
              }, []) : [],
            }
          }
        })
      }
    });
  }

  const fields = useMemo(() => {
    return formFields?.map(f => {
      if (f.input_type === 'select') {
        const options = f.options.map(opt => {
          return {
            value: opt.id,
            label: (
              <Fragment>
                { opt.photo && <BackgroundImage src={opt.photo} size="contain" />}
                <label>{opt.label}</label>
              </Fragment>
            )
          }
        });
        return (
          <div className="FormFields-field" key={f.id}>
            <b className="FormFields-field__title">{f.label}</b>
            <Dropdown className="FormFields-field__dropdown" options={options} onChange={e => onChange(e, f.id)} />
          </div>
        )
      }
      if (f.input_type === 'multiple_select') {
        const checkboxes = f.options.map(opt => {
          return (
            <Checkbox className="FormFields-field__checkbox" id={opt.id} key={opt.id} callback={checked => multiCheckboxClicked(checked, f.id, opt.id)}>
              <label>
                {opt.label}
              </label>
            </Checkbox>
          )
        })
        return (
          <div className="FormFields-field" key={f.id}>
            <b className="FormFields-field__title">{f.label}</b>
            {checkboxes}
          </div>
        )
      }
      if (f.input_type === 'checkbox') {
        return (
          <div className="FormFields-field" key={f.id}>
            <Checkbox className="FormFields-field__checkbox" id={f.id} key={f.id} value={f.default_value} callback={checked => checkboxClicked(checked, f.id)}>
              <label>
                <b>{f.label}</b>
              </label>
            </Checkbox>
          </div>
        )
      }
      return (
        <div className="FormFields-field" key={f.id}>
          <b className="FormFields-field__title">{f.label}</b>
        </div>
      );
    });
  }, [formFields]);

  useEffect(() => {
    if (!isEmpty(userProfile)) {
      if (userProfile.pending_email) {
        history.replace({ pathname: 'verify-email', search });
      } else if (!userProfile.phone_verified) {
        const newSearch = searchStringToHash(search);
        newSearch.send_code = 'true';
        history.replace({ pathname: 'verify-phone', search: `?${param(newSearch)}` });
      }
    }
  }, [userProfile]);

  // if not logged in go to sign-in
  useEffect(() => {
    if (!session && !userProfile) {
      history.replace({ pathname: 'sign-in', search });
    }
  }, [session, userProfile]);

  if (!session && !userProfile) {
    return null;
  }

  if (listSubscription) {
    if (!passportConnectedOrIgnored(listSubscription)) {
      history.replace({ pathname: "passport", search });
    } else {
      if (params.oauth_redirect) {
        window.top.location = params.oauth_redirect;
        return null;
      }
      return <Redirect to={params.redirect || "/"} />;
    }
  }

  const formFragment = (
    <Fragment>
      <form onSubmit={onSubmit}>
        {fields}
        {
          listSubscriptionLoading ?
          <button className="btn btn--stretch"><i className="icon-loading"></i></button>
          :
          <button className="btn btn--stretch" type="submit">Complete Registration</button>
        }
      </form>
      <Stepper steps={4} active={3} />
    </Fragment>
  );

  return params.display === 'oauth' ? (
    <div className="SignUpFlow FormFields OAuthFlow">
      <h2>HOW DO YOU BLEED BLUE?</h2>
      {formFragment}
    </div>
  ) : (
    <Fragment>
      <BasicHeader />
      <div className="SignUpFlow FormFields">
        <h2>HOW DO YOU BLEED BLUE?</h2>
        {formFragment}
      </div>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
};

export default FormFields;