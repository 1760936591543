import React from "react";

export default React.createContext({
  session: null,
  sessionUpdate: null,
  sessionReload: null,
  sessionLoading: false,
  sessionErrors: null,
  sessionComplete: false,
  sessionCreate: null,

  listSubscription: null,
  listSubscriptionUpdate: null,
  listSubscriptionReload: null,
  listSubscriptionLoading: false,
  listSubscriptionErrors: null,
  listSubscriptionComplete: false,
  listSubscriptionCreate: null,

  activityList: null,
});
