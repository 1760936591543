import React from "react";
import { Link } from "react-router-dom";
import {
  CurrencyDollarIcon,
  ShoppingCartIcon,
  GiftIcon,
  BellIcon,
  TicketIcon,
} from "@heroicons/react/outline";
import CredenzaBanner from "../../assets/images/credenzabanner.png";
import CredenzaMobileBanner from "../../assets/images/credenzamobileheader.png";
import "./PassportBanner.scss";

function PassportBanner(props) {
  return (
    <div className="PassportBanner ActivityCard w-full bg-white relative">
      {/* MOBILE */}
      <div className="md:hidden">
        <img src={CredenzaMobileBanner} className="w-full h-full" />
        <div className="p-6">
          <div className="text-lg">
            <ul className="list-inside text-black">
              <li className="flex items-center mb-3 leading-tight">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <BellIcon className="h-5 w-5" />
                </div>
                Notifications when tickets become available for upcoming events
              </li>
              <li className="flex items-center mb-3 leading-tight">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <TicketIcon className="h-5 w-5" />
                </div>
                Earn unique rewards and discounts based on your fandom
              </li>
              <li className="flex items-center mb-3 leading-tight">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <CurrencyDollarIcon className="h-5 w-5" />
                </div>
                Unlock surprise offers by purchasing concessions & retail at the
                Enterprise Center.
              </li>
              <li className="flex items-center mb-3 leading-tight">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <ShoppingCartIcon className="h-5 w-5" />
                </div>
                <div>
                  Additional exclusive access and opportunities via Bluenatics.{" "}
                  <Link to="/passport-about" className="inline underline">
                    Learn more
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div className="mt-6">
            <Link to="/account" className="btn w-full uppercase">
              Connect Account
            </Link>
          </div>
        </div>
      </div>

      {/* DESKTOP */}
      <div className="hidden md:block">
        <img src={CredenzaBanner} className="w-full h-full" />
        <div className="absolute top-24 left-56">
          <div className="text-lg">
            <ul className="list-inside text-black">
              <li className="flex items-center mb-3">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <BellIcon className="h-5 w-5" />
                </div>
                Notifications when tickets become available for upcoming events
              </li>
              <li className="flex items-center mb-3">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <CurrencyDollarIcon className="h-5 w-5" />
                </div>
                Unlock surprise offers by purchasing concessions & retail at the
                Enterprise Center.
              </li>
              <li className="flex items-center mb-3">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <ShoppingCartIcon className="h-5 w-5" />
                </div>
                <div>
                  Additional exclusive access and opportunities via Bluenatics.{" "}
                  <Link to="/passport-about" className="inline underline">
                    Learn more
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div className="mt-6">
            <Link to="/account" className="btn w-60 uppercase">
              Connect Account
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PassportBanner;
