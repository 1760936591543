import React from 'react';
import ENV from '../../constants';
import poweredBySvg from '../../assets/images/powered_by_tunespeak.svg';

import './Footer.scss';

export default function(props) {
  return (
    <div className="Footer">
      <a href={ENV.EMBER_APP_URL} target="_blank" rel="noopener noreferrer">
        <img src={poweredBySvg} className="Footer__img mx-auto" alt="Powered by TUNESPEAK"/>
      </a>
      <div className="Footer__links">
        <a href="http://support.tunespeak.com/support/home" target="_blank" rel="noopener noreferrer">Support</a>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
        <a href={`${ENV.EMBER_APP_URL}/tos`} target="_top">Terms</a>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
        <a href={`${ENV.EMBER_APP_URL}/privacy`} target="_top">Privacy</a>&nbsp;&nbsp;&bull;&nbsp;&nbsp;
        <a href={`${ENV.EMBER_APP_URL}/about-our-ads`} target="_top">About our Ads</a>
      </div>
    </div>
  );
}
