import { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import Api from '../api';

function useApi({ api = Api, path, query, defaultValue, serializer, options = {} }) {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState();
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const ref = useRef(true);

  useEffect(() => {
    return () => {
      ref.current = false;
    }
  }, []);

  const execute = (url = path) => {
    setComplete(false);
    setLoading(true);

    api.get(queryString.stringifyUrl({
      url: url,
      query: query
    }, options)).then(res => {
      if (!ref.current) return;
      setValue(typeof serializer === 'function' ? serializer(res) : res);
      setLoading(false);
      setComplete(true);
    }).catch(error => {
      if (!ref.current) return;
      setError(error);
      setLoading(false);
      setComplete(true);
    });
  };

  useEffect(() => {
    if (!path) return;
    if (path) execute();
  }, [path, JSON.stringify(query)]);

  return { value, setValue, error, execute, loading, complete };
}

export default useApi;