import React, { useState } from "react";
import Modal from "../components/Modal";

function useConfirm(options = {}) {
  const {
    title = "Are you sure?",
    description,
    yesLabel = "Yes",
    noLabel = "No",
  } = options;

  const [open, setOpen] = useState(false);
  const [callbacks, setCallbacks] = useState({});

  const close = () => setOpen(false);

  const confirm = () => {
    setOpen(true);
    return new Promise((resolve, reject) => {
      setCallbacks({
        onYes: () => {
          resolve();
          close();
        },
        onNo: () => {
          reject();
          close();
        },
      });
    });
  };

  const modal = (
    <Modal
      open={open}
      onClose={callbacks.onNo || close}
      className="p-6 min-w-md max-w-lg space-y-4"
    >
      {typeof title === "string" ? (
        <h2 className="text-2xl leading-5 font-medium text-cool-gray-900">
          {title}
        </h2>
      ) : (
        title
      )}
      {typeof description === "string" ? <p>{description}</p> : description}

      <div className="space-x-3 flex">
        <span className="shadow-sm flex-1">
          <button
            type="button"
            onClick={callbacks.onNo}
            className="btn btn--secondary w-full"
          >
            {noLabel}
          </button>
        </span>
        <span className="shadow-sm rounded-md flex-1">
          <button
            type="button"
            onClick={callbacks.onYes}
            className="btn w-full"
          >
            {yesLabel}
          </button>
        </span>
      </div>
    </Modal>
  );

  return [confirm, modal];
}

export default useConfirm;
