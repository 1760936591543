import React, {
  useRef,
  useContext,
  Fragment,
  useState,
  useEffect,
} from "react";
import { DateTime } from "luxon";
import classnames from "classnames";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Menu from "../../components/Menu";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/Footer";
import BluesFooter from "../../components/BluesFooter";
import MainContext from "../../contexts/MainContext";
import { useParams } from "react-router-dom";
import BluenaticKid from "../../components/BluenaticKid";
import Loading from "../../components/Loading";
import ActivityGrid from "../../components/ActivityGrid";
import ActivityCard from "../../components/ActivityCard";
import DashboardPlaceholderImg from "../../assets/images/dashboard-placeholder.png";
import Api from "../../api";
import { searchStringToHash } from "../../services/utils";
import UserContestContext from "../../contexts/UserContestContext";
import storage from "../../services/storage";
import KidsClubBanner from "../../components/KidsClubBanner";
import "./Kids.scss";

const DATE_FORMAT = "MM/dd/yyyy";
const SERVER_DATE_FORMAT = "yyyy-MM-dd";
const STORAGE_OPTIONS = {
  namespace: "kidsclub",
  storages: ["local"],
};

const Kids = function (props) {
  const location = useLocation();
  const { display } = useParams(props);
  const { session, listSubscription, listSubscriptionReload } = useContext(MainContext);
  const { fetchUserContests, clearUserContests } = useContext(UserContestContext);
  const [data, setData] = useState([]);
  const [kidsList, setKidsList] = useState();
  const [kidsListLoading, setKidsListLoading] = useState(false);

  const params = searchStringToHash(location.search);
  const [showAddNew, setShowAddNew] = useState(false);
  const [isEditingKid, setIsEditingKid] = useState(false);
  const [editingKid, setEditingKid] = useState(null);
  const [activeKid, setActiveKid] = useState(null);

  useEffect(() => {
    if (listSubscription?.accounts) {
      const kidsAccounts = listSubscription?.accounts.filter(
        (account) => account.type === "ListSubscriptionAccount::BluenaticsKid"
      );
      setData(kidsAccounts);
    }
  }, [listSubscription]);

  useEffect(() => {
    const kid = storage.get("active_kid", STORAGE_OPTIONS);
    if (kid && data?.find(x => x.id === kid.id)) {
      setActiveKid(kid);
    }
  }, [data]);

  const selectKid = (kid) => {
    setActiveKid(kid);
    storage.set("active_kid", kid, STORAGE_OPTIONS);
    clearUserContests();
  };

  const editKid = (e, kid) => {
    e.preventDefault();
    e.stopPropagation();
    setEditingKid(kid);
    setIsEditingKid(true);
  };

  const deleteKid = async (kid) => {
    await Api.delete(
      `/bluenatics_kids/${kid.id}?list_subscription_id=${listSubscription.id}`
    );
    setData((prev) => {
      let ret = [...prev];
      ret = ret.filter((item) => item.id !== kid.id);
      return ret;
    });
    if (activeKid?.id === kid.id) {
      setActiveKid(null);
      clearUserContests();
      storage.remove("active_kid", STORAGE_OPTIONS);
    }
    setIsEditingKid(false);
    // update the accounts data
    listSubscriptionReload();
  };

  const updateKid = async (kid) => {
    const res = await Api.put(
      `/bluenatics_kids/${kid.id}?list_subscription_id=${listSubscription.id}`,
      {
        bluenatics_kid: {
          first_name: kid.first_name,
          last_name: kid.last_name,
          birthday: DateTime.fromFormat(kid.birthday, DATE_FORMAT).toFormat(
            SERVER_DATE_FORMAT
          ),
          favorite_color: kid.favorite_color,
          form_inputs: kid.form_inputs,
        },
      }
    );
    setData((prev) => {
      const ret = [...prev];
      const index = ret.findIndex((item) => item.id === res.bluenatics_kid.id);
      if (index !== -1) ret[index] = res.bluenatics_kid;
      return ret;
    });
    setIsEditingKid(false);
  };

  const addKid = async (kid) => {
    const res = await Api.post(
      `/bluenatics_kids?list_subscription_id=${listSubscription.id}`,
      {
        bluenatics_kid: {
          first_name: kid.first_name,
          last_name: kid.last_name,
          birthday: DateTime.fromFormat(kid.birthday, DATE_FORMAT).toFormat(
            SERVER_DATE_FORMAT
          ),
          favorite_color: kid.favorite_color,
          form_inputs: kid.form_inputs
        },
      }
    );
    setData((prev) => {
      const ret = [...prev, res.bluenatics_kid];
      return ret;
    });
    setShowAddNew(false);
    selectKid(res.bluenatics_kid);
    // update the accounts data
    listSubscriptionReload();
  };

  // fetch kids listings
  useEffect(() => {
    if (
      activeKid?.list_id &&
      kidsList?.listings?.[0]?.list_id !== activeKid?.list_id
    ) {
      setKidsListLoading(true);
      Api.get(`/listings?list_id=${activeKid?.list_id}`)
        .then((res) => {
          setKidsList(res);
        })
        .finally(() => setKidsListLoading(false));
    }
  }, [session?.id, activeKid?.list_id]);

  // fetch kids user-contests
  useEffect(() => {
    if (session?.id && kidsList && activeKid?.user_id) {
      const params = {
        contest_ids: kidsList?.listings?.map((listing) => listing.listable.id),
        kid_user_id: activeKid?.user_id,
      };
      fetchUserContests(params);
    }
  }, [session?.id, kidsList, activeKid]);

  return (
    <div>
      <Menu path={props.location.pathname} display={params.display} />
      {params.display !== "app" && (
        <NavigationHeader path={props.location.pathname} />
      )}
      <div
        className={classnames({
          "in-app": params.display === "app",
        })}
      >
        {session?.id && activeKid && (
          <div className="bg-white p-3 sm:p-6">
            <div className="flex max-w-3xl mx-auto w-full">
              <div
                className="uppercase h-12 w-12 sm:h-16 sm:w-16 rounded-full flex items-center justify-center flex-shrink-0 text-white text-3xl"
                style={{ backgroundColor: activeKid.favorite_color }}
              >
                <div className="relative nhl-bold top-px">
                  {activeKid.first_name?.charAt(0)}
                </div>
              </div>
              <div className="flex w-full ml-3 justify-between items-center">
                <div className="leading-5">
                  <div className="nhl-regular text-2xl capitalize">
                    {activeKid?.first_name} {activeKid?.last_name}
                  </div>
                  <div>AGE {activeKid.age}</div>
                </div>
                <div className="leading-5">
                  <a
                    href="#"
                    className="nhl-regular text-2xl uppercase"
                    onClick={() => selectKid(null)}
                  >
                    Switch
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {session?.id && activeKid && (
          <div className="Kids max-w-3xl mx-auto mt-6">
            {kidsListLoading ? (
              <Loading />
            ) : kidsList?.listings?.length ? (
              <ActivityGrid>
                {kidsList?.listings?.map((listing) => (
                  <ActivityCard
                    key={listing.listable.id}
                    activity={listing.listable}
                    kidUser={activeKid}
                  />
                ))}
              </ActivityGrid>
            ) : (
              <img
                src={DashboardPlaceholderImg}
                className="dashboard-placeholder-img"
              />
            )}
          </div>
        )}

        {data.length < 1 && (
          <div className="Kids max-w-3xl mx-auto my-6">
            {session?.id && listSubscription && (
              <KidsClubBanner handleSubmit={addKid} loggedIn={true} />
            )}
            {!session?.id && <KidsClubBanner loggedIn={false} />}
          </div>
        )}

        {data.length > 0 && !activeKid && !isEditingKid && (
          <div className="Kids max-w-3xl mx-auto my-6 p-2 sm:p-6">
            {!showAddNew && (
              <div>
                <div className="text-4xl uppercase mb-3">
                  <span className="nhl-medium">Select a</span>
                  &nbsp;
                  <span className="nhl-bold">Kid</span>
                </div>
                <div className="space-y-3">
                  {data.map((kid) => (
                    <div
                      className="pl-6 flex bg-white hover:bg-gray-50 rounded transtion cursor-pointer"
                      key={kid.user_id}
                      onClick={() => selectKid(kid)}
                    >
                      <div
                        className="my-6 uppercase h-12 w-12 sm:h-16 sm:w-16 rounded-full flex items-center justify-center flex-shrink-0 text-white text-3xl sm:text-4xl"
                        style={{ backgroundColor: kid.favorite_color }}
                      >
                        <div className="relative nhl-bold top-px">
                          {kid?.first_name?.charAt(0)}
                        </div>
                      </div>
                      <div className="flex w-full ml-3 justify-between items-center overflow-hidden">
                        <div className="leading-5 overflow-hidden">
                          <div className="nhl-regular text-2xl capitalize whitespace-nowrap overflow-hidden text-ellipsis">
                            {kid?.first_name} {kid?.last_name}
                          </div>
                          <div>AGE {kid.age}</div>
                        </div>
                        <a
                          href="#"
                          className="leading-5 text-right nhl-regular text-3xl uppercase py-10 px-6"
                          onClick={(e) => editKid(e, kid)}
                        >
                          EDIT
                        </a>
                      </div>
                    </div>
                  ))}
                  <div
                    className="bg-white p-6 flex items-center text-2xl rounded hover:bg-gray-50 cursor-pointer"
                    onClick={() => setShowAddNew(true)}
                  >
                    <button className="btn shadow-md flex items-center justify-center rounded-full h-12 w-12 sm:w-16 sm:h-16 mr-3">
                      <i className="icon-plus text-2xl"></i>
                    </button>
                    <span className="nhl-regular text-2xl text-blues-blue">
                      Add New
                    </span>
                  </div>
                </div>
              </div>
            )}

            {showAddNew && (
              <div className="bg-white p-2 sm:p-6">
                <BluenaticKid
                  handleClose={() => setShowAddNew(false)}
                  handleSubmit={addKid}
                  showHeader={true}
                />
              </div>
            )}
          </div>
        )}

        {isEditingKid && (
          <div className="Kids bg-white max-w-3xl mx-auto my-6 p-2 sm:p-6">
            <BluenaticKid
              handleDelete={deleteKid}
              handleClose={() => setIsEditingKid(false)}
              kid={editingKid}
              handleSubmit={updateKid}
            />
          </div>
        )}

        {display !== "app" && (
          <Fragment>
            <Footer />
            <BluesFooter />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Kids;
