import ENV from '../constants';
const ids = ENV.FB_PIXEL_IDS ? ENV.FB_PIXEL_IDS.split(',') : [];

export default {
  init() {
    if (ids.length) {
      (function(f,b,e,v,n,t,s){
        if(f.fbq)return;
        n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;
        n.push=n;
        n.loaded=!0;
        n.version='2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        f.initFBPixel = function () {
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s)
        }
      }(window,document,'script','https://connect.facebook.net/en_US/fbevents.js'));
      ids.forEach(id => window.fbq('init', id));
    }
  },

  trackSingle(type = 'PageView') {
    if (!window.fbq) { return; }
    ids.forEach(id => window.fbq('trackSingle', id, type));
  }
};