import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import TextInput from '../../components/TextInput';
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import BasicHeader from '../../components/BasicHeader';
import useForm from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { get, validateEmail, searchStringToHash } from '../../services/utils';
import ENV from '../../constants';

const FLOW_OBJECT_ID = ENV.ACTIVITY_LIST_ID;
const FLOW_OBJECT_TYPE = 'ActivityList';

const ResetPassword = (props) => {
  const {
    location,
  } = props;

  const search = location.search;
  const params = searchStringToHash(search);
  const [form, handleChange] = useForm({ email: '' });
  const [resetRequest, resetPassword] = useRequest();
  const [error, setError] = useState(null);
  
  const emailValid = () => {
    let ret = true;
    if (!validateEmail(form.email.trim())) {
      setError('* Email is invalid');
      ret = false;
    }
    return ret;
  }

  const reset = (e) => {
    e.preventDefault();
    setError(null);
    if (emailValid()) {
      resetPassword({
        type: 'post',
        path: `/password_resets`,
        body: {
          password_reset: {
            email: form.email,
            redirect_url: `${window.location.origin}/reset-password`,
            flow_object_id: FLOW_OBJECT_ID,
            flow_object_type: FLOW_OBJECT_TYPE,
          }
        }
      });
    }
  }

  useEffect(() => {
    if (resetRequest.errors) {
      setError(`* ${get(resetRequest, 'errors.data.message')}`);
    }
  }, [resetRequest.errors]);

  if (resetRequest.success) {
    return params.display === 'oauth' ? (
      <div className="SignUpFlow ResetPassword OAuthFlow">
        <h2>WE SENT YOU AN EMAIL</h2>
        <p>Go to your email &amp; follow the instructions to reset your password.</p>
        <div className="SignUpFlow__links">
          <Link className="SignUpFlow__back" to={{ pathname: '/login', search }}><i className="icon-arrow-left"></i> Back to Login</Link>
        </div>
      </div>
    ) : (
      <Fragment>
        <BasicHeader />
        <div className="SignUpFlow ResetPassword">
          <h2>WE SENT YOU AN EMAIL</h2>
          <p>Go to your email &amp; follow the instructions to reset your password.</p>
          <div className="SignUpFlow__links">
            <Link className="SignUpFlow__back" to={{ pathname: '/login', search }}><i className="icon-arrow-left"></i> Back to Login</Link>
          </div>
        </div>
        <Footer />
        <BluesFooter />
      </Fragment>
    );
  }

  return params.display === 'oauth' ? (
    <form className="SignUpFlow ResetPassword OAuthFlow" onSubmit={reset}>
      <h2>FORGOT PASSWORD</h2>
      <p>Enter your sign in email and you will receive instructions to reset your password</p>
      <TextInput name="email" placeholder="Email" type="email" value={form.email} error={error} onChange={handleChange} />
      {
        resetRequest.loading
        ? <button className="btn btn--stretch"><i className="icon-loading"></i></button>
        : <button className="btn btn--stretch" type="submit">Submit</button>
      }
      <div className="SignUpFlow__links">
        <Link className="SignUpFlow__back" to={{ pathname: '/sign-in', search }}><i className="icon-arrow-left"></i> Back</Link>
      </div>
    </form>
  ) : (
    <Fragment>
      <BasicHeader />
      <form className="SignUpFlow ResetPassword" onSubmit={reset}>
        <h2>FORGOT PASSWORD</h2>
        <p>Enter your sign in email and you will receive instructions to reset your password</p>
        <TextInput name="email" placeholder="Email" type="email" value={form.email} error={error} onChange={handleChange} />
        {
          resetRequest.loading
          ? <button className="btn btn--stretch"><i className="icon-loading"></i></button>
          : <button className="btn btn--stretch" type="submit">Submit</button>
        }
        <div className="SignUpFlow__links">
          <Link className="SignUpFlow__back" to={{ pathname: '/sign-in', search }}><i className="icon-arrow-left"></i> Back</Link>
        </div>
      </form>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
}

export default ResetPassword;