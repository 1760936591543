import ENV from "../constants";
import history from "../history";
import { searchStringToHash, param, get } from "./utils";
import storage from "./storage";

const scrollKey = "scrollY";
const saveScrollPosition = () => {
  if (get(window, "pageYOffset")) {
    storage.set(scrollKey, window.pageYOffset);
  }
};
const resetScrollPosition = () => {
  const y = storage.get(scrollKey);
  const isStandalone = /\/activity\//.test(window.location.pathname);
  if (y && !isStandalone) window.scrollTo(0, y);
  storage.remove(scrollKey);
};

export function redirectTo({ to, cbid, cb, cbargs, search = {}, params = {} }) {
  let redirectPath = window.location.pathname;
  let redirectSearch = searchStringToHash(window.location.search);
  if (cbid && cb) {
    redirectSearch.cbid = cbid;
    redirectSearch.cb = cb;
  }
  if (cbargs && cbargs.length) {
    redirectSearch.cbargs = encodeURIComponent(JSON.stringify(cbargs));
  }
  saveScrollPosition();
  const redirect = encodeURIComponent(
    `${redirectPath}?${param({ ...redirectSearch, ...params })}`
  );
  // query params only for the page being sent to
  const query = param(search);
  history.push({
    pathname: to,
    search: `?redirect=${redirect}${query && `&${query}`}`,
  });
}

export function redirectToSignIn({
  cbid,
  cb,
  cbargs,
  search = {},
  params = {},
}) {
  let redirectPath = window.location.pathname;
  let redirectSearch = searchStringToHash(window.location.search);
  if (cbid && cb) {
    redirectSearch.cbid = cbid;
    redirectSearch.cb = cb;
  }
  if (cbargs && cbargs.length) {
    redirectSearch.cbargs = encodeURIComponent(JSON.stringify(cbargs));
  }
  saveScrollPosition();
  // query params only for the page being sent to
  const query = param(search);
  const redirect = encodeURIComponent(
    `${redirectPath}?${param({ ...redirectSearch, ...params })}`
  );
  history.replace({
    pathname: "/sign-in",
    search: `?redirect=${redirect}${query && `&${query}`}`,
  });
}

export function runSignInCallback({ cbid, cb }, func, scope) {
  const search = searchStringToHash(window.location.search);
  if (search.cbid === cbid && search.cb === cb) {
    resetScrollPosition();
    const search = searchStringToHash(window.location.search);
    let args = search.cbargs;
    if (args) {
      args = JSON.parse(decodeURIComponent(args));
    }
    delete search.cbargs;
    delete search.cbid;
    delete search.cb;
    history.replace(`${window.location.pathname}?${param(search)}`);
    func.apply(scope, args);
  }
}
