import React, { Fragment, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';
import Menu from '../../components/Menu';
import ActivityGrid from '../../components/ActivityGrid';
import ActivityCard from '../../components/ActivityCard';
import NavigationHeader from '../../components/NavigationHeader';
import GameDay from '../../components/GameDay';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import BluesFooter from '../../components/BluesFooter';
import TS from '../../api';
import MainContext from '../../contexts/MainContext';
import UserContestContext from '../../contexts/UserContestContext';

const Game = (props) => {
  const { session } = useContext(MainContext);
  const { fetchUserContests } = useContext(UserContestContext);
  const { id, display } = useParams(props);
  const [game, setGame] = useState({});

  useEffect(() => {
    TS.get(`/games/${id}`).then(res => {
      setGame(res.game);
    });
  }, []);

  useEffect(() => {
    if (session?.id && game.id) {
      const ids = game?.game_day_listings?.map((listing) => listing.listable.id).join(',');
      fetchUserContests({ contest_ids: ids });
    }
  }, [session?.id, game.id]);

  if (!game.id && !game.id) return <Loading />;

  return (
    <div>
      <Menu
        path={props.location.pathname}
        logo={display}
      />
      {display !== 'app' && (
        <NavigationHeader path={props.location.pathname} />
      )}

      <div className={classnames("w-full bg-white shadow-lg", {
         "in-app": display === "app"
      })}>
        <GameDay className="py-6 max-w-3xl mx-auto" activity={game} showLink={false} />
      </div>

      <div className="Games max-w-3xl mx-auto min-h-screen py-6 space-y-6 px-1 sm:px-0">
        <ActivityGrid>{game?.game_day_listings?.map(listing => <ActivityCard key={listing.listable.id} activity={listing.listable} />)}</ActivityGrid>
      </div>

      {display !== 'app' && (
        <Fragment>
          <Footer />
          <BluesFooter />
        </Fragment>
      )}
    </div>
  )
}

export default Game;