import React from 'react';
import PropTypes from 'prop-types';
import ReactPhoneNumberInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './PhoneInput.scss';

function PhoneInput(props) {
  const {
    onChange,
    onCountryChange,
    countries,
    value,
    country,
    international,
    error,
    placeholder,
    disabled,
    className,
  } = props;

  return (
    <div className={`PhoneInput ${className}`}>
      <span className="PhoneInput__error">{error}</span>
      <ReactPhoneNumberInput
        placeholder={placeholder} 
        international={international}
        displayInitialValueAsLocalNumber={true}
        onChange={onChange}
        country={country}
        onCountryChange={onCountryChange}
        countries={countries}
        value={value}
        disabled={disabled}
        flagsPath="https://tunespeak-cms.s3.amazonaws.com/flags/4x3/"
      />
    </div>
  );
};

PhoneInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  countries: PropTypes.array,
  value: PropTypes.string,
  international: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
}

PhoneInput.defaultProps = {
  countries: ['US', 'CA'],
  country: 'US',
  value: '',
  international: false,
  placeholder: '',
  className: '',
}

export default PhoneInput;