import React, { Fragment } from 'react';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import Loading from '../../components/Loading';
import BasicHeader from '../../components/BasicHeader';
import { searchStringToHash } from '../../services/utils';
import useRequest from '../../hooks/useRequest';

function EmailVerified(props) {
  const location = useLocation();
  const params = searchStringToHash(location.search);
  const token = params.token;
  const userId = params.user_id;

  const [emailVerification] = useRequest({
    type: 'get',
    path: `/verify_email?user_id=${userId}&token=${token}`,
  });

  const content = (() => {
    if (!emailVerification.complete) {
      return <Loading />
    } else if (emailVerification.data.verified) {
      if (emailVerification.data.redirect_url) {
        window.location.href = emailVerification.data.redirect_url;
      }

      return <Fragment>
        <h2>Success, Email Verified!</h2>
        <p>You may update your email preferences in settings.</p>
      </Fragment>
    } else {
      return <Fragment>
        <h2>Couldn't Verify Your Email</h2>
        <p>This verification link has expired or is invalid. Please check for a newer email, or resend the verification email from settings.</p>
      </Fragment>
    }
  })();

  return (
    <Fragment>
      <BasicHeader />
      <div className="SignUpFlow VerifyEmail">
        {content}
      </div>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
};

export default EmailVerified;


