import React, { Fragment, useEffect, useState, useContext } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import Menu from '../../components/Menu';
import NavigationHeader from '../../components/NavigationHeader';
import MustBeSubscribed from '../../components/MustBeSubscribed';
import BackgroundImage from '../../components/BackgroundImage';
import Footer from '../../components/Footer';
import Gameday from '../../components/GameDay';
import BluesFooter from '../../components/BluesFooter';
import ENV from '../../constants';
import TS from '../../api';
import history from '../../history';
import storage from "../../services/storage";
import MainContext from "../../contexts/MainContext";
import { searchStringToHash } from "../../services/utils";

const cc = storage.getRawCookie("ca2");
const dateFormatting = (function () {
  // US format
  if (!cc || cc === "US") {
    return {
      format: "MM/dd/yyyy",
    };
  }
  // International Date Format
  return {
    format: "dd/MM/yyyy",
  };
})();

const CHECK_IN_LOCATIONS = {
  venue: "At the Game",
  home: "Home",
  friends_house: "Friend's House",
  other: "Other",
  bleeds_blue: "Verified Bar Bleeds Blue Location",
};

const GamedayHistory = (props) => {
  const { user_game, className } = props;
  const { game } = user_game;
  const date = DateTime.fromISO(game.starts_at);

  function handleClick(e) {
    e.preventDefault();
    history.push({ pathname: `/game/${game.id}` });
  }

  return (
    <div className={classnames("bg-white text-xl shadow-lg flex", className)}>
      <div className="px-6 py-3 text-center">
        <div className="uppercase">{date.monthShort}</div>
        <div className="text-3xl">{date.day}</div>
      </div>

      <div className="w-full cursor-pointer" onClick={handleClick}>
        <div className="flex justify-between uppercase bg-gray-100 px-6 py-2">
          <div className="flex items-center">
            <img className="h-8 w-8 mr-3" src={game.home_team.logo} />
            <div>{game.home_team.name}</div>
          </div>
          {game.final && <div className="ml-3">{game.home_score}</div>}
        </div>

        <div className="flex justify-between uppercase mt-0.5 bg-gray-100 px-6 py-2">
          <div className="flex items-center">
            <img className="h-8 w-8 mr-3" src={game.away_team.logo} />
            <div>{game.away_team.name}</div>
          </div>
          {game.final && <div className="ml-3">{game.away_score}</div>}
        </div>
      </div>

      <Link to={`/game/${game.id}`} className="btn">
        <i className="icon-chevron-right text-xl" />
      </Link>
    </div>
  );
};

const GamedayStat = (props) => {
  const {
    line1Title,
    line1Stat = 0,
    line2Title,
    line2Stat = 0,
    className,
  } = props;
  return (
    <div
      className={classnames(
        "p-3 bg-white text-2xl shadow-lg nhl-light",
        className
      )}
    >
      <div className="flex justify-between uppercase">
        <div>{line1Title}</div>
        <div>{line1Stat}</div>
      </div>

      <div className="flex justify-between text-xl uppercase mt-2">
        <div>{line2Title}</div>
        <div>{line2Stat}</div>
      </div>
    </div>
  );
};

const Games = (props) => {
  const location = useLocation();
  const params = searchStringToHash(location.search);
  const { session, listSubscription } = useContext(MainContext);
  const [gameDays, setGameDays] = useState([]);
  const [userGames, setUserGames] = useState([]);
  const [userInsight, setUserInsight] = useState(null);

  // Fetch today's Game
  useEffect(() => {
    if (session || listSubscription) {
      TS.get(`/game_days/today?list_id=${ENV.ACTIVITY_LIST_ID}`).then((res) => {
        if (res.game_days) setGameDays(res.game_days);
      });
    }
  }, [session, listSubscription]);

  // Fetch user-games
  useEffect(() => {
    if (session || listSubscription) {
      TS.get(`/user_games?list_id=${ENV.ACTIVITY_LIST_ID}`).then((res) => {
        setUserGames(res.user_games);
      });
    }
  }, [session, listSubscription]);

  // Fetch user-stats
  useEffect(() => {
    if (session || listSubscription) {
      TS.get(`/user_game_insight?list_id=${ENV.ACTIVITY_LIST_ID}`).then(
        (res) => {
          setUserInsight(res.user_game_insight);
        }
      );
    }
  }, [session, listSubscription]);

  return (
    <MustBeSubscribed>
      <Menu path={props.location.pathname} display={params.display} />
      {params.display !== "app" && (
        <NavigationHeader path={props.location.pathname} />
      )}
      <div className={classnames("nhl-regular w-full bg-white p-3 sm:p-6 shadow-lg", {
        "in-app": params.display === "app"
      })}>
        <div className="max-w-3xl mx-auto flex items-center">
          <div className="avatar overflow-hidden">
            <BackgroundImage src={session?.photo} />
          </div>
          <div className="flex justify-between pl-3 w-full overflow-hidden">
            <div className="uppercase text-2xl">
              <div>
                {session?.first_name} {session?.last_name}
              </div>
              <div className="nhl-light">
                Joined{" "}
                {DateTime.fromISO(listSubscription?.created_at).toFormat(
                  dateFormatting.format
                )}
              </div>
            </div>
            {listSubscription?.season_ticket_holder && (
              <div className="ml-3 text-yellow-500 text-xl sm:text-2xl">
                <i className="icon-ticket"></i>
                <span className="pl-1">Season Ticket Holder</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="Games nhl-medium max-w-3xl w-full mx-auto min-h-screen py-6 space-y-6 px-1 sm:px-1">
        <div>
          <div className="text-3xl uppercase pb-3">Gameday Stats</div>
          <div className="space-y-3 sm:space-y-0 sm:grid sm:grid-cols-2 gap-3">
            <GamedayStat
              line1Title="Check-Ins"
              line1Stat={userInsight?.check_ins_count}
              line2Title="Top Location"
              line2Stat={
                CHECK_IN_LOCATIONS[userInsight?.top_location] ||
                userInsight?.top_location ||
                "N/A"
              }
            />
            <GamedayStat
              line1Title="Score Guesses"
              line1Stat={userInsight?.score_guesses_count}
              line2Title="Correct"
              line2Stat={userInsight?.score_guesses_correct_count}
            />
            <GamedayStat
              line1Title="First Goal Vote"
              line1Stat={userInsight?.first_goal_votes_count}
              line2Title="Correct"
              line2Stat={userInsight?.first_goal_votes_correct_count}
            />
            <GamedayStat
              line1Title="Starting Lineups"
              line1Stat={userInsight?.starting_lineups_count}
              line2Title="Most Started Player"
              line2Stat={userInsight?.most_started_player || "N/A"}
            />
            {/* <div className="bg-white shadow-lg text-center p-6 text-lg col-span-2">
              No Stats
            </div> */}
          </div>
        </div>

        {gameDays.length > 0 && (
          <div>
            <div className="text-3xl uppercase pb-3">Today's Game</div>
            <div className="space-y-3">
              {gameDays.map((gd) => (
                <Gameday key={gd.id} activity={gd} className="shadow-lg" />
              ))}
            </div>
          </div>
        )}

        <div>
          <div className="text-3xl uppercase pb-3">Gameday History</div>
          <div className="space-y-3">
            {userGames.map((ug) => (
              <GamedayHistory key={ug.id} user_game={ug} />
            ))}

            {userGames.length === 0 && (
              <div className="bg-white shadow-lg text-center p-6 text-lg">
                No History
              </div>
            )}
          </div>
        </div>
      </div>
      {params.display !== "app" && (
        <Fragment>
          <Footer />
          <BluesFooter />
        </Fragment>
      )}
    </MustBeSubscribed>
  );
};

export default Games;
