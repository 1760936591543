import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Redirect } from 'react-router-dom';
import Loading from '../../components/Loading';
import useRequest from '../../hooks/useRequest';
import { searchStringToHash, param } from '../../services/utils';

function ScoreGuess(props) {
  const { id } = props.match.params;
  const location = useLocation();
  const params = searchStringToHash(location.search)

  const [userGuess] = useRequest({
    type: 'get',
    path: `/score_guesses/${id}`
  })

  if (userGuess.loading || !userGuess.complete) {
    return <Loading />
  } else if (userGuess.data) {
    params.user_guess_id = id;
    return <Redirect to={{
      pathname: `/activity/${userGuess.data.score_guess.contest_id}`,
      search: param(params)
    }} />
  } else if (userGuess.complete) {
    return <Redirect to="/" />
  }
}

export default ScoreGuess;