import React, { useState, useEffect, Fragment, Suspense } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Link } from 'react-router-dom';
import TextInput from '../../components/TextInput';
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import BasicHeader from '../../components/BasicHeader';
import useForm from '../../hooks/useForm';
import useRequest from '../../hooks/useRequest';
import { get, searchStringToHash } from '../../services/utils';
import "./ResetPassword.scss";

const PasswordStrengthInput = React.lazy(() => import("../../components/PasswordStrengthInput"));

const ResetPassword = () => {
  const location = useLocation();
  const params = searchStringToHash(location.search);
  const token = params.token;
  const expired = params.expired;
  const [form, handleChange] = useForm({ password1: '', password2: ''});
  const [resetRequest, resetPassword] = useRequest();
  const [error, setError] = useState(null);
  const [passwordScore, setPasswordScore] = useState(0);
  
  const passwordsValid = () => {
    if (form.password1 !== form.password2) {
      setError('* Passwords do not match');
      return false;
    } else if (form.password1.length < 8) {
      setError('* Password must be at least 8 characters');
      return false;
    } else if (passwordScore < 2) {
      setError("* Password is too weak");
      return false;
    }
    return true;
  }

  const reset = async (e) => {
    e.preventDefault();
    // silently fail if no token
    if (!token) { return; }
    setError(null);
    if (passwordsValid()) {
      resetPassword({
        type: 'put',
        path: `/password_resets/${token}`,
        body: {
          password_reset: {
            password: form.password1,
          }
        }
      });
    } 
  }

  useEffect(() => {
    if (resetRequest.errors) {
      setError(`* ${get(resetRequest, 'errors.data.message')}`);
    }
  }, [resetRequest.errors]);

  let content = (
    <form className="SignUpFlow ResetPassword" onSubmit={reset}>
      <h2>RESET PASSWORD</h2>
      <p>Enter your new password in both fields.</p>
      <Suspense fallback={null}>
        <PasswordStrengthInput
          name="password1"
          placeholder="New Password"
          value={form.password1}
          error={error}
          setError={setError}
          onChange={handleChange}
          score={passwordScore}
          setScore={setPasswordScore}
        />
      </Suspense>
      <TextInput
        name="password2"
        placeholder="Confirm New Password"
        type="password"
        value={form.password2}
        onChange={handleChange}
      />
      {resetRequest.loading ? (
        <button className="btn btn--stretch">
          <i className="icon-loading"></i>
        </button>
      ) : (
        <button className="btn btn--stretch" type="submit">
          Reset
        </button>
      )}
    </form>
  );

  // your link already expired
  if (expired) {
    content = (
      <div className="SignUpFlow ResetPassword">
        <h2>RESET PASSWORD EXPIRED</h2>
        <p>This password reset link has expired or is invalid. Please check for a newer email, or try again <a href="#" >here</a></p>
      </div>
    );
  }

  if (resetRequest.success) {
    content = (
      <div className="SignUpFlow ResetPassword">
        <h2>SUCCESS!</h2>
        <p>Your password has been updated</p>
        <Link className="SignUpFlow__back" to={{ pathname: '/login', search: location.search }}><i className="icon-arrow-left"></i> Back to Login</Link>
      </div>
    );
  }

  //OAuthFlow class?
  return params.display === 'oauth' ? content : (
    <Fragment>
      <BasicHeader />
      <div className="min-h-screen">
        {content}
      </div>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
}

export default ResetPassword;