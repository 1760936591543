import { useEffect, useState, useRef } from 'react';
import TS from '../api';

/*****
 * 
 * Legacy code, use useApi hook instead
 * 
 ******/
const useRequest = (
  requestOptions = { path: '', type: 'get', body: null, base: '' }
) => {
  const [options, setOptions] = useState(requestOptions);
  const [value, setValue] = useState({
    loading: false,
    data: null,
    errors: null,
    complete: false
  });
  const ref = useRef(true);

  useEffect(() => {
    return () => {
      ref.current = false;
    };
  }, []);

  useEffect(() => {
    if (options.path || options.base) {
      setValue({ ...value, loading: true, errors: null, complete: false });
      TS.request(options)
        .then((res) => {
          if (ref.current) {
            if (typeof options.serializer === 'function') {
              const serialized = options.serializer(res);
              setValue({
                loading: false,
                data: serialized,
                errors: null,
                complete: true,
                success: true
              });
            } else {
              setValue({
                loading: false,
                data: res,
                errors: null,
                complete: true,
                success: true
              });
            }
          }
        })
        .catch((error) => {
          if (ref.current) {
            setValue({
              loading: false,
              data: null,
              errors: error,
              complete: true
            });
          }
        });
    }
  }, [options]);

  // returns array with function to trigger request by setting a new url and the request values
  return [value, setOptions];
};

export default useRequest;
