import axios from 'axios';
import ENV from './constants';

const API_ROOT = ENV.API_ROOT;

// configure defaults for tunespeak api
const tsAxios = axios.create({
  baseURL: API_ROOT,
  withCredentials: true,
  contentType: 'application/json',
});

const request = (type, url, body) => {
  return new Promise((resolve, reject) => {
    tsAxios[type](url, body).then(res => {resolve(res.data)}).catch(error => reject(error.response));
  }); 
};

export default {
  get(url) {
    return request('get', url);
  },
  post(url, body = {}) {
    return request('post', url, body);
  },
  put(url, body = {}) {
    return request('put', url, body);
  },
  delete(url, body = {}) {
    return request('delete', url, body);
  },
  request({ type, path, body, base }) {
    if (base) {
      return new Promise((resolve, reject) => {
        axios({ method: type, url: `${base}${path}`, data: body })
          .then(res => resolve(res.data))
          .catch(error => reject(error.response));
      }); 
    }
    return request(type, path, body);
  }
};
