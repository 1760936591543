import { CredenzaSDK } from "@credenza3/core-web";
import { OAuthExtension } from "@credenza3/core-web-oauth-ext";
import { AccountExtension } from "@credenza3/core-web-account-ext";
import { EvmExtension } from "@credenza3/core-web-evm-ext";
import { SuiExtension } from "@credenza3/core-web-sui-ext";
import storage from "../services/storage";
import constants from "../constants";

let passportInstance = null;
let initializationPromise = null;

const chainConfig = {
  chainId: "0x89",
  rpcUrl: "https://polygon-bor-rpc.publicnode.com",
  displayName: "Polygon",
  blockExplorer: "https://www.polygonscan.com/",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
};

async function getSuiAddress() {
  const passport = await getPassportInstance();
  const address = await passport.sui.getAddress();
  return address;
}

async function getEvmAddress() {
  const passport = await getPassportInstance();
  const provider = await passport.evm.getEthersProvider();
  const signer = await provider.getSigner();
  const address = await signer.getAddress();
  return address;
}

export async function initializePassport() {
  if (!passportInstance) {
    if (!initializationPromise) {
      initializationPromise = new Promise((resolve, reject) => {
        const instance = new CredenzaSDK({
          clientId: constants.KEYS.credenza.clientId,
          env: constants.KEYS.credenza.sdk.env,
          callback_uris: constants.KEYS.credenza.sdk.callback_uris,
          extensions: [
            new SuiExtension({ suiNetwork: SuiExtension.SUI_NETWORK.DEVNET }),
            new EvmExtension({ chainConfig }),
            new OAuthExtension(),
            new AccountExtension(),
          ],
        });
        instance
          .initialize()
          .then(() => {
            passportInstance = instance;
            window.instance = passportInstance;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
    await initializationPromise;
  }
}

export async function loginPassport(email) {
  await initializePassport();
  const param = encodeURIComponent(window.location.href);
  passportInstance.oauth.login({
    scope: "profile profile.write email phone blockchain.evm blockchain.sui",
    redirectUrl: `${window.location.origin}/passport-redirect?redirect=${param}`,
    type: OAuthExtension.LOGIN_TYPE.PASSWORDLESS,
    passwordlessType: OAuthExtension.PASSWORDLESS_LOGIN_TYPE.EMAIL,
    forceEmail: email
  });
}

export async function logoutPassport() {
  await initializePassport();

  await passportInstance.logout();
}

export async function getPassportInstance() {
  await initializePassport();
  window.passport = passportInstance;

  return passportInstance;
}

export async function getPassportInfo() {
  const passport = await getPassportInstance();
  if (passport?.account?.user) return passport.account.user;
  const token = passport.accessToken;
  if (token) {
    const user = await passport.account.info();
    const sui = await getSuiAddress();
    const evm = await getEvmAddress();
    passport.account.user = { ...user, sui_address: sui, evm_address: evm };
    return { ...user, sui_address: sui, evm_address: evm };
  }
  throw "Not Authorized";
}

export async function viewAccountDetails() {
  await initializePassport();

  passportInstance.openUI();
}

const STORAGE_OPTIONS = {
  namespace: "credenza",
  storages: ["local", "cookie"],
};

export function ignorePassport() {
  storage.set("passport_ignored", true, STORAGE_OPTIONS);
}

export function getPassport(listSubscription) {
  return listSubscription?.accounts?.find(
    (account) => account.type === "ListSubscriptionAccount::CredenzaPassport"
  );
}

export function passportConnectedOrIgnored(listSubscription) {
  return (
    getPassport(listSubscription) ||
    storage.get("passport_ignored", STORAGE_OPTIONS)
  );
}
