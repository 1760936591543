import React from 'react';
import classnames from 'classnames';
import ENV from '../../constants';

const TsMagicLoginRedirect = (props) => {
  const {
    path,
    target = '_top',
    children,
    className,
  } = props;

  const href    = `${ENV.API_ROOT}/ts_redirect?path=${path}`;
  const classes = classnames('TsMagicLoginRedirect', className);

  return (
    <a href={href} target={target} className={classes} rel="noopener noreferrer">
      {children}
    </a>
  );
}

export default TsMagicLoginRedirect;