import { useState, useEffect } from 'react';
import Storage from './storage';

const GDPR_COUNTRIES = ['UK', 'GB', 'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'IS', 'NO', 'LI'];
const COOKIE_DAYS = 6 * 31;

class GDPRCookie {
  constructor(countryCode, acceptedCookie) {
    this.countryCode = countryCode;
    this.cookie = acceptedCookie;
    this.deferred = (() => {
      let resolve, reject;
      const promise = new Promise((res, rej) => {
        [resolve, reject] = [res, rej];
      });
      return { promise, reject, resolve };
    })();

    this._init();
  }

  get accepted() {
    return this.deferred.promise;
  }

  get response() {
    switch (this.cookie) {
      case '0':
        return false;
      case '1':
        return true;
      default:
        return null;
    }
  }

  get isGDPRRegion() {
    return GDPR_COUNTRIES.includes(this.countryCode);
  }

  get isPending() {
    return this.isGDPRRegion && this.response === null;
  }

  get isAllowed() {
    const resp = this.response;
    return resp === true || (resp === null && !this.isGDPRRegion);
  }

  accept() {
    Storage.setRawCookie('cc', '1', { expireDays: COOKIE_DAYS });
    this.deferred.resolve();
  }

  decline() {
    Storage.setRawCookie('cc', '0', { expireDays: COOKIE_DAYS });
    this.deferred.reject();
  }

  _init() {
    if (this.isAllowed) {
      this.deferred.resolve();
    }
  }
}

export default function (loaded) {
  const [cookies, setCookies] = useState();
  useEffect(() => {
    if (loaded) {
      setCookies(new GDPRCookie(Storage.getRawCookie('ca2'), Storage.getRawCookie('cc')));
    }
  }, [loaded]);

  return cookies;
}