import React from "react";
import classnames from "classnames";
import Modal from "../Modal";
import { StarIcon } from "@heroicons/react/solid";

export default function (props) {
  const { open, onClose, className } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseButton={true}
      className={classnames("min-w-60 p-4 sm:p-10", className)}
    >
      <div className="text-center">
        <div className="text-xl font-bold uppercase pb-4">
          <i className="icon-check-in mr-1"></i> Complete Your Check-In
        </div>
        <div className="space-y-4 text-lg">
          <div className="flex items-center">
            <div
              className="text-base w-6 h-6 flex-shrink-0 rounded-full flex justify-center items-center border-2 border-solid border-box leading-6 tracking-tighter mr-3"
              style={{ fontFamily: "monospace" }}
            >
              1
            </div>
            <div className="text-left">Find the bar or restaurant you're at on the map.</div>
          </div>
          <div className="flex items-center">
            <div
              className="text-base w-6 h-6 flex-shrink-0 rounded-full flex justify-center items-center border-2 border-solid border-box leading-6 tracking-tighter mr-3"
              style={{ fontFamily: "monospace" }}
            >
              2
            </div>
            <div className="text-left">Hit "CHECK-IN TO WIN."</div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
