import React from 'react';
import { DateTime } from 'luxon';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import storage from '../../services/storage';
import './UserOfferListItem.scss';

const cc = storage.getRawCookie('ca2');
const dateFormatting = (function() {
  // US format
  if (!cc || cc === 'US') {
    return {
      format: 'MM/dd/yyyy',
    }
  }
  // International Date Format
  return {
    format: 'dd/MM/yyyy',
  }
})();

const UserOfferListItem= (props) => {
  const {
    userOffer,
    className,
    handleClick,
  } = props;

  const offer = userOffer.list_offer;
  const classes = classNames('UserOfferListItem p-4 cursor-pointer', className);
  const expiresAt = DateTime.fromISO(offer.expires_at).toFormat(dateFormatting.format);

  return (
    <div className={classes} onClick={() => {
      handleClick(userOffer)
    }}>
      <div className="UserOfferListItem-sender flex">
        {
          offer.sender_avatar
            ? <img className="UserOfferListItem-sender__avatar avatar avatar--md" src={offer.sender_avatar} alt="" />
            : <div className="UserOfferListItem-sender__avatar avatar avatar--md"><i className="icon-mail"></i></div>
        }
        <div className="text-lg nhl-medium">{offer.sender_name}</div>
      </div>
      {/* <Grid className="UserOfferListItem-sender" container alignItems="center">
        {
          offer.sender_avatar
          ? <img className="UserOfferListItem-sender__avatar avatar avatar--md" src={offer.sender_avatar} alt="" />
          : <div className="UserOfferListItem-sender__avatar avatar avatar--md"><i className="icon-mail"></i></div>
        }
        <div className="text-lg">{offer.sender_name}</div>
      </Grid> */}
      <p className="UserOfferListItem-subject mb-3">
        {offer.subject}
      </p>
      <div className="UserOfferListItem-tags flex justify-end">
        <div className="UserOfferListItem-tags__exp rounded-full">
          <span>EXP</span> {expiresAt}
        </div>
      </div>
      {/* <Grid className="UserOfferListItem-tags" container justify="flex-end">
        <Grid item className="UserOfferListItem-tags__exp">
          <span>EXP</span> {expiresAt}
        </Grid>
      </Grid> */}
    </div>
  );
}

export default UserOfferListItem;