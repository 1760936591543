import React, { useState } from "react";
import { Link } from "react-router-dom";
import storage from "../../services/storage";
import classnames from "classnames";
import {
  MailOpenIcon,
  SparklesIcon,
  GiftIcon,
  TicketIcon,
} from "@heroicons/react/outline";
import BluenaticKid from "../BluenaticKid";
import KidsClubLogo from "../../assets/images/kids_club.png";
import LinesSvg from "../../assets/images/lines_2.svg";
const STORAGE_OPTIONS = {
  namespace: "kidsclub",
  storages: ["local", "cookie"],
};

function KidsClubBanner(props) {
  const { handleSubmit, loggedIn } = props;
  const [hidden, setHidden] = useState(false);

  const hideBanner = (e) => {
    e.preventDefault();
    storage.set("kidsclub_ignored", true, STORAGE_OPTIONS);
    setHidden(true);
  };

  if (hidden || storage.get("kidsclub_ignored", STORAGE_OPTIONS)) {
    return null;
  }

  return (
    <div className="KidsClubBanner w-full bg-white relative pt-6 ActivityCard">
      {/* DESKTOP */}
      <div className="flex justify-center items-center ">
        <img src={LinesSvg} className="block h-8" />
        <div className="text-3xl text-black sm:text-5xl uppercase nhl-medium flex items-center justify-center flex-wrap px-2 space-x-1">
          <span>Join the</span>
          <span className="nhl-bold">Kids Club</span>
        </div>
        <img src={LinesSvg} className="block rotate-180 h-8" />
      </div>
      <div className="flex items-center p-3 sm:p-6 flex-col sm:flex-row">
        <img
          src={KidsClubLogo}
          className={classnames("w-full h-auto sm:w-auto", {
            "sm:h-72": !handleSubmit,
            "h-auto sm:h-56": handleSubmit,
          })}
        />
        <div className="pt-6 sm:pt-0 sm:pl-6">
          <div className="text-lg">
            <ul className="list-inside text-black leading-tight">
              <li className="nhl-regular text-2xl mb-3">
                Members receive exclusive benefits including...
              </li>
              <li className="flex items-center mb-3">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <MailOpenIcon className="h-5 w-5" />
                </div>
                A quarterly newsletter for Blue Note Kids Club
                Members
              </li>
              <li className="flex items-center mb-3">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <SparklesIcon className="h-5 w-5" />
                </div>
                Access to members-only events
              </li>
              <li className="flex items-center mb-3">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <TicketIcon className="h-5 w-5" />
                </div>
                Special Ticket Offers
              </li>
              <li className="flex items-center mb-3">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <GiftIcon className="h-5 w-5" />
                </div>
                A sticker of your favorite mascot - Louie!
              </li>
            </ul>
            {!handleSubmit && (
              <div className="mt-6 sm:mt-3 flex flex-col gap-6 items-center sm:flex-row">
                {loggedIn && (
                  <>
                    <Link to="/kids" className="btn w-60 uppercase">
                      Join
                    </Link>
                    <a
                      href="#"
                      onClick={(e) => hideBanner(e)}
                      className="link underline"
                    >
                      Do this later
                    </a>
                  </>
                )}
                {!loggedIn && (
                  <Link
                    to={`/sign-in?redirect=kids`}
                    className="btn w-full uppercase"
                  >
                    Join
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="px-6 pb-6">
        {handleSubmit && (
          <BluenaticKid handleSubmit={handleSubmit} showHeader={false} />
        )}
      </div>
    </div>
  );
}

export default KidsClubBanner;
