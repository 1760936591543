import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { DateTime } from "luxon";
import ENV from "../../constants";
import RegistrationForm from "../../components/RegistrationForm";
import Stepper from "../../components/Stepper";
import Footer from "../../components/Footer";
import BluesFooter from "../../components/BluesFooter";
import BasicHeader from "../../components/BasicHeader";
import history from "../../history";
import storage from "../../services/storage";
import promotionService from "../../services/promotion";
import { get, isEmpty, searchStringToHash, param } from "../../services/utils";
import { passportConnectedOrIgnored } from "../../utils/passport";
import MainContext from "../../contexts/MainContext";
import Api from "../../api";

const FLOW_OBJECT_ID = ENV.ACTIVITY_LIST_ID;
const FLOW_OBJECT_TYPE = "ActivityList";

const cc = storage.getRawCookie("ca2");
const birthdayFormatting = (function () {
  // US format
  if (!cc || cc === "US") {
    return {
      format: "MM/dd/yyyy",
    };
  }
  // International Date Format
  return {
    format: "dd/MM/yyyy",
  };
})();

function SignUp(props) {
  const { session, sessionLoading, sessionReload, sessionComplete, listSubscription, listSubscriptionComplete, activityList } =
    useContext(MainContext);
  const allowedCountries = activityList?.allowed_countries;
  const [userProfile, setUserProfile] = useState(session?.profile);
  const [userProfileLoading, setUserProfileLoading] = useState(false);
  const [userProfileErrors, setUserProfileErrors] = useState(null);

  const createUserProfile = (body) => {
    setUserProfileLoading(true);
    Api.post(`/user_profiles`, {
      user_profile: body,
    })
      .then((res) => {
        setUserProfile(res.user_profile);
      })
      .catch((e) => {
        if (e?.data) setUserProfileErrors(e?.data);
      })
      .finally(() => {
        setUserProfileLoading(false);
      });
  };

  const updateUserProfile = (id, body) => {
    Api.put(`/user_profiles/${id}`, {
      user_profile: body,
    })
      .then((res) => {
        setUserProfile(res.user_profile);
      })
      .catch((e) => {
        if (e?.data) setUserProfileErrors(e?.data);
      });
  };

  const location = useLocation();
  const search = location.search;
  const params = searchStringToHash(search);

  const promotionId = promotionService.getPromotion();

  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("US");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [birthday, setBirthday] = useState("");
  const [recaptcha, setRecaptcha] = useState("");

  const [emailVerified, setEmailVerified] = useState(false);

  const [serverFormError, setServerFormError] = useState("");
  const formFieldDidChange = function (e, fieldName) {
    switch (fieldName) {
      case "phone":
        return setPhone(e);
      case "country":
        return setCountry(e);
      case "firstName":
        return setFirstName(e.target.value);
      case "lastName":
        return setLastName(e.target.value);
      case "password":
        return setPassword(e.target.value);
      case "email":
        setEmail(e.target.value);
        // if email was verified and value changed check to see if it's still the verified email or not
        if (!isEmpty(userProfile) && userProfile.email_verified) {
          setEmailVerified(userProfile.email === email);
        }
        return;
      case "birthday":
        return setBirthday(e.target.value);
      case "recaptcha":
        return setRecaptcha(e);
      default:
        break;
    }
  };

  const submitRegistration = (form) => {
    if (!isEmpty(userProfile)) {
      // if user profile already exists update
      updateUserProfile(userProfile.id, {
        first_name: form.firstName,
        last_name: form.lastName,
        email: form.email,
        email_confirmed: emailVerified,
        birthday: form.birthday.toISODate(),
        after_verify_redirect_url: `${window.location.origin}/verify-email${search}`,
        verify_redirect_url: `${window.location.origin}/email-verified`,
        flow_object_id: FLOW_OBJECT_ID,
        flow_object_type: FLOW_OBJECT_TYPE,
        phone_verification_attributes: {
          phone: form.phone,
          country_code: form.country,
        },
      });
      delete params.edit;
      history.replace({ pathname: "sign-up", search: param(params) });
    } else {
      // else create a new user profile
      createUserProfile({
        first_name: form.firstName,
        last_name: form.lastName,
        email: form.email,
        password: form.password,
        birthday: form.birthday.toISODate(),
        recaptcha_token: form.recaptcha,
        promotion_id: promotionId,
        list_id: ENV.ACTIVITY_LIST_ID,
        after_verify_redirect_url: `${window.location.origin}/verify-email${search}`,
        verify_redirect_url: `${window.location.origin}/email-verified`,
        flow_object_id: FLOW_OBJECT_ID,
        flow_object_type: FLOW_OBJECT_TYPE,
        phone_verification_attributes: {
          phone: form.phone,
          country_code: form.country,
        },
      });
    }
  };

  const reset = (e) => {
    if (e) e.preventDefault();
    delete params.edit;
    history.replace({ pathname: "sign-in", search: param(params) });
  };

  // Watch for server errors from form submission and pass them to the form
  useEffect(() => {
    let errors = null;
    if (userProfileErrors && userProfileErrors.message) {
      errors = `* ${userProfileErrors.message}`;
    }
    setServerFormError(errors);
  }, [userProfileErrors]);

  // populate form with user-profile data or redirect to next step
  useEffect(() => {
    if (sessionComplete) {
      if (listSubscription) {
        if (!passportConnectedOrIgnored(listSubscription)) {
          history.replace({ pathname: "passport", search });
        } else if (params.oauth_redirect) {
          window.top.location = params.oauth_redirect;
          return null;
        } else {
          history.replace(params.redirect || "/");
        }
      } else if (!isEmpty(userProfile)) {
        // profile just created fetch the session
        if (!session && !sessionLoading) {
          sessionReload();
        }
        // user-profile has all relevant fields transition to correct sign-up state
        if (
          !params.edit &&
          userProfile.first_name &&
          userProfile.last_name &&
          (userProfile.pending_email || userProfile.email) &&
          (userProfile.pending_phone || userProfile.phone)
        ) {
          // email not verified yet
          if (userProfile.pending_email) {
            history.replace({ pathname: "verify-email", search });
            // phone not verified yet
          } else if (!userProfile.phone_verified) {
            params.send_code = "true";
            history.replace({
              pathname: "verify-phone",
              search: param(params),
            });
            // else go to subscription questions if they exist
          } else {
            history.replace({ pathname: "form-fields", search });
          }
          // more info required fill in missing fields
        } else {
          setPhone(userProfile.pending_phone || userProfile.phone || "");
          setCountry(
            userProfile.country_code || userProfile.pending_country_code || "US"
          );
          setFirstName(userProfile.first_name || "");
          setLastName(userProfile.last_name || "");
          setEmail(userProfile.pending_email || userProfile.email || "");
          let b = "";
          if (userProfile.birthday) {
            b = DateTime.fromISO(userProfile.birthday).toFormat(
              birthdayFormatting.format
            );
          }
          setBirthday(b);
          setEmailVerified(userProfile.email_verified);
        }
      }
    }
  }, [userProfile, listSubscription]);

  const title = (() => {
    if (isEmpty(userProfile)) {
      return (
        <Fragment>
          <h2>SIGN UP</h2>
          <p>
            Create your Bluenatics account now! A mobile number is required to
            verify your account. Unless additional communications are
            requested,&nbsp;your email address will only be used to notify you
            if you win a Bluenatics prize.
          </p>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <h2>COMPLETE YOUR PROFILE</h2>
          <p>
            We need a little more info from you to finish setting up your
            Bluenatics profile.
          </p>
        </Fragment>
      );
    }
  })();

  const back = (() => {
    if (isEmpty(userProfile)) {
      return (
        <Link
          className="SignUpFlow__back"
          to={{ pathname: "/sign-in", search }}
        >
          <i className="icon-arrow-left"></i> Back
        </Link>
      );
    }
    return (
      <a className="SignUpFlow__back" href="/" onClick={reset}>
        <i className="icon-arrow-left"></i> Start Over
      </a>
    );
  })();

  const formFragment = (
    <Fragment>
      {title}
      <RegistrationForm
        firstName={firstName}
        lastName={lastName}
        phone={phone}
        country={country}
        countries={allowedCountries}
        email={email}
        birthday={birthday}
        password={password}
        recaptcha={recaptcha}
        onChange={formFieldDidChange}
        submit={submitRegistration}
        error={serverFormError}
        emailVerified={emailVerified}
        showPassword={isEmpty(userProfile)}
        loading={userProfileLoading}
      />
      <Stepper steps={4} active={0} />
      <div className="SignUpFlow__links">{back}</div>
    </Fragment>
  );

  return params.display === "oauth" ? (
    <div className="SignUpFlow OAuthFlow">{formFragment}</div>
  ) : (
    <Fragment>
      <BasicHeader />
      <div className="SignUpFlow">{formFragment}</div>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
}

export default SignUp;
