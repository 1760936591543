import React from 'react';
import { Redirect } from 'react-router-dom';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Loading from '../../components/Loading';
import { param, searchStringToHash } from '../../services/utils';
import useRequest from '../../hooks/useRequest';

function StarsOfGame(props) {
  const { id } = props.match.params;
  const location = useLocation();
  const params = searchStringToHash(location.search);

  const [userStars] = useRequest({
    type: 'get',
    path: `/stars_of_games/${id}`,
  });

  if (userStars.loading || !userStars.complete) {
    return <Loading />
  } else if (userStars.data) {
    params.user_stars_id = id;
    return <Redirect to={{
      pathname: `/activity/${userStars.data.stars_of_game.contest_id}`,
      search: param(params)
    }} />
  } else if (userStars.complete) {
    return <Redirect to="/" />
  }
}

export default StarsOfGame;