import React, { useState } from 'react';
import classnames from 'classnames';
import './Checkbox.scss';

function Checkbox(props) {
  const { value, id, controlled, callback, className, children, disabled } = props;
  const [checked, setChecked] = useState(value);
  const checkboxClass = classnames('Checkbox', className, {
    'Checkbox--disabled': disabled,
  });
  const boxClass = classnames('Checkbox__box', {
    'Checkbox__box--checked': controlled && value || !controlled && checked,
  });

  const handleClick = (e) => {
    if (disabled) return;
    if (controlled) {
      if (callback) {
        callback(!value, id);
      }
    } else {
      const val = !checked;
      setChecked(val);
      callback(val, id)
    }
  }

  return (
    <div className={checkboxClass} onClick={handleClick}>
      <div className={boxClass}><i className="icon-check"></i></div>
      {children}
    </div>
  )
}

export default Checkbox;