import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Footer from "../../components/Footer";
import BluesFooter from "../../components/BluesFooter";
import BasicHeader from "../../components/BasicHeader";
import Loading from "../../components/Loading/Loading";
import BackgroundImage from "../../components/BackgroundImage";
import { loginPassport, getPassport } from "../../utils/passport";
import PassportWebSvg from "../../assets/images/passport-web.svg";
import PassportMobileSvg from "../../assets/images/passport-mobile.svg";
import BgWhite from "../../assets/images/bg_white.png";
import {
  TicketIcon,
  CurrencyDollarIcon,
  ShoppingCartIcon,
  BellIcon,
} from "@heroicons/react/outline";
import MainContext from "../../contexts/MainContext";
import { searchStringToHash } from "../../services/utils";

const PassportSignUp = () => {
  const location = useLocation();
  const params = searchStringToHash(location.search);

  const { session, listSubscription } = useContext(MainContext);
  const [working, setWorking] = useState(false);

  function redirect() {
    if (params.oauth_redirect) {
      window.top.location = params.oauth_redirect;
      return null;
    }
    window.top.location = params.redirect || "/";
  }

  async function handleLogin(e) {
    e.preventDefault();
    setWorking(true);
    await loginPassport(session?.profile?.email);
    setWorking(false);
  }

  useEffect(() => {
    if (getPassport(listSubscription)) {
      redirect();
    }
  }, [listSubscription]);

  return params.display === "oauth" ? (
    <BackgroundImage src={BgWhite} className="mx-auto bg-white max-w-screen-md">
      <div className="pt-6 px-6">
        <img src={PassportWebSvg} className="hidden w-full sm:block" />
        <img src={PassportMobileSvg} className="w-full sm:hidden" />
      </div>
      {working && (
        <Loading
          className="h-80"
          message="This could take a minute, please don't leave the page."
        />
      )}
      {!working && (
        <div>
          <p className="pt-4 px-6 text-black text-lg font-bold">
            Join today and earn rewards, discounts, access to ticket presales
            and offers by participating in activities on Bluenatics, and by
            using Blues Pay for your food &amp; beverage, and retail purchases
            at Enterprise Center.
          </p>
          <ul className="p-6 list-inside text-black text-left">
            <li className="flex items-center mb-3 text-lg">
              <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                <BellIcon className="h-5 w-5" />
              </div>
              Notifications when tickets become available for upcoming events
            </li>
            <li className="flex items-center mb-3 text-lg">
              <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                <TicketIcon className="h-5 w-5" />
              </div>
              Earn unique rewards and discounts based on your fandom
            </li>
            <li className="flex items-center mb-3 text-lg text-left">
              <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                <CurrencyDollarIcon className="h-5 w-5" />
              </div>
              Unlock surprise offers when using Blues Pay to purchase
              concessions &amp; retail at Enterprise Center
            </li>
            <li className="flex items-center mb-3 text-lg">
              <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                <ShoppingCartIcon className="h-5 w-5" />
              </div>
              <div>
                Additional exclusive access and opportunities via Bluenatics.{" "}
                <Link to="/passport-about" className="inline underline">
                  Learn more
                </Link>
              </div>
            </li>
          </ul>

          <div className="p-3 md:p-6 bg-white w-full flex flex-col md:flex-row items-center">
            <div className="order-2 mt-4 md:mt-0 md:order-first">
              By connecting your Bluenatics account to Passport, you are
              agreeing to receive push notifications from the Blues App and
              emails from the St. Louis Blues to be notified when you've
              received one of the benefits of this program.
            </div>
            <button
              className="btn uppercase w-full md:ml-6"
              onClick={handleLogin}
            >
              Connect Account
            </button>
          </div>
        </div>
      )}
    </BackgroundImage>
  ) : (
    <Fragment>
      <BasicHeader />
      <BackgroundImage
        src={BgWhite}
        className="mx-auto bg-white mb-6 md:mt-6 max-w-screen-md"
      >
        <div className="pt-6 px-3 md:px-6">
          <img src={PassportWebSvg} className="hidden w-full sm:block" />
          <img src={PassportMobileSvg} className="w-full sm:hidden" />
        </div>
        {working && (
          <Loading
            className="h-80"
            message="This could take a minute, please don't leave the page."
          />
        )}
        {!working && (
          <div>
            <p className="pt-4 px-3 md:px-6 text-black text-lg font-bold leading-snug">
              Join today and earn rewards, discounts, access to ticket presales
              and offers by participating in activities on Bluenatics, and by
              using Blues Pay for your food &amp; beverage, and retail purchases
              at Enterprise Center.
            </p>
            <ul className="p-3 md:p-6 list-inside text-black text-left">
              <li className="flex items-center mb-3 text-lg leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <BellIcon className="h-5 w-5" />
                </div>
                Notifications when tickets become available for upcoming events
              </li>
              <li className="flex items-center mb-3 text-lg leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <TicketIcon className="h-5 w-5" />
                </div>
                Earn unique rewards and discounts based on your fandom
              </li>
              <li className="flex items-center mb-3 text-lg text-left leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <CurrencyDollarIcon className="h-5 w-5" />
                </div>
                Unlock surprise offers when using Blues Pay to purchase
                concessions &amp; retail at Enterprise Center
              </li>
              <li className="flex items-center mb-3 text-lg leading-snug">
                <div className="bg-blues-yellow text-blues-blue p-3 rounded-full inline-block mr-3">
                  <ShoppingCartIcon className="h-5 w-5" />
                </div>
                <div>
                  Additional exclusive access and opportunities via Bluenatics.{" "}
                  <Link to="/passport-about" className="inline underline">
                    Learn more
                  </Link>
                </div>
              </li>
            </ul>

            <div className="p-3 md:p-6 bg-white w-full flex flex-col md:flex-row items-center">
              <div className="order-2 mt-4 md:mt-0 md:order-first">
                By connecting your Bluenatics account to Passport, you are
                agreeing to receive push notifications from the Blues App and
                emails from the St. Louis Blues to be notified when you've
                received one of the benefits of this program.
              </div>
              <button
                className="btn uppercase w-full md:ml-6"
                onClick={handleLogin}
              >
                Connect Account
              </button>
            </div>
          </div>
        )}
      </BackgroundImage>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
};

export default PassportSignUp;
