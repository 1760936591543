import React from 'react';
import LoadingImg from '../../assets/images/loading.gif';
import './Loading.scss';

export default function(props) {
  const { className, message } = props;
  const klass = `Loading ${className ? className : ''}`;
  return (
    <div className={klass}>
      <img src={LoadingImg} alt="" className="Loading__img" />
      {message && <p className="mt-6">{message}</p>}
    </div>
  );
}
