import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import ReactCodeInput from 'react-code-input';
import TS from '../../api';
import history from '../../history';
import ENV from '../../constants';
import Stepper from '../../components/Stepper';
import useRequest from '../../hooks/useRequest';
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import BasicHeader from '../../components/BasicHeader';
import { get, isEmpty, searchStringToHash, param } from '../../services/utils';
import './VerifyPhone.scss';
import MainContext from '../../contexts/MainContext';

const FLOW_OBJECT_ID = ENV.ACTIVITY_LIST_ID;
const FLOW_OBJECT_TYPE = 'ActivityList';

function VerifyPhone(props) {
  const location = useLocation();
  const search = location.search;
  const params = searchStringToHash(search);

  const { session } = useContext(MainContext);
  const userProfile = session?.profile;

  const [code, setCode] = useState(null);
  const [error, setError] = useState(null);
  const [phoneVerificationSent, setPhoneVerificationSent] = useState(false);
  const [phoneVerification, verifyPhone] = useRequest();

  const sendPhoneVerification = function (e) {
    if (e) { e.preventDefault(); }
    setPhoneVerificationSent(true);
    TS.post(`/user_profiles/send_phone_verification`, {
      user_profile:{
        flow_object_id: FLOW_OBJECT_ID,
        flow_object_type: FLOW_OBJECT_TYPE,
      }
    });
    setTimeout(() => {
      setPhoneVerificationSent(false);
    }, 3000);
  }

  const handleClick = (e) => {
    e.preventDefault();
    if (phoneVerification.loading) {
      return;
    }
    setError(null);
    if (!code || code.length !== 4) {
      return setError('* Please enter the full 4-digit code.');
    }
    verifyPhone({
      type: 'post',
      path: '/verify_phone',
      body: {
        token: code,
        user_profile: {
          flow_object_id: FLOW_OBJECT_ID,
          flow_object_type: FLOW_OBJECT_TYPE,
        }
      }
    });
  }

  useEffect(() => {
    // if request complete we reload the page to refetch the session with the updated phone_verified bool
    if (phoneVerification.data) {
      window.location.reload();
    }
    if (phoneVerification.errors) {
      setError('* Incorrect Code');
    }
  }, [phoneVerification.data, phoneVerification.errors]);

  useEffect(() => {
    if (!isEmpty(userProfile)) {
      if (userProfile.pending_email) {
        return history.replace({ pathname: 'verify-email', search });
      } else if (userProfile.phone_verified) {
        return history.replace({ pathname: 'form-fields', search });
      }
    }
  }, [userProfile]);

  // if not logged in go to sign-in
  useEffect(() => {
    if (!session && !userProfile) {
      history.replace({ pathname: 'sign-in', search });
    } else {
      const newSearch = searchStringToHash(window.location.search);
      if (newSearch.send_code === 'true') {
        sendPhoneVerification();
        delete newSearch.send_code;
        history.replace({ pathname: 'verify-phone', search: `?${param(newSearch)}` });
      }
    }
  }, [session, userProfile]);

  if (!session && !userProfile) {
    return null;
  }

  const formFragment = (
    <Fragment>
      <h2 className="VerifyPhone__title">VERIFY YOUR PHONE</h2>
      <p>
        A 4 digit code was sent to <b>{userProfile.pending_phone}</b> please enter the code below to verify your phone number.
        If you did not receive a code you can resend it here:&nbsp;
        { phoneVerificationSent 
          ? <a className="VerifyPhone__resend" href="#" onClick={(e) => e.preventDefault()}>Code Sent</a>
          : <a className="VerifyPhone__resend" href="#" onClick={sendPhoneVerification}>Resend Code</a>
        }
      </p>
      <span className="VerifyPhone__error">{error}</span>
      <ReactCodeInput
        className="VerifyPhone__input" 
        type="number"
        fields={4}
        onChange={(e) => setCode(e)}
      />
      {
        phoneVerification.loading ?
        <button className="btn btn--stretch VerifyPhone__btn"><i className="icon-loading"></i></button>
        :
        <button className="btn btn--stretch VerifyPhone__btn" type="submit">Continue</button>
      }
      <Stepper steps={4} active={2} />
      <div className="SignUpFlow__links">
        <Link className="SignUpFlow__back" to={{ pathname: '/sign-up', search: param({ ...params, edit: true}) }}><i className="icon-arrow-left"></i> Edit</Link>
      </div>
    </Fragment>
  );

  return params.display === 'oauth' ? (
    <form className="SignUpFlow VerifyPhone OAuthFlow" onSubmit={handleClick}>
      {formFragment}
    </form>
  ) : (
    <Fragment>
      <BasicHeader />
      <form className="SignUpFlow VerifyPhone" onSubmit={handleClick}>
        {formFragment}
      </form>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
};

export default VerifyPhone;