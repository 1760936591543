import React, { useState, useEffect, Fragment, useContext, useRef } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import history from "../../history";
import TextInput from "../../components/TextInput";
import Footer from "../../components/Footer";
import BluesFooter from "../../components/BluesFooter";
import BasicHeader from "../../components/BasicHeader";
import {
  get,
  isEmpty,
  validateEmail,
  searchStringToHash,
  param,
} from "../../services/utils";
import { passportConnectedOrIgnored } from "../../utils/passport";
import ENV from "../../constants";
import useForm from "../../hooks/useForm";
import "./Login.scss";
import Api from "../../api";
import MainContext from "../../contexts/MainContext";

function Login(props) {
  const location = useLocation();
  const search = location.search;
  const params = searchStringToHash(search);

  const { session, sessionErrors, sessionCreate, listSubscription } =
    useContext(MainContext);

  const [form, handleChange] = useForm({
    email: "",
    password: "",
    recaptcha_token: "",
  });
  const [error, setError] = useState(null);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const recaptchaRef = useRef();

  const loginEmail = function (e) {
    e.preventDefault();
    setError(null);
    if (!validateEmail(form.email)) {
      return setError("* Please enter a valid email address");
    }
    if (!form.password?.trim()?.length) {
      return setError("* Email or password is incorrect");
    }
    if (showRecaptcha && !form.recaptcha_token) {
      return setError("* Invalid Recaptcha");
    }
    sessionCreate({
      email: form.email,
      password: form.password,
      recaptcha_token: form.recaptcha_token,
    });
  };

  useEffect(() => {
    if (sessionErrors && sessionErrors.status !== 404) {
      setShowRecaptcha(true);
      recaptchaRef?.current?.reset();
      if (sessionErrors.status === 401) {
        setError("* Email or password is incorrect");
      } else if (sessionErrors?.data?.message === "Invalid recaptcha") {
        setError("* Invalid Recaptcha");
      } else {
        setError("* Something went wrong");
      }
    }
  }, [sessionErrors]);

  useEffect(() => {
    if (listSubscription) {
      if (!passportConnectedOrIgnored(listSubscription)) {
        history.replace({ pathname: "passport", search });
      } else {
        if (params.oauth_redirect) {
          window.top.location = params.oauth_redirect;
          return null;
        }
        history.replace(params.redirect || "/");
      }
    } else if (!isEmpty(session)) {
      if (session.profile.pending_email) {
        history.replace({ pathname: "verify-email", search });
      } else if (!session.profile.phone_verified) {
        const newSearch = searchStringToHash(search);
        if (session.profile.phone || session.profile.pending_phone) {
          newSearch.send_code = "true";
          history.replace({
            pathname: "verify-phone",
            search: `?${param(newSearch)}`,
          });
        } else {
          history.replace({
            pathname: "sign-up",
            search: `?${param(newSearch)}`,
          });
        }
      } else if (!listSubscription) {
        history.replace({ pathname: "form-fields", search });
      }
    }
  }, [session]);

  const formFragment = (
    <Fragment>
      <TextInput
        name="email"
        placeholder="Email Address"
        type="email"
        value={form.email}
        error={error}
        onChange={handleChange}
      />
      <TextInput
        name="password"
        placeholder="Password"
        type="password"
        value={form.password}
        onChange={handleChange}
      />
      <ReCAPTCHA
        className={classnames("SignupFlow", {
          "hidden": !showRecaptcha,
          "inline-block": showRecaptcha
        })}
        name="recaptcha_token"
        sitekey={ENV.KEYS.google.reCaptchaKey}
        onChange={(e) =>
          handleChange({ target: { value: e, name: "recaptcha_token" } })
        }
        ref={recaptchaRef}
      />
      <button className="btn btn--stretch" type="submit">
        Continue
      </button>
      <div className="SignUpFlow__links">
        <Link
          className="SignUpFlow__back"
          to={{ pathname: "/sign-in", search }}
        >
          <i className="icon-arrow-left"></i> Back
        </Link>
        <div className="SignUpFlow__forgot-password">
          <Link to={{ pathname: "/forgot-password", search }}>
            Forgot Password?
          </Link>
        </div>
      </div>
    </Fragment>
  );

  return params.display === "oauth" ? (
    <form className="SignUpFlow Login OAuthFlow" onSubmit={loginEmail}>
      <h2>LOG IN</h2>
      <p>
        Enter your email and password below to log into your Bluenatics account.
      </p>
      {formFragment}
    </form>
  ) : (
    <Fragment>
      <BasicHeader />
      <form className="SignUpFlow Login" onSubmit={loginEmail}>
        <h2>LOG IN</h2>
        <p>
          Enter your email and password below to log into your Bluenatics
          account.
        </p>
        {formFragment}
      </form>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
}

export default Login;
