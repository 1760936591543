import React from 'react';
import classnames from 'classnames';
import './Empty.scss';

export default function(props) {
  const {
    message = "Nothing to see here.",
    className
  } = props;

  const classes = classnames('Empty', className);

  return <div className={classes}>{message}</div>;
}
