import React, { useState, useMemo, Fragment, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import Dropdown from 'react-dropdown';
import Grid from '@material-ui/core/Grid';
import PhoneInput from '../../components/PhoneInput';
import Menu from '../../components/Menu';
import NavigationHeader from '../../components/NavigationHeader';
import MustBeSubscribed from '../../components/MustBeSubscribed';
import PassportSuccessNotification from "../../components/PassportSuccessNotification";
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import Checkbox from '../../components/Checkbox';
import FlashNotification from '../../components/FlashNotification';
import BackgroundImage from '../../components/BackgroundImage';
import TsMagicLoginRedirect from '../../components/TsMagicLoginRedirect';
import ENV from '../../constants';
import { get, searchStringToHash } from '../../services/utils';
import Passport from '../../components/Passport';
import TS from "../../api";
import {
  GiftIcon,
  CurrencyDollarIcon,
  ShoppingCartIcon,
  BellIcon,
  CheckCircleIcon
} from "@heroicons/react/outline";

import './Account.scss';
import { getPassport } from '../../utils/passport';
import MainContext from '../../contexts/MainContext';

function Account(props) {
  const location = useLocation();
  const params = searchStringToHash(location.search);
  const { session, listSubscription, listSubscriptionUpdate, activityList } = useContext(MainContext);
  const formFields = activityList?.form_fields;
  const allowedCountries = activityList?.allowed_countries;
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // select
  const onSelect = useCallback((e, fieldId) => {
    setData(prevState => {
      return {
        ...prevState,
        [fieldId]: e.value,
      }
    });
  }, [setData]);

  // single checkbox
  const checkboxClicked = useCallback((checked, fieldId) => {
    setData(prevState => {
      return {
        ...prevState,
        [fieldId]: checked,
      }
    });
  }, [setData]);

  // multi select
  const multiCheckboxClicked = useCallback((checked, fieldId, optionId) => {
    setData(prevState => {
      return {
        ...prevState,
        [fieldId]: {
          ...prevState[fieldId],
          [optionId]: checked
        }
      }
    });
  }, [setData]);

  // submit
  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    try {
      await listSubscriptionUpdate(listSubscription.id, {
        list_subscription: {
          list_id: ENV.ACTIVITY_LIST_ID,
          form_inputs: formFields.filter(val => val.permission !== 'create_only').map(f => {
            const formFieldId = listSubscription.form_inputs.find(val => val.field_id === f.id).field_id;
            if (f.input_type === 'select' || f.input_type === 'checkbox') {
              return {
                field_id: formFieldId,
                value: data[f.id],
              }
            } else if (f.input_type === 'multiple_select') {
              return {
                field_id: formFieldId,
                values: data[f.id] ? Object.keys(data[f.id]).reduce((acc, currentValue) => {
                  if (data[f.id][currentValue]) {
                    acc.push(currentValue);
                  }
                  return acc;
                }, []) : [],
              }
            }
          })
        }
      });
      setLoading(false);
      setOpen(true);
    } catch (error) {
      setLoading(false);
    }
  }, [listSubscriptionUpdate, listSubscription, setData, data, loading]);

  const onPassportConnect = useCallback((passportUser) => {
    TS.post(`/credenza_passports?list_subscription_id=${listSubscription.id}`, {
      credenza_passport: {
        ext_id: passportUser.id,
        email: passportUser.email,
        address: passportUser.evm_address,
      },
    }).then(res => {
      const updated = {...listSubscription };
      updated.accounts.push(res);
      listSubscriptionUpdate(listSubscription.id, { list_subscription: updated });
    });
  }, [listSubscription, listSubscriptionUpdate]);

  const onPassportDisconnect = useCallback(() => {
    const passport = getPassport(listSubscription);
    if (passport) {
      TS.delete(`/credenza_passports/${passport.id}?list_subscription_id=${listSubscription.id}`).then(() => {
        const updated = { ...listSubscription };
        const index = listSubscription.accounts.findIndex(val => val.id === passport.id);
        updated.accounts.splice(index, 1);
        listSubscriptionUpdate(listSubscription.id, { list_subscription: updated });
      });
    }
  }, [listSubscription, listSubscriptionUpdate]);

  // reduce existing data to object iwth field_id's as keys
  const userFormFields = useMemo(() => {
    if (listSubscription) {
      return listSubscription.form_inputs.reduce((acc, i) => {
        return {
          ...acc,
          [i.field_id]: i,
        }
      }, {});
    }
    return {}
  }, [listSubscription]);

  const fields = useMemo(() => {
    return formFields?.filter(val => val.permission !== 'create_only').map(f => {
      if (f.input_type === 'select') {
        const options = f.options.map(opt => {
          return {
            value: opt.id,
            label: (
              <Fragment>
                { opt.photo && <BackgroundImage src={opt.photo} size="contain" />}
                <label>{opt.label}</label>
              </Fragment>
            )
          }
        });
        let defaultValue;
        if (get(userFormFields, `${f.id}.selection.id`)) {
          defaultValue = {
            value: userFormFields[f.id].selection.id,
            label: (
              <Fragment>
                { userFormFields[f.id].selection.photo && <BackgroundImage src={userFormFields[f.id].selection.photo} size="contain" />}
                <label>{userFormFields[f.id].selection.label}</label>
              </Fragment>
            )
          }
          setData(prevState => {
            return {
              ...prevState,
              [f.id]: userFormFields[f.id].selection.id,
            }
          });
        }
        return (
          <div className="FormFields-field" key={f.id}>
            <b className="FormFields-field__title">{f.label}</b>
            <Dropdown className="FormFields-field__dropdown" options={options} value={defaultValue} onChange={e => onSelect(e, f.id)} />
          </div>
        )
      }
      if (f.input_type === 'multiple_select') {
        const checkboxes = f.options.map(opt => {
          const defaultValue = get(userFormFields, `${f.id}.selections`) ? userFormFields[f.id].selections.find(val => val.id === opt.id) : null;
          if (defaultValue) {
            setData(prevState => {
              return {
                ...prevState,
                [f.id]: {
                  ...prevState[f.id],
                  [opt.id]: true,
                }
              }
            });
          }
          return (
            <Checkbox className="FormFields-field__checkbox" id={opt.id} value={defaultValue} key={opt.id} callback={checked => multiCheckboxClicked(checked, f.id, opt.id)}>
              <label>
                {opt.label}
              </label>
            </Checkbox>
          )
        })
        return (
          <div className="FormFields-field" key={f.id}>
            <b className="FormFields-field__title">{f.label}</b>
            {checkboxes}
          </div>
        )
      }
      if (f.input_type === 'checkbox') {
        const defaultValue = get(userFormFields, `${f.id}.value`);
        if (defaultValue) {
          setData(prevState => {
            return {
              ...prevState,
              [f.id]: defaultValue === 'true'
            }
          });
        }
        return (
          <div className="FormFields-field" key={f.id}>
            <Checkbox className="FormFields-field__checkbox" id={f.id} key={f.id} value={defaultValue === 'true'} callback={checked => checkboxClicked(checked, f.id)}>
              <label>
                <div>{f.label}</div>
              </label>
            </Checkbox>
          </div>
        )
      }
      return (
        <div className="FormFields-field" key={f.id}>
          <b className="FormFields-field__title">{f.label}</b>
        </div>
      );
    });
  }, [formFields, userFormFields]);

  return (
    <MustBeSubscribed>
      <Menu path={props.location.pathname} display={params.display} />
      {params.display !== "app" && (
        <NavigationHeader path={props.location.pathname} />
      )}
      <div className="Account">
        <h2
          className="Account-settings__title flex items-center space-x-1"
          id="bluenatics_passport"
        >
          <span>BLUENATICS PASSPORT</span>
          {/* <img src={NewSvg} className="text-xs h-7 w-7 mb-1" /> */}
        </h2>
        <div className="mt-4 h-full">
          <div>
            <h1 className="text-xl font-bold mb-1 text-blues-light-blue">
              Introducing Bluenatics Passport
            </h1>
            <p className="text-black mb-4">
              Earn rewards, discounts, and offers by participating in activities
              on Bluenatics, using Blues Pay for your food & beverage, and
              retail purchases at Enterprise Center.
            </p>

            <ul className="list-inside text-black">
              <li className="flex items-center mb-2">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <BellIcon className="h-5 w-5" />
                </div>
                Notifications when tickets become available for upcoming events
              </li>
              <li className="flex items-center mb-2">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <CurrencyDollarIcon className="h-5 w-5" />
                </div>
                Earn unique rewards and discounts based on your fandom
              </li>
              <li className="flex items-center mb-2">
                <div className="bg-blues-yellow text-blues-blue text-left p-2 rounded-full inline-block mr-3">
                  <ShoppingCartIcon className="h-5 w-5" />
                </div>
                Unlock surprise offers when using Blues Pay to purchase
                concessions &amp; retail at Enterprise Center
              </li>
              <li className="flex items-center mb-2">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <GiftIcon className="h-5 w-5" />
                </div>
                <div>
                  Additional exclusive opportunities for Bluenatics.{" "}
                  <Link to="/passport-about" className="underline">
                    Learn more
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <Passport
              email={get(session, "profile.email")}
              passport={getPassport(listSubscription)}
              onConnect={onPassportConnect}
              onDisconnect={onPassportDisconnect}
            />
          </div>
        </div>
        <form
          className="Account-settings"
          onSubmit={onSubmit}
          id="bluenatics_profile"
        >
          <h2 className="Account-settings__title">PROFILE</h2>

          {/* FIRST NAME */}
          <div className="Account-settings-field">
            <label className="Account-settings-field__label">First Name</label>
            <div className="Account-settings-field__wrapper">
              <input
                className="Account-settings-field__input"
                type="text"
                value={get(session, "profile.first_name")}
                disabled
              />
              <TsMagicLoginRedirect
                path="/settings/account"
                className="avatar Account-settings-field__avatar"
              >
                <i className="icon-edit"></i>
              </TsMagicLoginRedirect>
            </div>
          </div>

          {/* LAST NAME */}
          <div className="Account-settings-field">
            <label className="Account-settings-field__label">Last Name</label>
            <div className="Account-settings-field__wrapper">
              <input
                className="Account-settings-field__input"
                type="text"
                value={get(session, "profile.last_name")}
                disabled
              />
              <TsMagicLoginRedirect
                path="/settings/account"
                className="avatar Account-settings-field__avatar"
              >
                <i className="icon-edit"></i>
              </TsMagicLoginRedirect>
            </div>
          </div>

          {/* EMAIL */}
          <div className="Account-settings-field">
            <label className="Account-settings-field__label">Email</label>
            <div className="Account-settings-field__wrapper">
              <input
                className="Account-settings-field__input"
                type="text"
                value={get(session, "profile.email")}
                disabled
              />
              <TsMagicLoginRedirect
                path="/settings/account"
                className="avatar Account-settings-field__avatar"
              >
                <i className="icon-edit"></i>
              </TsMagicLoginRedirect>
            </div>
          </div>

          {/* PHONE NUMBER */}
          <div className="Account-settings-field">
            <label className="Account-settings-field__label">
              Mobile Number
            </label>
            <div className="Account-settings-field__wrapper">
              <PhoneInput
                className="Account-settings-field__input Account-settings-field__input--phone"
                disabled={true}
                placeholder="Mobile Number"
                international={false}
                onChange={() => null}
                displayInitialValueAsLocalNumber={true}
                countries={allowedCountries}
                country={get(session, "profile.country_code")}
                value={get(session, "profile.phone")}
              />
              <TsMagicLoginRedirect
                path="/update-phone"
                className="avatar Account-settings-field__avatar"
              >
                <i className="icon-edit"></i>
              </TsMagicLoginRedirect>
            </div>
          </div>

          {/* PASSWORD */}
          <div className="Account-settings-field">
            <label className="Account-settings-field__label">Password</label>
            <div className="Account-settings-field__wrapper">
              <input
                className="Account-settings-field__input"
                type="password"
                value="thisisapassword"
                disabled
              />
              <TsMagicLoginRedirect
                path="/update-password"
                className="avatar Account-settings-field__avatar"
              >
                <i className="icon-edit"></i>
              </TsMagicLoginRedirect>
            </div>
          </div>
          <TsMagicLoginRedirect path="/settings">
            <i className="icon-settings-spotify"></i> Advanced Options
          </TsMagicLoginRedirect>

          <h2 className="Account-settings__title" id="bluenatic_info">
            BLUENATIC INFO
          </h2>
          {fields}
          <Grid container>
            <Grid item xs={12} sm={5}>
              <button type="submit" className="btn btn--stretch">
                {loading ? <i className="icon-loading" /> : "UPDATE"}
              </button>
            </Grid>
          </Grid>

          <h2 className="Account-settings__title" id="danger_zone">
            DANGER ZONE
          </h2>
          <div className="flex items-center mt-8">
            <div className="w-full pr-6">
              <b>Deactivate Account</b>
              <p className="text-gray-400">
                Temporarily disable your account and restrict access to your
                data.
              </p>
            </div>
            <span>
              <TsMagicLoginRedirect
                path="/settings/deactivate-account"
                className="cursor-pointer text-lg"
              >
                Deactivate
              </TsMagicLoginRedirect>
              <i className="icon-chevron-right link-color"></i>
            </span>
          </div>
          <div className="flex items-center mt-5">
            <div className="w-full pr-6">
              <b>Delete Account</b>
              <p className="text-gray-400">
                Permanently delete your account. This action cannot be undone.
              </p>
            </div>
            <span>
              <TsMagicLoginRedirect
                path="/settings/delete-account"
                className="cursor-pointer text-lg"
              >
                Delete
              </TsMagicLoginRedirect>
              <i className="icon-chevron-right link-color"></i>
            </span>
          </div>
        </form>
        <FlashNotification
          isOpen={open}
          timeout={5000}
          handleClose={() => setOpen(false)}
        >
          <div className="flex items-center text-green-500">
            <div>
              <CheckCircleIcon className="h-5 w-5" />
            </div>
            <p className="ml-3">Account Updated</p>
          </div>
        </FlashNotification>
        <PassportSuccessNotification />
      </div>
      {params.display !== "app" && (
        <Fragment>
          <Footer />
          <BluesFooter />
        </Fragment>
      )}
    </MustBeSubscribed>
  );
}

export default Account;