import React, { useEffect } from "react";
import FlashNotification from "../FlashNotification";
import storage from "../../services/storage";
import { CheckCircleIcon } from "@heroicons/react/solid";
import useAsyncState from '../../hooks/useAsyncState';

const OPTIONS = {
  namespace: "credenza",
  storages: ["local"],
};

const PassportSuccessNotification = function () {
  const [open, setOpen] = useAsyncState(false);

  useEffect(() => {
    const connected = storage.get("passport_connected", OPTIONS);
    if (connected) {
      setOpen(true);
      storage.remove("passport_connected", OPTIONS);
    }
  }, []);

  return (
    <FlashNotification
      isOpen={open}
      timeout={5000}
      handleClose={() => setOpen(false)}
    >
      <div className="flex items-center text-green-500">
        <div>
          <CheckCircleIcon className="h-5 w-5" />
        </div>
        <p className="ml-3">Passport Connected</p>
      </div>
    </FlashNotification>
  );
};

export default PassportSuccessNotification;
