import React, { useEffect, useState } from 'react';
import GDPRCookie from '../../services/gdprCookie';

function CookieBanner(props) {
  const { sessionFetched } = props;
  const cookies = GDPRCookie(sessionFetched);
  const [isOpen, setIsOpen] = useState(cookies?.isPending);

  function handleClick(value) {
    setIsOpen(false);
    value ? cookies.accept() : cookies.decline();
  }

  useEffect(() => {
    if (cookies) {
      setIsOpen(cookies.isPending);
    }
  }, [cookies]);

  useEffect(() => {
    if (cookies) {
      cookies.accepted.then(() => {
        window.initGtag();
        window.initFacebook();
        window.initFBPixel();
      }).catch(() => { });
    }
  }, [cookies]);

  if (!isOpen) return null;

  return (
    <div className='fixed md:flex justify-between bottom-0 left-0 w-full bg-white shadow-lg p-4 md:p-8 z-50'>
      <p className='text-xs md:mr-6'>
        This site uses cookies and other tracking technologies to tailor your experience, understand how you use our site, and target and tailor advertising. If you do not click "accept" or chose "reject" below, this site will only use cookies necessary for the its operation. For more info on how to withdraw consent and manage the use of cookies on this site, please refer to our <a href="/about-our-ads" className='link-blue' target="_blank">tracking tools policy</a>.
      </p>

      <div className='flex items-center mt-6 md:mt-0'>
        <button className='btn border rounded-sm border-gray-200 text-center font-light w-full md:w-40 mr-6' onClick={() => handleClick(false)}>Reject</button>
        <button className='font-bold rounded-sm btn btn-green text-center text-white w-full md:w-40' onClick={() => handleClick(true)}>Accept</button>
      </div>
    </div>
  )
}


export default CookieBanner;