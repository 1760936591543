import React, { Fragment } from "react";
import BasicHeader from "../../components/BasicHeader";
import Footer from "../../components/Footer";
import BluesFooter from "../../components/BluesFooter";
import NavigationHeader from "../../components/NavigationHeader/NavigationHeader";
import Menu from "../../components/Menu/Menu";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";
import BgWhite from "../../assets/images/bg_white.png";
import TagEnd from "../../assets/images/tag_end.svg";
import PassportScan from "../../assets/images/passport_scan.png";
import PassportConcession from "../../assets/images/passport_concession.png";
import LinesSvg from "../../assets/images/lines.svg";

const PassportAbout = (props) => {
  return (
    <Fragment>
      <Menu path={props.location.pathname} />
      <NavigationHeader />
      <div className="PassportAbout">
        <div className="sm:my-8 bg-white max-w-3xl mx-auto">
          <div>
            <BackgroundImage src={BgWhite} className="w-full h-24 text-center">
              <div className="px-3 sm:px-6 py-8 text-4xl sm:text-5xl flex justify-center flex-wrap text-blues-light-blue space-x-1">
                <span className="signpainter bg-blues-yellow">
                  What&nbsp;is
                </span>
                <span className="nhl-bold">Bluenatics</span>
                <span className="nhl-medium">Passport</span>
              </div>
            </BackgroundImage>
            <div className="p-3 sm:p-8">
              <div className="flex text-blues-light-blue text-2xl nhl-medium">
                <img src={TagEnd} className="hidden sm:inline-block sm:h-24" />
                <div className="uppercase bg-blues-yellow flex items-center flex-wrap p-3 justify-center text-center space-x-1">
                  <span>Beginning in fall 2023, we're introducing</span>
                  <span className="nhl-bold">Bluenatics Passport</span>
                  <span>
                    A new way to unlock benefits by simply being a blues fan!
                  </span>
                </div>
                <img
                  src={TagEnd}
                  className="-ml-px hidden sm:inline-block sm:h-24 rotate-180"
                />
              </div>

              <div className="block mt-6 sm:flex sm:mt-12 text-xl leading-tight">
                <img
                  src={PassportConcession}
                  className="sm:w-2/5 sm:order-1 sm:ml-3"
                />
                <div className="mt-3 sm:mt-0">
                  By participating in activities on Bluenatics, using mobile
                  wallet for your food & beverage and retail purchases at
                  Enterprise Center and more, fans will have the opportunity to
                  earn rewards, discounts, and offers.
                  <br></br>
                  <br></br>
                  Fans who opt-in to the program will also be eligible for
                  additional surprise-and-delights such as tickets, merchandise,
                  gift cards, or exclusive experiences.
                </div>
              </div>

              <div className="block mt-6 sm:flex sm:mt-12 text-xl leading-tight">
                <img src={PassportScan} className="sm:w-2/5 sm:mr-3" />
                <div className="mt-3 sm:mt-0">
                  Simply opt-in to Passport on your Bluenatics account (you can
                  check your status by going into your Account settings) and
                  you're all set.
                  <br></br>
                  <br></br>
                  Make sure you have notifications in the Blues app enabled so
                  you don't miss being notified when you receive a reward!
                </div>
              </div>

              <div className="flex mt-12">
                <img src={LinesSvg} className="hidden sm:block h-12" />
                <div
                  className="text-xl text-white sm:text-2xl sm:mx-2 uppercase nhl-medium flex items-center justify-center flex-wrap px-8 space-x-1"
                  style={{ backgroundColor: "#1c488a" }}
                >
                  <span>Keep an eye out for more details about</span>
                  <span className="nhl-bold">Passport</span>
                  <span>in the future!</span>
                </div>
                <img
                  src={LinesSvg}
                  className="hidden sm:block rotate-180 h-12"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <BluesFooter />
    </Fragment>
  );
};

export default PassportAbout;
