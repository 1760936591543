import React from 'react';
import BluesSvg from "../../assets/images/STL_dark.svg";
import './BluesFooter.scss';

const BluesFooter = () => {
  return (
    <footer className="site-footer" data-is-club="">
      <div className="site-footer__container">
        <div className="site-footer__sponsor-links"></div>

        <div className="site-footer__colophon-container">
          <a className="site-footer__team-link" href="https://www.nhl.com/blues">
            <img className="logo site-footer__team-logo" src={BluesSvg} role="img" alt="St. Louis Blues" />
          </a>
          <div>
            <nav className="site-footer__legal-links">
              <a className="site-footer__legal-link" href="https://www.nhl.com/blues/info/privacy-policy" data-phone-href="https://www.nhl.com/blues/info/privacy-policy">Blues Privacy Policy</a>
              <a className="site-footer__legal-link" href="http://info.evidon.com/pub_info/431?v=1" data-phone-href="http://info.evidon.com/pub_info/431?v=1">Adchoices</a>
              <a className="site-footer__legal-link" href="https://www.teamworkonline.com/hockey-jobs/hockeyjobs/st--louis-blues" data-phone-href="https://www.teamworkonline.com/hockey-jobs/hockeyjobs/st--louis-blues">Employment &amp; Internships</a>
              <a className="site-footer__legal-link" href="https://www.nhl.com/blues/info/contact-us" data-phone-href="https://www.nhl.com/blues/info/contact-us">Contact Us</a>
            </nav>
            <p className="site-footer__colophon">stlouisblues.com is the official Web site of the St Louis Blues. St Louis Blues, stlouisblues.com and stlblues.com are trademarks of the St Louis Blues Hockey Club. NHL, the NHL Shield, the word mark and image of the Stanley Cup and NHL Conference logos are registered trademarks of the National Hockey League. All NHL logos and marks and NHL team logos and marks as well as all other proprietary materials depicted herein are the property of the NHL and the respective NHL teams and may not be reproduced without the prior written consent of NHL Enterprises, L.P. Copyright © 1999-2020 St. Louis Blues Hockey Club and the National Hockey League. All Rights Reserved.</p>
          </div>
        </div>
      </div>
      
    </footer>
  );
}

export default BluesFooter;