import React from 'react';

import './ActivityGrid.scss';

export default function(props) {
  return (
    <div className="ActivityGrid">
      {props.children}
    </div>
  );
}
