import React, { useEffect, useState, Fragment, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import ActivityCard from '../../components/ActivityCard';
import NavigationHeader from '../../components/NavigationHeader';
import Menu from '../../components/Menu';
import Footer from '../../components/Footer';
import BluesFooter from '../../components/BluesFooter';
import PassportSuccessNotification from '../../components/PassportSuccessNotification';
import promotionService from '../../services/promotion';
import ENV from '../../constants';
import { get, searchStringToHash } from '../../services/utils';
import useRequest from '../../hooks/useRequest';
import './Activity.scss';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import MainContext from '../../contexts/MainContext';
import UserContestContext from '../../contexts/UserContestContext';
import PassportActivityBanner from '../../components/PassportActivityBanner';
import { getPassport } from "../../utils/passport";

function Activity(props) {
  const location = useLocation();
  const params = searchStringToHash(location.search);
  const { id } = props.match.params;
  const { session, listSubscription } = useContext(MainContext);
  const { fetchUserContests } = useContext(UserContestContext);

  const showPassportBanner = useMemo(() => {
    return !getPassport(listSubscription);
  }, [listSubscription]);

  const [activity] = useRequest({
    type: 'get',
    path: `/activities/${id}?activity_list_id=${ENV.ACTIVITY_LIST_ID}`
  });

  useEffect(() => {
    promotionService.setPromotionFromQueryString();
  }, []);

  useEffect(() => {
    if (session?.id && id) {
      fetchUserContests({contest_ids: [id]});
    }
  }, [session?.id]);

  const content = (() => {
    if (activity.loading) {
      return <Loading />
    } else if (activity.data) {
      const {
        title,
        og_title,
        og_description,
        shareable_facebook_photo,
      } = activity.data.activity;

      return (
        <Fragment>
          <Helmet>
            <title>{`${title} | St. Louis Blues`}</title>

            {og_title && <meta property="og:title" content={og_title} />}
            {og_description && (
              <meta property="og:description" content={og_description} />
            )}
            {shareable_facebook_photo && (
              <meta property="og:image" content={shareable_facebook_photo} />
            )}

            {og_title && <meta name="twitter:title" content={og_title} />}
            {og_description && (
              <meta name="twitter:description" content={og_description} />
            )}
            {shareable_facebook_photo && (
              <meta name="twitter:image" content={shareable_facebook_photo} />
            )}
          </Helmet>
          <ActivityCard
            key={activity.data.activity.id}
            activity={activity.data.activity}
            standalone={true}
          />
          {showPassportBanner && <PassportActivityBanner className="mt-6" />}
          <Link
            to={"/"}
            className="mt-6 mb-5 link uppercase flex items-center text-2xl nhl-regular justify-center sm:justify-start"
          >
            <span className="bg-white p-0.5 flex justify-center items-center rounded-full mr-2">
              <ChevronLeftIcon className="h-4 w-4" />
            </span>
            Return to Home
          </Link>
        </Fragment>
      );
    }
    return <Empty message="Not Found" />
  })();

  return (
    <Fragment>
      <Menu
        path={props.location.pathname}
        display={params.display}
      />
      {params.display !== "app" && (
        <NavigationHeader path={props.location.pathname} />
      )}
      <div
        className={classnames("Activity py-3 sm:pt-5 px-1", {
          "in-app": params.display === "app",
        })}
      >
        <header>
          <h1 className="logo" alt="LOGO" />
        </header>
        {content}
      </div>
      {params.display !== "app" && (
        <Fragment>
          <Footer />
          <BluesFooter />
          <PassportSuccessNotification />
        </Fragment>
      )}
    </Fragment>
  );
}

export default Activity;