import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Redirect } from 'react-router-dom';
import Loading from '../../components/Loading';
import useRequest from '../../hooks/useRequest';
import { searchStringToHash, param } from '../../services/utils';

function RosterPollShare(props) {
  const { id } = props.match.params;
  const location = useLocation();
  const params = searchStringToHash(location.search)

  const [pollShare] = useRequest({
    type: 'get',
    path: `/poll_shares/${id}`,
  });

  if (pollShare.loading || !pollShare.complete) {
    return <Loading />
  } else if (pollShare.data) {
    params.poll_share_id = id;
    return <Redirect to={{
      pathname: `/activity/${pollShare.data.poll_share.contest_id}`,
      search: param(params)
    }} />
  } else if (pollShare.complete) {
    return <Redirect to="/" />
  }
}

export default RosterPollShare;