import React, { Suspense, useRef } from 'react';
import { get } from '../../services/utils';
import './ActivityCard.scss';

function determineComponentName(activity) {
  if (activity.type === 'Raffle') {
    if (activity.contest_actions[0]) {
      return 'RaffleWithAction';
    } else {
      return 'Raffle';
    }
  }
  if (activity.type === 'GameDay') {
    return 'GameDay';
  }
  switch (activity.contest_actions[0].template) {
    case 'apple-album-save':
    case 'apple-song-save':
      return 'AppleSave';
    case 'spotify-album-save':
    case 'spotify-song-save':
      return 'SpotifySave';
    case 'reveal':
      return 'Reveal';
    case 'share':
      return 'Share';
    case 'poll-response':
      return 'Poll';
    case 'youtube-video-view':
      return 'YoutubeVideoView';
    case 'video-view':
      return 'Video';
    case 'mailing-list-entry':
      return 'MailingListEntry';
    case 'score-guess':
      return 'GuessTheScore';
    case 'starting-lineup-submission':
      return 'StartingLineup';
    case 'link-click':
      return 'LinkClick';
    case 'stars-of-game-submission':
      return 'StarsOfGameSubmission';
    case 'pico':
      return 'Pico';
    case 'typeform-response':
      return 'Typeform';
    case 'check-in':
      return 'CheckIn';
    case 'roster-poll-response':
      return 'RosterPollResponse';
    default:
      break;
  }
}

const COMPONENTS = {
  Raffle: React.lazy(() => import(`../Raffle`)),
  Video: React.lazy(() => import(`../Video`)),
  Poll: React.lazy(() => import(`../Poll`)),
  Share: React.lazy(() => import(`../Share`)),
  Reveal: React.lazy(() => import('../Reveal')),
  SpotifySave: React.lazy(() => import('../SpotifySave')),
  AppleSave: React.lazy(() => import('../AppleSave')),
  YoutubeVideoView: React.lazy(() => import(`../YoutubeVideoView`)),
  MailingListEntry: React.lazy(() => import(`../MailingListEntry`)),
  GuessTheScore: React.lazy(() => import('../GuessTheScore')),
  RaffleWithAction: React.lazy(() => import('../RaffleWithAction')),
  StartingLineup: React.lazy(() => import('../StartingLineup')),
  LinkClick: React.lazy(() => import('../LinkClick')),
  StarsOfGameSubmission: React.lazy(() => import('../StarsOfGameSubmission')),
  Pico: React.lazy(() => import('../Pico')),
  Typeform: React.lazy(() => import('../Typeform')),
  CheckIn: React.lazy(() => import('../CheckIn')),
  RosterPollResponse: React.lazy(() => import('../RosterPollResponse')),
  GameDay: React.lazy(() => import('../GameDay')),
};

export default function (props) {
  const { activity, standalone, kidUser } = props;
  const ref = useRef();
  const componentName = determineComponentName(activity);
  const classNames = [
    'ActivityCard',
    `ActivityCard--pos-${get(activity, 'listing.position') || 1}`,
    `ActivityCard--type-${componentName}`,
    props.className
  ].join(' ');

  const TagName = COMPONENTS[componentName];
  return (
    <div className={classNames}>
      <Suspense fallback={null}>
        <div ref={ref} style={{ position: 'relative' }}>
          <TagName
            activity={activity}
            standalone={standalone}
            kidUser={kidUser}
            activityRef={ref}
          />
        </div>
      </Suspense>
    </div>
  );
}
