import React, { useState, Suspense, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import classnames from "classnames";
import { isValidPhoneNumber } from 'react-phone-number-input';
import { DateTime } from 'luxon';
import TextInput from '../TextInput';
import PhoneInput from '../PhoneInput';
import ENV from '../../constants';
import storage from '../../services/storage';
import { validateEmail } from '../../services/utils';
import './RegistrationForm.scss';

const PasswordStrengthInput = React.lazy(() => import("../../components/PasswordStrengthInput"));

const cc = storage.getRawCookie('ca2');
const birthdayFormatting = (function() {
  // US format
  if (!cc || cc === 'US') {
    return {
      format: 'MM/dd/yyyy',
      placeholder: 'Birthday (mm/dd/yyyy)',
    }
  }
  // International Date Format
  return {
    format: 'dd/MM/yyyy',
    placeholder: 'Birthday (dd/mm/yyyy)',
  }
})();

function RegistrationForm(props) {
  const {
    firstName,
    lastName,
    password,
    email,
    birthday,
    recaptcha,
    phone,
    country,
    countries,
    submit,
    onChange,
    error,
    showPassword,
    emailVerified,
    loading,
  } = props;

  // Errors
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [birthdayError, setBirthdayError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);

  const [passwordScore, setPasswordScore] = useState(0);
  const recaptchaRef = useRef();

  const preSubmitValidation = function () {
    let errors = 0;
    // First Name
    if (!firstName || firstName.trim().length < 1) {
      setFirstNameError('* First Name Cannot be blank');
      errors += 1;
    }
    // Last Name
    if (!lastName || lastName.trim().length < 1) {
      setLastNameError('* Last Name Cannot be blank');
      errors += 1;
    }
    // Email
    if (!validateEmail(email.trim())) {
      setEmailError('* Email is invalid');
      errors += 1;
    }
    // Birthday
    if (birthday) {
      const formattedBirthday = DateTime.fromFormat(birthday, birthdayFormatting.format);
      const diffNow = formattedBirthday.diffNow('years');
      if (formattedBirthday.invalid || diffNow.years >= -13) {
        setBirthdayError(`* Birthday must be at least 13 years ago, and in ${birthdayFormatting.format.toLowerCase()} format`);
        errors += 1;
      }
    } else {
      setBirthdayError(`* Birthday must be at least 13 years ago, and in ${birthdayFormatting.format.toLowerCase()} format`);
      errors += 1;
    }
    // Password
    if (showPassword) {
      if (!password || password.trim().length < 8) {
        setPasswordError("* Password must be at least 8 characters");
        errors += 1;
      }
      if (passwordScore < 2) {
        setPasswordError("* Password is too weak");
        errors += 1;
      }
    }
    // Phone
    if (!isValidPhoneNumber(phone)) {
      setPhoneError('* Mobile Number is invalid');
      errors += 1;
    }
    return errors > 0;
  }

  const clearErrors = function () {
    setFirstNameError(null);
    setLastNameError(null);
    setPasswordError(null);
    setEmailError(null);
    setBirthdayError(null);
    setPhoneError(null);
  }

  const submitForm = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    clearErrors();
    const errors = preSubmitValidation();
    if (!errors) {
      submit({
        phone,
        country,
        firstName,
        lastName,
        password,
        email,
        birthday: DateTime.fromFormat(birthday, 'MM/dd/yyyy'),
        recaptcha,
      });
    }
  }

  useEffect(() => {
    if (error && recaptchaRef?.current?.reset) {
      recaptchaRef?.current?.reset();
    }
  }, [error]);
  
  return (
    <form className="RegistrationForm" onSubmit={submitForm}>
      <span className="RegistrationForm__error">{error}</span>
      <PhoneInput
        placeholder="Mobile Number"
        international={false}
        displayInitialValueAsLocalNumber={true}
        onChange={(e) => onChange(e, "phone")}
        country={country}
        countries={countries}
        onCountryChange={(e) => onChange(e, "country")}
        value={phone}
        error={phoneError}
      />
      <div className="RegistrationForm__names">
        <TextInput
          placeholder="First Name"
          value={firstName}
          error={firstNameError}
          onChange={(e) => onChange(e, "firstName")}
        />
        <div className="RegistrationForm__spacer" />
        <TextInput
          placeholder="Last Name"
          value={lastName}
          error={lastNameError}
          onChange={(e) => onChange(e, "lastName")}
        />
      </div>
      <TextInput
        placeholder="Email Address"
        emailVerified={emailVerified}
        type="email"
        value={email}
        error={emailError}
        onChange={(e) => onChange(e, "email")}
      />
      {/* {
        showPassword ?
        <TextInput placeholder="Password" type="password" value={password} error={passwordError} onChange={(e) => onChange(e, 'password')} />
        :
        null
      } */}
      <Suspense fallback={null}>
        <PasswordStrengthInput
          placeholder="Password"
          name="password"
          value={password}
          error={passwordError}
          setError={setPasswordError}
          onChange={(e) => onChange(e, "password")}
          score={passwordScore}
          setScore={setPasswordScore}
        />
      </Suspense>

      <TextInput
        placeholder={birthdayFormatting.placeholder}
        type="date"
        value={birthday}
        error={birthdayError}
        onChange={(e) => onChange(e, "birthday")}
      />
      <ReCAPTCHA
        className={classnames("RegistrationForm__recaptcha", {
          "hidden": !showPassword,
          "block": showPassword
        })}
        sitekey={ENV.KEYS.google.reCaptchaKey}
        onChange={(e) => onChange(e, "recaptcha")}
        ref={recaptchaRef}
      />
      {loading ? (
        <button className="btn btn--stretch">
          <i className="icon-loading"></i>
        </button>
      ) : (
        <button className="btn btn--stretch" type="submit">
          Continue
        </button>
      )}
    </form>
  );
};

RegistrationForm.propTypes = {
  showPassword: PropTypes.bool,
  emailVerified: PropTypes.bool,
}

RegistrationForm.defaultProps = {
  showPassword: true,
  emailVerified: false,
}

export default RegistrationForm;