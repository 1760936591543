import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { normalize } from 'normalizr';
import userListOffersModel from '../../models/userListOffer';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import NavigationHeader from '../../components/NavigationHeader';
import MustBeSubscribed from '../../components/MustBeSubscribed';
import Menu from '../../components/Menu';
import UserOfferListItem from '../../components/UserOfferListItem';
import UserOffer from '../../components/UserOffer';
import Loading from '../../components/Loading';
import BluesFooter from '../../components/BluesFooter';
import Modal from '../../components/Modal';
import Empty from '../../components/Empty';
import history from '../../history';
import TS from '../../api';
import { isSmall, searchStringToHash } from '../../services/utils';
import './Offers.scss';
import MainContext from '../../contexts/MainContext';

const Offers = (props) => {
  const { listSubscription } = useContext(MainContext);
  const location = useLocation();
  const params = searchStringToHash(location.search);
  const userOfferId = params.id;
  const offerId = params.offer_id;

  const offersRef = useRef();
  const [openOffer, setOpenOffer] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchUserListOffers = async (id) => {
    setLoading(true);
    try {
      const res = await TS.get(`/user_list_offers?list_id=${id}`);
      const normalized = normalize(res.user_list_offers, [userListOffersModel]);
      const ret = Object.values(normalized.entities.user_list_offers);
      setOffers(ret);
      setLoading(false);
    } catch (error) {
      // silently fail if no offers
      setLoading(false);
    }
  }

  const selectOffer = (selectedOffer) => {
    setOpenOffer(selectedOffer);
    history.replace({ pathname: '/offers', search: `id=${selectedOffer.id}` })
    // if mobile open modal
    window.offers_ref = offersRef.current;
    if (isSmall(offersRef.current.offsetWidth)) {
      setModalOpen(true);
    }
    window.gtag('event', 'select_content', {
      action: 'click',
      category: 'view',
      content_type: 'offer',
      item_id: selectedOffer.list_offer.id,
    });
  }

  useEffect(() => {
    // ideally we shouldn't need to do this check
    if (listSubscription && listSubscription.list_id) {
      fetchUserListOffers(listSubscription.list_id);
    }
  }, []);

  // open first offer in list by default and track
  useEffect(() => {
    if (offers.length) {
      let offer;
      if (userOfferId) {
        offer = offers[userOfferId];
      }
      if (offerId) {
        offer = offers.find((o) => {
          return o.list_offer.id === offerId;
        });
      }
      if (!offer) {
        offer = offers[0];
      }
      setOpenOffer(offer);
      window.offers_ref = offersRef.current;
      // if desktop track view since it's visible
      // OR if it selected offer and mobile, open modal and track it automatically as well
      if (!isSmall(offersRef.current.offsetWidth) || userOfferId == offer.id) {
        if (userOfferId == offer.id) {
          if (isSmall(offersRef.current.offsetWidth)) {
            setModalOpen(true);
          }
        }
        window.gtag('event', 'select_content', {
          action: 'click',
          category: 'view',
          content_type: 'offer',
          item_id: offer.list_offer.id,
        });
      }
    }
  }, [offers]);

  const content = (() => {
    if (!offers.length) {
      return (
        <div className="Page Offers">
          <Empty className="Offers__empty" message="No Offers Available" />
        </div>
      );
    }

    return (
      <div className="Page Offers bg-white">
        <Grid container className='min-h-screen h-full'>
          <Grid className="Offers-list overflow-y-scroll" item xs={12} sm={5} md={4}>
            {offers.map((offer) => <UserOfferListItem userOffer={offer} key={offer.id} className={`Offers-list__item ${openOffer?.id === offer.id ? 'Offers-list__item--active' : ''}`} handleClick={selectOffer} />)}
          </Grid>
          <Grid className="Offers-open" item xs={false} sm={7} md={8}>
            {openOffer && <UserOffer userOffer={openOffer} offer={openOffer.list_offer} />}
          </Grid>
        </Grid>
      </div>
    );
  })();

  return (
    <MustBeSubscribed>
      <Menu path={location.pathname} />
      <NavigationHeader path={location.pathname} />
      <div ref={offersRef}>{loading ? <Loading /> : content}</div>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        showCloseButton
      >
        {openOffer && (
          <UserOffer userOffer={openOffer} offer={openOffer.list_offer} />
        )}
      </Modal>
      {/* <Footer /> */}
      <BluesFooter />
    </MustBeSubscribed>
  );
}

export default Offers;