import React, { useEffect, useState, Fragment } from 'react';
import classNames from 'classnames';
import Clipboard from 'react-clipboard.js';

function CopyButton(props) {
  const { toCopy, className, label, copiedLabel, copiedCallback } = props;
  const [justCopied, setJustCopied] = useState(false);
  const classes = classNames('CopyButton', 'btn', className);
  let timer;
  const didCopy = () => {
    setJustCopied(true);
    if (copiedCallback) {
      copiedCallback();
    }
    timer = setTimeout(() => {
      setJustCopied(false);
    }, 3000);
  };

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  let content = (
    <Fragment>
      <i className="CopyButton__icon icon-clipboard"></i>
      <label className="CopyButton__label">{label}</label>
    </Fragment>
  );

  if (justCopied) {
    content = (
      <Fragment>
        <i className="CopyButton__icon icon-check"></i>
        <label className="CopyButton__label">{copiedLabel}</label>
      </Fragment>
    );
  }

  return (
    <Clipboard className={classes} data-clipboard-text={toCopy} onSuccess={didCopy} title="Copy Link">
      {content}
    </Clipboard>
  );
}

CopyButton.defaultProps = {
  label: 'Copy',
  copiedLabel: 'Copied'
}

export default CopyButton;