import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  CurrencyDollarIcon,
  ShoppingCartIcon,
  TicketIcon,
} from "@heroicons/react/outline";
import BluenaticsInlineBanner from "../../assets/images/bluenatics_inline.png";
import BluenaticsMultilineBanner from "../../assets/images/bluenatics_multiline.png";
import BgWhite from "../../assets/images/bg_white.png";
import "./PassportActivityBanner.scss"
import BackgroundImage from "../BackgroundImage";

function PassportActivityBanner(props) {
  return (
    <BackgroundImage
      src={BgWhite}
      className={classnames(
        "w-full bg-white relative ActivityCard",
        props.className
      )}
    >
      {/* MOBILE */}
      <div className="md:hidden">
        <div className="p-3">
          <img src={BluenaticsMultilineBanner} className="w-full h-full" />
          <div className="text-base uppercase mt-1">
            <ul className="list-inside text-black uppercase text-sm font-bold">
              <li className="flex items-center mb-3 leading-tight">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <CurrencyDollarIcon className="h-5 w-5" />
                </div>
                Rewards + Discounts
              </li>
              <li className="flex items-center mb-3 leading-tight">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <ShoppingCartIcon className="h-5 w-5" />
                </div>
                Surprise offers at Enterprise Center
              </li>
              <li className="flex items-center mb-3 leading-tight">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full inline-block mr-3">
                  <TicketIcon className="h-5 w-5" />
                </div>
                <div>Exclusive opportunities for Bluenatics</div>
              </li>
            </ul>
          </div>
          <div className="mt-5">
            <Link to="/account" className="btn w-full uppercase">
              Connect Account
            </Link>
          </div>
        </div>
      </div>

      {/* DESKTOP */}
      <div className="hidden md:flex items-center p-6">
        <div className="w-full">
          <img src={BluenaticsInlineBanner} className="w-96 h-full" />
          <div className="mt-1">
            <div className="flex w-full items-center text-black uppercase text-sm font-bold gap-3">
              <div className="flex items-center">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full mr-3">
                  <CurrencyDollarIcon className="h-5 w-5" />
                </div>
                Rewards + Discounts
              </div>
              <div className="flex basis-2/5 items-center">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full mr-3">
                  <ShoppingCartIcon className="h-5 w-5" />
                </div>
                Surprise offers at Enterprise Center
              </div>
              <div className="flex items-center">
                <div className="bg-blues-yellow text-blues-blue p-2 rounded-full mr-3">
                  <TicketIcon className="h-5 w-5" />
                </div>
                <div>Exclusive opportunities for Bluenatics</div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/account" className="btn ml-6 w-60 uppercase">
          Connect Now
        </Link>
      </div>
    </BackgroundImage>
  );
}

export default PassportActivityBanner;
