import React from "react";

export default React.createContext({
  userContestData: null,
  userContestsLoading: null,
  userContestsComplete: null,
  fetchUserContests: null,
  fetchUserContest: null,
  createUserContest: null,
  getUserContest: null,
  getUserContestAction: null
});
