import React from 'react';
import PropTypes from 'prop-types';
import './Stepper.scss';

function Stepper(props) {
  const {
    steps,
    active,
  } = props;

  const arr = [...Array(steps)].map((_, i) => i);

  return (
    <div className="Stepper">
      {
        arr.map((x, i) => {
          return <div className={`Stepper-step ${i === active ? 'Stepper-step--active' : ''}`} key={i} />
        })
      }
    </div>
  )
};

Stepper.propTypes = {
  steps: PropTypes.number,
}

Stepper.defaultProps ={
  steps: 3,
  active: 0,
}

export default Stepper;