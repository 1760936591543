import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

function BackgroundImage(props) {
  const { src, size, className, children, delay = 0 } = props;
  const [loaded, setLoaded] = useState(false);

  const classNames = className ? `background-image ${className}` : 'background-image';
  const style = {
    backgroundImage: delay ? `url(${loaded})` : `url(${src})`,
    backgroundSize: size,
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  };

  useEffect(() => {
    if (delay) {
      setLoaded(false);
      const img = new Image();
      img.src = src
      img.onload = () => {
        setTimeout(() => {
          setLoaded(src)
        }, delay);
      };
    }
  }, [src, delay]);

  if (delay) {
    return <div style={style} className={classnames("bg-black transition ease-in", {
      "opacity-0": !loaded,
      "opacity-100": loaded
    }, classNames)}>
      {children}
    </div>
  }

  return (
    <div style={style} className={classNames}>
      {children}
    </div>
  );
}

BackgroundImage.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
}

BackgroundImage.defaultProps = {
  size: 'cover',
  src: '',
}

export default BackgroundImage;
